// src/components/PurchaseFlow/PurchaseFlow.js

import React, { useState, useEffect, useContext } from 'react';
import { ClientContext } from '../../context/ClientContext';
import { SelectedOfferProvider } from '../../context/SelectedOfferContext';

// Importar todos los componentes de pasos
import OfferStep from './Steps/Offer/OfferStep';
import DataEntryStep from './Steps/DataEntry/DataEntryStep';
import DocumentUploadStep from './Steps/DocumentUpload/DocumentUploadStep';
import ConfirmationStep from './Steps/ConfirmationStep';
import PaymentStep from './Steps/Payment/PaymentStep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import './PurchaseFlow.css';

const PurchaseFlow = ({ onClose }) => {
  const { clientData, clientStatus } = useContext(ClientContext);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const [stepLabels, setStepsLabels] = useState([]);
  const [purchaseRequestId, setPurchaseRequestId] = useState(null);
  const [lastStepStatus, setLastStepStatus] = useState('pending');

  const [formData, setFormData] = useState({});

  const clientPurchaseFlowMap = {
    NEW: process.env.REACT_APP_PURCHASE_FLOW_NEW,
    RENEWER: process.env.REACT_APP_PURCHASE_FLOW_RENEWER,
    OLD: process.env.REACT_APP_PURCHASE_FLOW_OLD,
    RESTRICTED: process.env.REACT_APP_PURCHASE_FLOW_RESTRICTED,
    BLACKLISTED: process.env.REACT_APP_PURCHASE_FLOW_BLACKLISTED
  };  

  useEffect(() => {
    const getStepsForClientStatus = () => {
      let stepsConfig = '';
      console.log('clientStatus:', clientStatus);
      console.log('clientPurchaseFlowMap:', clientPurchaseFlowMap);
      stepsConfig = clientPurchaseFlowMap[clientStatus];

      if (!stepsConfig) {
        console.error(`No hay configuración de pasos para el estado ${clientStatus}`);
        return;
      }

      const stepsArray = stepsConfig.split(',').map((stepName) => {
        const trimmedStepName = stepName.trim();
        switch (trimmedStepName) {
          case 'OfferStep':
            return OfferStep;
          case 'DataEntryStep':
            return DataEntryStep;
          case 'DocumentUploadStep':
            return DocumentUploadStep;
          case 'ConfirmationStep':
            return ConfirmationStep;
          case 'PaymentStep':
            return PaymentStep;
          default:
            console.warn(`Paso desconocido: "${trimmedStepName}"`);
            return null;
        }
      });

      const stepLabelsArray = stepsConfig.split(',').map((stepName) => {
        const trimmedStepName = stepName.trim();
        switch (trimmedStepName) {
          case 'OfferStep':
            return 'Producto';
          case 'DataEntryStep':
            return 'Legajo Digital';
          case 'DocumentUploadStep':
            return 'Documentos';
          case 'ConfirmationStep':
            return 'Confirmación';
          case 'PaymentStep':
            return 'Medio de Pago';
          default:
            console.warn(`Etiqueta desconocida para paso: "${trimmedStepName}"`);
            return null;
        }
      });

      console.log('stepsArray:', stepsArray);
      console.log('stepLabelsArray:', stepLabelsArray);

      setSteps(stepsArray);
      setStepsLabels(stepLabelsArray);
    };

    getStepsForClientStatus();

  }, [clientStatus]);

  const handleNext = (data, stepStatus = null) => {
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        ...data,
      };
      console.log('Updated formData:', updatedData);
      return updatedData;
    });

    if (currentStepIndex === steps.length - 1 && stepStatus) {
      setLastStepStatus(stepStatus);
    } else {
      setCurrentStepIndex((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setCurrentStepIndex((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    const CurrentStepComponent = steps[currentStepIndex];
    console.log('currentStepIndex:', currentStepIndex);
    console.log('stepLabels[currentStepIndex]:', stepLabels[currentStepIndex]);

    if (!CurrentStepComponent) {
      console.error(`Componente para el paso index ${currentStepIndex} no está definido.`);
      return <div>Error al cargar el paso. Por favor, intenta nuevamente.</div>;
    }

    return (
      <CurrentStepComponent
        clientData={clientData}
        purchaseRequestId={purchaseRequestId}
        setPurchaseRequestId={setPurchaseRequestId}
        onNext={handleNext}
        onBack={handleBack}
        formData={formData}
      />
    );
  };

  // Función para calcular el progreso
  const calculateProgress = () => {
    const totalSteps = steps.length;
    if (totalSteps === 0) return 0;

    // Si el último paso está en success, retornamos 100%
    if (currentStepIndex === totalSteps - 1 && lastStepStatus === 'success') {
      return 100;
    }

    // Calculamos el progreso basado en los pasos completados
    const completedSteps = currentStepIndex;
    return (completedSteps / (totalSteps - 1)) * 100;
  };

  return (
    <SelectedOfferProvider>
      <div className="purchase-flow">
        {/* Botón para cerrar el flujo */}
        <div className="text-end">
          <button className="btn btn-link" onClick={onClose}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>

        {/* Stepper con barra de progreso - solo visible si no está confirmado */}
        {!(currentStepIndex === steps.length - 1 && lastStepStatus === 'success') && (
          <div className="stepper-container mb-4">
            <div className="stepper-wrapper">
              {steps.map((_, index) => {
                let stepClassName = 'stepper-item';
                if (index === currentStepIndex) {
                  if (index === steps.length - 1 && lastStepStatus === 'success') {
                    stepClassName += ' completed';
                  } else {
                    stepClassName += ' active';
                  }
                } else if (index < currentStepIndex) {
                  stepClassName += ' completed';
                }

                return (
                  <div className={stepClassName} key={index}>
                    <div className="step-counter">
                      {(index < currentStepIndex || 
                        (index === steps.length - 1 && lastStepStatus === 'success')) ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        index + 1
                      )}
                    </div>
                    <p className="step-label">{stepLabels[index]}</p>
                  </div>
                );
              })}
            </div>
            {/* Barra de progreso actualizada */}
            <div className="progress-bar-wrapper">
              <div
                className="progress-bar"
                style={{
                  width: `${calculateProgress()}%`,
                  transition: 'width 0.3s ease-in-out' // Agregamos una transición suave
                }}
              ></div>
            </div>
          </div>
        )}

        {/* Renderizar el componente del paso actual */}
        {renderStep()}
      </div>
    </SelectedOfferProvider>
  );
};

export default PurchaseFlow;

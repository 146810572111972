import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { formatNumber, formatDate, formatCurrency } from '../../utils/numberFormatter';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SaleDetailModal from '../Sales/SaleDetailModal';
import '../../styles/icons.css';
import '../../styles/bitacora.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons';

const MySwal = withReactContent(Swal);

const SALE_STATUSES = {
  created: { label: 'Pendiente', class: 'bg-warning' },
  pending: { label: 'Verificando', class: 'bg-info' },
  verified: { label: 'Verificada', class: 'bg-success' },
  paid: { label: 'Pagada', class: 'bg-success' },
  rejected: { label: 'Rechazada', class: 'bg-danger' },
  annulled: { label: 'Anulada', class: 'bg-secondary' }
};

const TableSkeleton = () => (
  <div className="skeleton-wrapper">
    <table className="table">
      <thead>
        <tr>
          <th>Venta</th>
          <th>Fecha</th>
          <th>Vendedor</th>
          <th>Sucursal</th>
          <th>Monto Total</th>
          <th>Cuotas</th>
          <th>Valor Cuota</th>
          <th className="text-center">Estado</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 5 }).map((_, index) => (
          <tr key={index}>
            <td><div className="skeleton-cell" style={{ width: '60px' }}></div></td>
            <td><div className="skeleton-cell" style={{ width: '100px' }}></div></td>
            <td><div className="skeleton-cell" style={{ width: '150px' }}></div></td>
            <td><div className="skeleton-cell" style={{ width: '120px' }}></div></td>
            <td><div className="skeleton-cell" style={{ width: '100px' }}></div></td>
            <td><div className="skeleton-cell" style={{ width: '60px' }}></div></td>
            <td><div className="skeleton-cell" style={{ width: '100px' }}></div></td>
            <td><div className="skeleton-cell" style={{ width: '100px' }}></div></td>
            <td><div className="skeleton-cell" style={{ width: '100px' }}></div></td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ClientSales = ({ clientId }) => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedSaleId, setSelectedSaleId] = useState(null);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 25,
    total: 0,
    last_page: 1
  });
  const [filters, setFilters] = useState({
    status: '',
    from_date: '',
    to_date: ''
  });

  useEffect(() => {
    fetchSales();
  }, [clientId, pagination.current_page, filters]);

  const fetchSales = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams({
        ...filters,
        page: pagination.current_page,
        per_page: pagination.per_page
      });

      const response = await api.get(`/customers/${clientId}/sales?${params}`);
      setSales(response.data.sales);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error('Error al obtener ventas:', error);
      MySwal.fire({
        title: 'Error',
        text: 'No se pudieron cargar las ventas del cliente',
        icon: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
    setPagination(prev => ({ ...prev, current_page: 1 }));
  };

  const handleCancelSale = async (saleId) => {
    MySwal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esta acción",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, anular venta',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.post(`/sales/${saleId}/cancel`);
          fetchSales();
          MySwal.fire(
            'Anulada',
            'La venta ha sido anulada',
            'success'
          );
        } catch (error) {
          MySwal.fire(
            'Error',
            'No se pudo anular la venta',
            'error'
          );
        }
      }
    });
  };

  const handleUploadDocuments = (saleId) => {
    // Implementar lógica de carga de documentos
  };

  const handlePayInstallment = (saleId, installmentId) => {
    MySwal.fire({
      title: '¿Deseas registrar el pago de esta cuota?',
      text: "Confirma para proceder con el pago",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#198754',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Sí, pagar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.post(`/sales/${saleId}/installments/${installmentId}/pay`);
          const response = await api.get(`/sales/${saleId}`);
          setSelectedSaleId(response.data.id);
          fetchSales(); // Actualizar la lista de ventas
          MySwal.fire(
            'Pagado',
            'La cuota ha sido pagada correctamente',
            'success'
          );
        } catch (error) {
          MySwal.fire(
            'Error',
            'No se pudo procesar el pago de la cuota',
            'error'
          );
        }
      }
    });
  };

  const handleViewDetail = (saleId) => {
    setSelectedSaleId(saleId);
    setShowDetailModal(true);
  };

  const Pagination = () => (
    <nav aria-label="Navegación de páginas">
      <ul className="pagination justify-content-center mb-0">
        <li className={`page-item ${loading || pagination.current_page === 1 ? 'disabled' : ''}`}>
          <button 
            className="page-link" 
            onClick={() => setPagination(prev => ({ ...prev, current_page: prev.current_page - 1 }))}
            disabled={loading}
          >
            Anterior
          </button>
        </li>
        {Array.from({ length: pagination.last_page }, (_, i) => (
          <li key={i} className={`page-item ${pagination.current_page === i + 1 ? 'active' : ''} ${loading ? 'disabled' : ''}`}>
            <button 
              className="page-link"
              onClick={() => setPagination(prev => ({ ...prev, current_page: i + 1 }))}
              disabled={loading}
            >
              {i + 1}
            </button>
          </li>
        ))}
        <li className={`page-item ${loading || pagination.current_page === pagination.last_page ? 'disabled' : ''}`}>
          <button 
            className="page-link"
            onClick={() => setPagination(prev => ({ ...prev, current_page: prev.current_page + 1 }))}
            disabled={loading}
          >
            Siguiente
          </button>
        </li>
      </ul>
    </nav>
  );

  if (loading) {
    return (
      <div className="text-center p-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title mb-0"><FontAwesomeIcon icon={faTags} className="me-2" />Historial de Ventas</h5>
      </div>
      <div className="card-body">
        {/* Filtros */}
        <div className="row mb-3">
          <div className="col-md-4">
            <select
              className="form-select"
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
            >
              <option value="">Todos los estados</option>
              {Object.entries(SALE_STATUSES).map(([value, { label }]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <input
              type="date"
              className="form-control"
              name="from_date"
              value={filters.from_date}
              onChange={handleFilterChange}
              placeholder="Fecha desde"
            />
          </div>
          <div className="col-md-4">
            <input
              type="date"
              className="form-control"
              name="to_date"
              value={filters.to_date}
              onChange={handleFilterChange}
              placeholder="Fecha hasta"
            />
          </div>
        </div>

        {/* Tabla */}
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Venta</th>
                <th>Fecha</th>
                <th>Vendedor</th>
                <th>Sucursal</th>
                <th>Monto</th>
                <th>Cuotas</th>
                <th className="text-center">Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9">
                    <TableSkeleton />
                  </td>
                </tr>
              ) : (
                sales.map((sale) => (
                  <tr key={sale.id}>
                    <td>#{sale.id}</td>
                    <td>{formatDate(sale.sale_date)}</td>
                    <td>{sale.seller.full_name}</td>
                    <td>{sale.branch.name}</td>
                    <td>{formatCurrency(sale.total_amount)}</td>
                    <td className="text-center">{sale.installments}</td>
                    <td className="text-center">
                      <span className={`badge ${SALE_STATUSES[sale.status]?.class}`}>
                        { SALE_STATUSES[sale.status]?.label ?? sale.status_label}
                      </span>
                    </td>
                    <td>
                      <div className="btn-group">
                        <button 
                          className="btn btn-sm btn-outline-primary"
                          onClick={() => handleViewDetail(sale.id)}
                          title="Ver detalle"
                        >
                          <i className="bi bi-eye me-1"></i>
                          Ver
                        </button>
                        {sale.status !== 'annulled' && false && ( // TODO: implement sales anullment
                          <button 
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => handleCancelSale(sale.id)}
                            title="Anular venta"
                          >
                            <i className="bi bi-x-circle me-1"></i>
                            Anular
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <Pagination />

        {showDetailModal && (
          <SaleDetailModal
            saleId={selectedSaleId}
            customerId={clientId}
            onClose={() => {
              setShowDetailModal(false);
              setSelectedSaleId(null);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ClientSales; 
import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { formatNumber, formatDate, formatCurrency } from '../utils/numberFormatter';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import '../styles/bitacora.css';
import '../styles/sales.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SaleDetailModal from '../components/Sales/SaleDetailModal';

const MySwal = withReactContent(Swal);

const SALE_STATUSES = {
  created: { label: 'Pendiente', class: 'bg-warning' },
  pending: { label: 'Verificando', class: 'bg-info' },
  verified: { label: 'Verificada', class: 'bg-success' },
  paid: { label: 'Pagada', class: 'bg-success' },
  rejected: { label: 'Rechazada', class: 'bg-danger' },
  annulled: { label: 'Anulada', class: 'bg-secondary' }
};

const SalesPage = () => {
  const navigate = useNavigate();
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSale, setSelectedSale] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 25,
    total: 0,
    last_page: 1
  });
  const [filters, setFilters] = useState({
    search: '',
    status: '',
    from_date: '',
    to_date: ''
  });

  useEffect(() => {
    fetchSales();
  }, [filters, pagination.current_page]);

  const fetchSales = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams({
        ...filters,
        page: pagination.current_page,
        per_page: pagination.per_page
      });

      const response = await api.get(`/sales?${params}`);
      setSales(response.data.sales);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error('Error al obtener ventas:', error);
      MySwal.fire({
        title: 'Error',
        text: 'No se pudieron cargar las ventas',
        icon: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
    setPagination(prev => ({ ...prev, current_page: 1 })); // Reset page when filtering
  };

  const handleCancelSale = async (saleId) => {
    MySwal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esta acción",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, anular venta',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.post(`/sales/${saleId}/cancel`);
          fetchSales();
          MySwal.fire(
            'Anulada',
            'La venta ha sido anulada',
            'success'
          );
        } catch (error) {
          MySwal.fire(
            'Error',
            'No se pudo anular la venta',
            'error'
          );
        }
      }
    });
  };

  const handleUploadDocuments = (saleId) => {
    // Implementar lógica de carga de documentos
  };

  const handlePayInstallment = (saleId, installmentId) => {
    MySwal.fire({
      title: '¿Deseas registrar el pago de esta cuota?',
      text: "Confirma para proceder con el pago",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#198754',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Sí, pagar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.post(`/sales/${saleId}/installments/${installmentId}/pay`);
          const response = await api.get(`/sales/${saleId}`);
          setSelectedSale(response.data);
          fetchSales(); // Actualizar la lista de ventas
          MySwal.fire(
            'Pagado',
            'La cuota ha sido pagada correctamente',
            'success'
          );
        } catch (error) {
          MySwal.fire(
            'Error',
            'No se pudo procesar el pago de la cuota',
            'error'
          );
        }
      }
    });
  };


  const getStatusBadgeClass = (status) => {
    return SALE_STATUSES[status]?.class || 'bg-secondary';
  };

  const formatStatus = (status) => {
    return SALE_STATUSES[status]?.label || status;
  };

  const handleViewDetail = (saleId, customerId) => {
    setSelectedSale({ id: saleId, customer: { id: customerId } });
    setShowDetailModal(true);
  };

  const handleGoToCustomer = (dni) => {
    navigate(`/clientes/${dni}`);
  };

  // Componente Skeleton para la tabla
  const TableSkeleton = () => (
    <>
      {Array.from({ length: pagination.per_page }).map((_, index) => (
        <tr key={index}>
          <td><Skeleton width={40} height={30} /></td>
          <td><Skeleton width={80} height={30} /></td>
          <td><Skeleton width={60} height={30} /></td>
          <td><Skeleton width={60} height={30} /></td>
          <td><Skeleton width={60} height={30} /></td>
          <td><Skeleton width={60} height={30} /></td>
          <td className="text-center">
            <Skeleton width={50} height={25} />
          </td>
          <td>
            <div className="d-flex gap-2">
              <Skeleton width={30} height={30} />
              <Skeleton width={30} height={30} />
              <Skeleton width={30} height={30} />
            </div>
          </td>
        </tr>
      ))}
    </>
  );

  // Componente de Paginación
  const Pagination = ({ loading }) => (
    <nav aria-label="Navegación de páginas">
      <ul className="pagination justify-content-center mb-0">
        <li className={`page-item ${loading || pagination.current_page === 1 ? 'disabled' : ''}`}>
          <button 
            className="page-link" 
            onClick={() => setPagination(prev => ({ ...prev, current_page: prev.current_page - 1 }))}
            disabled={loading}
          >
            Anterior
          </button>
        </li>
        {Array.from({ length: pagination.last_page }, (_, i) => (
          <li key={i} className={`page-item ${pagination.current_page === i + 1 ? 'active' : ''} ${loading ? 'disabled' : ''}`}>
            <button 
              className="page-link"
              onClick={() => setPagination(prev => ({ ...prev, current_page: i + 1 }))}
              disabled={loading}
            >
              {i + 1}
            </button>
          </li>
        ))}
        <li className={`page-item ${loading || pagination.current_page === pagination.last_page ? 'disabled' : ''}`}>
          <button 
            className="page-link"
            onClick={() => setPagination(prev => ({ ...prev, current_page: prev.current_page + 1 }))}
            disabled={loading}
          >
            Siguiente
          </button>
        </li>
      </ul>
    </nav>
  );

  return (
    <div className="page-content">
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3>Ventas</h3>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-md-3 mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por cliente o DNI..."
                  name="search"
                  value={filters.search}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-3 mb-2">
                <select
                  className="form-select"
                  name="status"
                  value={filters.status}
                  onChange={handleFilterChange}
                >
                  <option value="">Todos los estados</option>
                  {Object.entries(SALE_STATUSES).map(([value, { label }]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 mb-2">
                <input
                  type="date"
                  className="form-control"
                  name="from_date"
                  value={filters.from_date}
                  onChange={handleFilterChange}
                  placeholder="Fecha desde"
                />
              </div>
              <div className="col-md-3 mb-2">
                <input
                  type="date"
                  className="form-control"
                  name="to_date"
                  value={filters.to_date}
                  onChange={handleFilterChange}
                  placeholder="Fecha hasta"
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Venta</th>
                    <th>Cliente</th>
                    <th>Fecha</th>
                    <th>Vendedor</th>
                    <th>Monto</th>
                    <th>Cuotas</th>
                    <th className="text-center">Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <TableSkeleton />
                  ) : (
                    sales.map((sale) => (
                      <tr key={sale.id}>
                        <td>#{sale.id}</td>
                        <td>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleGoToCustomer(sale.customer.dni);
                            }}
                            className="text-primary"
                          >
                            {sale.customer.name}
                          </a>
                          <br />
                          <small className="text-muted">
                            DNI: {sale.customer.dni}
                          </small>
                        </td>
                        <td>{formatDate(sale.sale_date)}</td>
                        <td>{sale.seller.full_name}</td>
                        <td>{formatCurrency(sale.amount)}</td>
                        <td className="text-center">{sale.installments}</td>
                        <td className="text-center">
                          <span className={`badge ${getStatusBadgeClass(sale.status)}`}>
                            {formatStatus(sale.status)}
                          </span>
                        </td>
                        <td>
                          <div className="btn-group">
                            <button 
                              className="btn btn-sm btn-outline-primary"
                              onClick={() => handleViewDetail(sale.id, sale.customer.id)}
                              title="Ver detalle"
                            >
                              <i className="bi bi-eye me-1"></i>
                              Ver
                            </button>
                            {sale.status !== 'annulled' && false && ( // TODO: implement sales anullment
                              <button 
                                className="btn btn-sm btn-outline-danger"
                                onClick={() => handleCancelSale(sale.id)}
                                title="Anular venta"
                              >
                                <i className="bi bi-x-circle me-1"></i>
                                Anular
                              </button>
                            )}
                            {/* <button 
                              className="btn btn-sm btn-outline-success"
                              onClick={() => handleUploadDocuments(sale.id)}
                              title="Documentos"
                            >
                              <i className="bi bi-file-earmark-text"></i>
                            </button> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            
            <Pagination loading={loading} />

            {showDetailModal && (
              <SaleDetailModal
                saleId={selectedSale.id}
                customerId={selectedSale.customer.id}
                onClose={() => {
                  setShowDetailModal(false);
                  setSelectedSale(null);
                }}
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SalesPage; 
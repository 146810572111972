import React from 'react';

const NavigationButtons = ({ 
  onBack, 
  onNext, 
  nextLabel = 'Siguiente', 
  backLabel = 'Atrás', 
  disableNext = false, 
  isLoading = false 
}) => {
  return (
    <div className="d-flex justify-content-between mt-4">
      {onBack ? (
        <button className="btn btn-secondary" type="button" onClick={onBack}>
          {backLabel}
        </button>
      ) : (
        <div></div>
      )}
      {onNext ? (
        <button 
          className="btn btn-success d-flex align-items-center justify-content-center" 
          type="button" 
          onClick={onNext} 
          disabled={disableNext || isLoading}
        >
          {isLoading && (
            <span 
              className="spinner-border spinner-border-sm me-2" 
              role="status" 
              aria-hidden="true"
            ></span>
          )}
          {nextLabel}
        </button>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default NavigationButtons;

// src/pages/LoginPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/Logo';
import api from '../services/api';
import { useAuth } from '../context/AuthContext';
import { useSearchParams } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [branchNumber, setBranchNumber] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');

  useEffect(() => {
    const queryMessage = searchParams.get('message');
    if (queryMessage === 'sesion_expirada') {
      setMessage('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.');
    }
  }, [searchParams]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/login', { username, password, branch_number: branchNumber });
  
      const { access_token, user, commerce, config } = response.data;
      const { id: channelId, name: commerceName, logo: commerceLogo, branch } = commerce;
      const { id: branchId, name: branchName } = branch;
  
      // Llamar al método login
      login(access_token, user, channelId, branchId, commerceName, branchName, commerceLogo, config);
  
      navigate('/');
    } catch (error) {
      console.error('Login error:', error.response?.data || error.message);
      setErrorMessage('Credenciales incorrectas. Por favor intenta de nuevo.');
    }
  };  

  return (
    <div id="auth-left">
      <div className="auth-logo">
        <a href="/">
          <Logo />
        </a>
      </div>
      <h1 className="auth-title">Portal Comercios</h1>
      <p className="auth-subtitle mb-5">Ingresa con las credenciales que te asignamos.</p>

      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      {message && <div className="alert alert-warning">{message}</div>}

      <form onSubmit={handleLogin}>
        <div className="form-group position-relative has-icon-left mb-4">
          <input
            type="text"
            className="form-control form-control-xl"
            placeholder="Número de sucursal"
            value={branchNumber}
            onChange={(e) => setBranchNumber(e.target.value)}
          />
          <div className="form-control-icon">
            <i className="bi bi-shop"></i>
          </div>
        </div>
        <div className="form-group position-relative has-icon-left mb-4">
          <input
            type="text"
            className="form-control form-control-xl"
            placeholder="Usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <div className="form-control-icon">
            <i className="bi bi-person"></i>
          </div>
        </div>
        <div className="form-group position-relative has-icon-left mb-4">
          <input
            type="password"
            className="form-control form-control-xl"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="form-control-icon">
            <i className="bi bi-shield-lock"></i>
          </div>
        </div>
        <div className="form-check form-check-lg d-flex align-items-end">
          <input
            className="form-check-input me-2"
            type="checkbox"
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
            id="flexCheckDefault"
          />
          <label className="form-check-label text-gray-600" htmlFor="flexCheckDefault">
            Recordarme en esta computadora
          </label>
        </div>
        <button type="submit" className="btn btn-primary btn-block btn-lg shadow-lg mt-5">
          Ingresar
        </button>
      </form>
    </div>
  );
};

export default LoginPage;

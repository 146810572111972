import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../services/api';

const TokenValidationPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await api.post('/temporary-token/validate', { token });
        
        // Guardamos el token JWT y el scope
        localStorage.setItem('authToken', response.data.access_token);
        localStorage.setItem('tokenScope', JSON.stringify(response.data.scope));
        
        // Guardamos el tipo de token y los IDs relevantes
        const { sale_id, customer_id } = response.data.scope;
        const { tokenType } = response.data;
        
        localStorage.setItem('tokenType', tokenType);

        if (sale_id) {
          localStorage.setItem('tokenSaleId', sale_id);
        }
        if (customer_id) {
          localStorage.setItem('tokenCustomerId', customer_id);
        }

        // Redirigimos a la página de documentos
        navigate('/m/docs/' + token + '/sales');
        
      } catch (error) {
        console.error('Error validating token:', error);
        const errorType = error.response?.data?.message?.includes('expired')
          ? 'token_expired'
          : 'invalid_token';
        navigate(`/m/error/${errorType}`);
      }
    };

    validateToken();
  }, [token, navigate]);

  return (
    <div className="text-center p-5">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Validando acceso...</span>
      </div>
    </div>
  );
};

export default TokenValidationPage; 
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDownload } from '@fortawesome/free-solid-svg-icons';

const UploadedDocumentItem = ({ icon, name, uploadDate, onView, onDownload }) => {
  return (
    <li className="document-item uploaded border-0">
      <div className="document-info">
        <FontAwesomeIcon icon={icon} size="2x" className="me-2" />
        <span className="document-name">{name}</span>
      </div>

      <div className="document-actions">
        <button 
          className="btn btn-link btn-sm text-success pe-0"
          onClick={onView}
          title="Ver documento"
        >
          <FontAwesomeIcon icon={faEye} className="me-1" />
        </button>
        <button 
          className="btn btn-link btn-sm text-success pe-0"
          onClick={onDownload}
          title="Descargar documento"
        >
          <FontAwesomeIcon icon={faDownload} className="me-1" />
        </button>
      </div>
    </li>
  );
};

export default UploadedDocumentItem;
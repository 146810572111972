/**
 * Formats a DNI number with thousand separators
 * @param {string|number} dni - The DNI number to format
 * @returns {string} Formatted DNI with thousand separators
 * @example
 * formatDNI(12345678) // returns "12.345.678"
 * formatDNI("12345678") // returns "12.345.678"
 */
export const formatDNI = (dni) => {
  if (!dni) return '';
  
  // Convert to string and remove any existing formatting
  const cleanDni = dni.toString().replace(/\D/g, '');
  
  // Format with thousand separators
  return cleanDni.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

/**
 * Formats a CUIL/CUIT number with hyphens
 * @param {string|number} cuil - The CUIL/CUIT number to format
 * @returns {string} Formatted CUIL/CUIT with hyphens (XX-XXXXXXXX-X)
 * @example
 * formatCUIL(20123456789) // returns "20-12345678-9"
 * formatCUIL("20123456789") // returns "20-12345678-9"
 */
export const formatCUIL = (cuil) => {
  if (!cuil) return '';
  
  // Convert to string and remove any existing formatting
  const cleanCuil = cuil.toString().replace(/\D/g, '');
  
  // Validate length
  if (cleanCuil.length !== 11 && cleanCuil.length !== 10) {
    return cleanCuil; // Return unformatted if invalid length
  }
  
  // Format XX-XXXXXXXX-X
  return `${cleanCuil.slice(0, 2)}-${cleanCuil.slice(2, 10)}-${cleanCuil.slice(10)}`;
};

/**
 * Removes formatting from a document number
 * @param {string} document - The formatted document number
 * @returns {string} Clean document number without formatting
 * @example
 * cleanDocumentNumber("12.345.678") // returns "12345678"
 * cleanDocumentNumber("20-12345678-9") // returns "20123456789"
 */
export const cleanDocumentNumber = (document) => {
  if (!document) return '';
  return document.toString().replace(/\D/g, '');
};

import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../services/api';
import Swal from 'sweetalert2';
import { formatNumber } from '../utils/numberFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faCamera, faImages } from '@fortawesome/free-solid-svg-icons';

const SALE_STATUSES = {
  created: { label: 'Pendiente', class: 'bg-warning' },
  pending: { label: 'Verificando', class: 'bg-info' },
  verified: { label: 'Verificada', class: 'bg-success' }
};

const SaleSkeleton = () => (
  <div className="col-12">
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-start mb-3">
          <div style={{ width: '60%' }}>
            <div className="skeleton-loader mb-2" style={{ width: '40%', height: '24px' }}></div>
            <div className="skeleton-loader" style={{ width: '80%', height: '18px' }}></div>
          </div>
          <div className="skeleton-loader" style={{ width: '20%', height: '24px' }}></div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <div className="skeleton-loader mb-1" style={{ width: '30%', height: '14px' }}></div>
            <div className="skeleton-loader" style={{ width: '50%', height: '18px' }}></div>
          </div>
          <div className="col-6">
            <div className="skeleton-loader mb-1" style={{ width: '30%', height: '14px' }}></div>
            <div className="skeleton-loader" style={{ width: '50%', height: '18px' }}></div>
          </div>
        </div>
        <div className="skeleton-loader" style={{ width: '100%', height: '40px' }}></div>
      </div>
    </div>
  </div>
);

const MobileDocumentsPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [sales, setSales] = useState([]);
  const [selectedSale, setSelectedSale] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [displayedSales, setDisplayedSales] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    status: 'created'
  });
  const [uploadedDocs, setUploadedDocs] = useState(new Set());
  const [uploadProgress, setUploadProgress] = useState({});
  const [pagination, setPagination] = useState({
    current_page: 1,
    last_page: 1,
    total: 0,
    per_page: 25
  });

  // Obtenemos el scope del token
  const scope = useMemo(() => {
    const storedScope = localStorage.getItem('tokenScope');
    return storedScope ? JSON.parse(storedScope) : {};
  }, []);

  // Determinamos el tipo de token
  const tokenType = useMemo(() => {
    if (scope.sale_id && scope.customer_id) return 'customer-sale';
    if (scope.sale_id) return 'seller-sale';
    return 'seller-general';
  }, [scope]);

  useEffect(() => {
    initializeData();
  }, []);

  const initializeData = async () => {
    try {
      const storedToken = localStorage.getItem('authToken');
      const storedScope = localStorage.getItem('tokenScope');

      if (storedToken && storedScope) {
        const scope = JSON.parse(storedScope);
        setTokenData({ scope });
        await fetchSales({ page: 1 });
      } else {
        navigate(`/m/docs/${token}`);
      }
    } catch (error) {
      console.error('Error initializing data:', error);
      setError('Error al inicializar los datos');
      setSales([]);
    }
  };

  const fetchSales = async ({ page = 1, append = false } = {}) => {
    try {
      setLoadingMore(append);
      setLoading(!append);

      let response;
      
      if (tokenType === 'seller-general') {
        // Token general - traemos lista de ventas
        response = await api.get(`/sales?page=${page}&status=${filters.status}`);
        
        const salesData = response.data.sales || [];
        setPagination(response.data.pagination);
        
        if (append) {
          setDisplayedSales(prev => [...prev, ...salesData]);
        } else {
          setDisplayedSales(salesData);
        }
      } else {
        // Token específico de venta (cliente o vendedor)
        response = await api.get(`/customers/${scope.customer_id}/sales/${scope.sale_id}`);
        
        setDisplayedSales([response.data]);
        setPagination({
          current_page: 1,
          last_page: 1,
          total: 1,
          per_page: 1
        });
      }
    } catch (error) {
      console.error('Error fetching sales:', error);
      setError('Error al cargar las ventas');
      setDisplayedSales([]);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  // Efecto para cargar ventas iniciales o cuando cambian los filtros
  useEffect(() => {
    fetchSales();
  }, [filters]);

  const handleFileInput = async (sale, documentType, documentId, file) => {
    try {
      setUploadProgress(prev => ({
        ...prev,
        [documentId]: 0
      }));

      const formData = new FormData();
      formData.append('files[]', file);
      formData.append('document_ids[]', documentId);
      
      const response = await api.post(
        `/customers/${sale.customer.id}/sales/${sale.id}/documents`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(prev => ({
              ...prev,
              [documentId]: percentCompleted
            }));
          }
        }
      );
      
      // Limpiamos el progreso
      setUploadProgress(prev => {
        const newProgress = { ...prev };
        delete newProgress[documentId];
        return newProgress;
      });
      
      // Actualizamos la venta en el listado mostrado sin aplicar filtros
      setDisplayedSales(prevSales => 
        prevSales.map(s => {
          if (s.id === sale.id) {
            return {
              ...s,
              status: response.data.sale_status,
              pending_documents: s.pending_documents.map(doc => {
                if (doc.id === documentId) {
                  return { ...doc, status: 'uploaded' };
                }
                return doc;
              })
            };
          }
          return s;
        })
      );
      
      if (response.data.sale_status === 'verified') {
        Swal.fire({
          title: '¡Éxito!',
          text: 'Documentos subidos correctamente',
          icon: 'success'
        });
      }
      
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadProgress(prev => {
        const newProgress = { ...prev };
        delete newProgress[documentId];
        return newProgress;
      });
      Swal.fire({
        title: 'Error',
        text: 'No se pudo subir el documento',
        icon: 'error'
      });
    }
  };

  const renderDocumentCard = (sale, doc) => {
    // Verificamos el estado del documento directamente desde su propiedad status
    const isUploaded = doc.status === 'uploaded';
    const isUploading = uploadProgress[doc.id] !== undefined;
    const progress = uploadProgress[doc.id] || 0;

    return (
      <div key={doc.id} className="mob-document-upload-container mb-2">
        {isUploaded ? (
          <div className="mob-document-uploaded">
            <FontAwesomeIcon icon={faFileAlt} className="me-2" />
            {doc.title}
            <i className="bi bi-check-circle-fill text-success ms-2"></i>
          </div>
        ) : (
          <div className="mob-document-pending">
            <div className="mob-document-title">
              <FontAwesomeIcon icon={faFileAlt} className="me-2" />
              {doc.title}
            </div>
            {isUploading ? (
              <div className="upload-progress">
                <div className="progress">
                  <div 
                    className="progress-bar progress-bar-striped progress-bar-animated" 
                    role="progressbar" 
                    style={{ width: `${progress}%` }}
                    aria-valuenow={progress} 
                    aria-valuemin="0" 
                    aria-valuemax="100"
                  >
                    {progress}%
                  </div>
                </div>
                <small className="text-muted mt-1">Subiendo documento...</small>
              </div>
            ) : (
              <div className="mob-document-actions">
                <button
                  className="btn btn-primary"
                  onClick={() => document.getElementById(`camera-${doc.id}`).click()}
                  title="Usar cámara"
                >
                  <FontAwesomeIcon icon={faCamera} className="me-2" />
                  Cámara
                </button>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => document.getElementById(`gallery-${doc.id}`).click()}
                  title="Elegir de galería"
                >
                  <FontAwesomeIcon icon={faImages} className="me-2" />
                  Galería
                </button>
              </div>
            )}
            <input
              id={`camera-${doc.id}`}
              type="file"
              accept="image/*"
              capture="environment"
              className="d-none"
              onChange={(e) => {
                if (e.target.files?.[0]) {
                  handleFileInput(sale, doc.type, doc.id, e.target.files[0]);
                }
              }}
            />
            <input
              id={`gallery-${doc.id}`}
              type="file"
              accept="image/*"
              className="d-none"
              onChange={(e) => {
                if (e.target.files?.[0]) {
                  handleFileInput(sale, doc.type, doc.id, e.target.files[0]);
                }
              }}
            />
          </div>
        )}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-4">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  }

  if (selectedSale) {
    return (
      <div className="container mt-4">
        <h4>Venta #{selectedSale.id}</h4>
        <p className="text-muted">Cliente: {selectedSale.customer.name || selectedSale.customer.dni}</p>
        
        <div className="documents-list mt-4">
          {selectedSale.pending_documents && selectedSale.pending_documents.length > 0 ? (
            selectedSale.pending_documents.map(doc => renderDocumentCard(selectedSale, doc))
          ) : (
            <div className="alert alert-success">
              <i className="bi bi-check-circle-fill me-2"></i>
              Todos los documentos han sido subidos correctamente.
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="mobile-docs-container">
      <div className="container mt-4">
        {/* Solo mostramos filtros para token general */}
        {tokenType === 'seller-general' && (
          <div className="card mb-4">
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar por DNI o número de venta..."
                    value={filters.search}
                    onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
                  />
                </div>
                <div className="col-md-4">
                  <select
                    className="form-select"
                    value={filters.status}
                    onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                  >
                    <option value="">Todos los estados</option>
                    {Object.entries(SALE_STATUSES).map(([value, { label }]) => (
                      <option key={value} value={value}>{label}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row g-4">
          {displayedSales.map((sale) => (
            <div key={sale.id} className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-start mb-3">
                    <div>
                      <h5 className="card-title mb-1">Venta #{sale.id}</h5>
                    </div>
                    <span className={`badge ${SALE_STATUSES[sale.status]?.class}`}>
                      {SALE_STATUSES[sale.status]?.label}
                    </span>
                  </div>
                  
                  <div className='row mb-3'>
                    <div className='col-12'>
                      <p className='text-muted mb-0'>
                        Cliente: <b>{sale.customer.name || sale.customer.dni}</b><br />
                        DNI: <b>{sale.customer.dni}</b>
                      </p>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-6">
                      <small className="text-muted d-block">Fecha</small>
                      <strong>{new Date(sale.sale_date).toLocaleDateString()}</strong>
                    </div>
                    <div className="col-6">
                      <small className="text-muted d-block">Monto</small>
                      <strong>${formatNumber(sale.amount)}</strong>
                    </div>
                  </div>

                  {sale.pending_documents && sale.pending_documents.length > 0 && (
                    <div>
                      <h6 className="mb-3">
                        Documentos pendientes
                      </h6>
                      <div className="documents-grid">
                        {sale.pending_documents.map(doc => renderDocumentCard(sale, doc))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}

          {loadingMore && (
            <>
              <SaleSkeleton />
              <SaleSkeleton />
              <SaleSkeleton />
            </>
          )}

          {/* Solo mostramos "cargar más" para token general */}
          {tokenType === 'seller-general' && 
           pagination.current_page < pagination.last_page && (
            <div className="col-12 text-center mt-4">
              <button 
                className="btn btn-outline-primary"
                onClick={() => fetchSales({ page: pagination.current_page + 1, append: true })}
              >
                Cargar más ventas
              </button>
            </div>
          )}

          {!loading && !loadingMore && displayedSales.length === 0 && (
            <div className="col-12">
              <div className="alert alert-info text-center">
                No se encontraron ventas que coincidan con los filtros aplicados.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileDocumentsPage; 
// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ClientProvider } from './context/ClientContext'; // Importa el ClientProvider
import MobileLayout from './layouts/MobileLayout';

// Importa tus layouts y páginas
import AuthLayout from './layouts/AuthLayout';
import PortalLayout from './layouts/PortalLayout';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage'; // Importa ProfilePage
import ProtectedRoute from './components/ProtectedRoute';
import SalesPage from './pages/SalesPage';
import DocumentsPage from './pages/DocumentsPage';
import MobileDocumentsPage from './pages/MobileDocumentsPage';
import TokenValidationPage from './pages/TokenValidationPage';
import ErrorPage from './pages/ErrorPage';
import MobileErrorPage from './pages/MobileErrorPage';
import MobileOnboardingPage from './pages/MobileOnboardingPage';

function App() {
  return (
    <Router>
      <Routes>
        {/* Rutas de autenticación */}
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>

        {/* Rutas protegidas dentro del Portal */}
        <Route
          element={
            <ProtectedRoute>
              <ClientProvider>
                <PortalLayout />
              </ClientProvider>
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<HomePage />} />
          <Route path="/clientes/:dni" element={<ProfilePage />} />
          <Route path="/ventas" element={<SalesPage />} />
          <Route path="/documentos" element={<DocumentsPage />} />
        </Route>

        {/* Rutas móviles sin layout completo */}
        <Route element={<MobileLayout />}>
          <Route path="/m/docs/:token" element={<TokenValidationPage />} />
          <Route path="/m/docs/:token/sales" element={<MobileDocumentsPage />} />
          <Route path="/m/error/:type" element={<MobileErrorPage />} />
          <Route path="/m/onboarding/:token" element={<MobileOnboardingPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

// src/api/api.js
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Fallback si no está definida
});

// Interceptor para incluir el token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Interceptor para manejar errores de autenticación
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Verificamos si estamos en la versión mobile
      if (window.location.pathname.startsWith('/m/')) {
        // Redirigimos a la página de error mobile con el tipo correspondiente
        const errorType = error.response?.data?.message?.includes('expired') 
          ? 'token_expired' 
          : 'invalid_token';
        window.location.href = `/m/error/${errorType}`;
      } else {
        // Comportamiento normal para versión desktop
        localStorage.removeItem('authToken');
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default api;

// src/components/PurchaseFlow/Step/DocumentUploadStep.js
import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFile, 
  faIdCard, 
  faIdCardAlt, 
  faFileInvoiceDollar, 
  faQrcode,
  faMobileScreen,
  faUserCircle,
  faSmile,
  faIdBadge
} from '@fortawesome/free-solid-svg-icons';
import NavigationButtons from '../../NavigationButtons';
import DocumentItem from '../../../DocumentItem/PendingDocumentItem';
import QRCode from 'qrcode';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '../../../../services/api';
import './DocumentUploadStep.css';

// Documents mapping
const DOCUMENTS_MAP = {
  documentFront: { name: 'DNI Frente', icon: faIdCard },
  documentBack: { name: 'DNI Dorso', icon: faIdCardAlt },
  paycheck: { name: 'Recibo de Sueldo', icon: faFileInvoiceDollar },
  selfie: { name: 'Foto Selfie', icon: faUserCircle },
  selfieSmiling: { name: 'Foto Selfie Sonriendo', icon: faSmile },
  selfieDocument: { name: 'Foto Selfie con Documento', icon: faIdBadge },
};

const MySwal = withReactContent(Swal);

const DocumentUploadStep = ({ onNext, onBack, clientData, purchaseRequestId }) => {
  const [documents, setDocuments] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [temporaryToken, setTemporaryToken] = useState(null);
  const [progress, setProgress] = useState(0);
  const [qrUrl, setQrUrl] = useState(null);
  const [pollingTimeout, setPollingTimeout] = useState(null);
  const [pollingInterval, setPollingInterval] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [qrCanvas, setQrCanvas] = useState(null);

  // Generar token temporal y URL para QR
  useEffect(() => {
    const generateTemporaryToken = async () => {
      try {
        const response = await api.post('/temporary-token/generate', {
          type: 'onboarding',
          scope: ['document_upload'],
          customer_id: clientData.id,
          purchase_request_id: purchaseRequestId,
          expires_in: 30,
          single_use: true
        });
        
        const { token } = response.data.data;
        const mobileUrl = `${window.location.origin}/m/onboarding/${token}`;
        console.log('mobileUrl', mobileUrl);
        setTemporaryToken(token);
        setQrUrl(mobileUrl);
        
        // Generar QR
        if (mobileUrl) {
          QRCode.toCanvas(document.getElementById('qr-canvas'), mobileUrl, {
            width: 150,
            margin: 1,
            color: {
              dark: '#000000',
              light: '#ffffff'
            }
          });
        }
      } catch (error) {
        console.error('Error al generar token temporal:', error);
        MySwal.fire({
          title: 'Error',
          text: 'No se pudo generar el acceso móvil',
          icon: 'error'
        });
      }
    };

    generateTemporaryToken();
  }, [clientData.id, purchaseRequestId]);

  // Estados posibles de documentos:
  // pending: no hay archivo
  // selected: archivo seleccionado localmente pero no subido al servidor
  // uploaded: archivo subido al servidor (desde móvil o después de "Continuar")
  const handleFileUpload = (docType, file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setDocuments((prevDocs) => ({
        ...prevDocs,
        [docType]: {
          ...prevDocs[docType],
          file,
          preview: reader.result,
          status: 'selected',
          uploadedFromMobile: false
        },
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleReject = (docType) => {
    setDocuments((prevDocs) => ({
      ...prevDocs,
      [docType]: { ...prevDocs[docType], file: null, preview: null, status: 'pending' },
    }));
  };

  const startPolling = useCallback(() => {
    const timeout = setTimeout(() => {
      const checkDocuments = async () => {
        try {
          const response = await api.get(
            `/customers/${clientData.id}/purchase-requests/${purchaseRequestId}/documents`
          );
          
          setDocuments(prevDocuments => {
            const updatedDocuments = { ...prevDocuments };
            const newDocuments = [];

            response.data.forEach(serverDoc => {
              if (updatedDocuments[serverDoc.type]) {
                const currentDoc = updatedDocuments[serverDoc.type];
                
                // Solo actualizamos si no está ya subido o si fue subido desde el móvil
                if (serverDoc.status === 'uploaded' && 
                    (currentDoc.status === 'pending' || 
                     currentDoc.status === 'selected')) {
                  
                  updatedDocuments[serverDoc.type] = {
                    ...currentDoc,
                    status: 'uploaded',
                    preview: serverDoc.file.url,
                    uploadedFromMobile: true,
                    title: serverDoc.title,
                    file: null // Limpiamos el archivo local si existe
                  };
                  
                  newDocuments.push(DOCUMENTS_MAP[serverDoc.type]?.name || serverDoc.type);
                }
              }
            });

            if (newDocuments.length > 0) {
              MySwal.fire({
                icon: 'success',
                title: '¡Nuevos documentos!',
                text: `El cliente ha subido: ${newDocuments.join(', ')}`,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
              });
            }

            return newDocuments.length > 0 ? updatedDocuments : prevDocuments;
          });
        } catch (error) {
          console.error('Error verificando documentos:', error);
        }
      };

      checkDocuments();
      const interval = setInterval(checkDocuments, 10000);
      setPollingInterval(interval);
    }, 1); // 180000);

    setPollingTimeout(timeout);
  }, [clientData.id, purchaseRequestId]);

  // Inicializar documentos
  useEffect(() => {
    const requiredDocs = process.env.REACT_APP_FLOW_NEW_REQUIRED_DOCS.split(',');
    const optionalDocs = process.env.REACT_APP_FLOW_NEW_OPTIONAL_DOCS.split(',');
    const initialDocuments = {};

    requiredDocs.forEach((doc) => {
      initialDocuments[doc] = { 
        file: null, 
        preview: null, 
        status: 'pending', 
        optional: false 
      };
    });

    optionalDocs.forEach((doc) => {
      initialDocuments[doc] = { 
        file: null, 
        preview: null, 
        status: 'pending', 
        optional: true 
      };
    });

    setDocuments(initialDocuments);
  }, []); // Solo se ejecuta una vez al montar

  // Iniciar polling
  useEffect(() => {
    startPolling();
  }, [startPolling]);

  // Cleanup del polling
  useEffect(() => {
    return () => {
      if (pollingTimeout) clearTimeout(pollingTimeout);
      if (pollingInterval) clearInterval(pollingInterval);
    };
  }, [pollingTimeout, pollingInterval]);

  useEffect(() => {
    if (qrUrl) {
      QRCode.toCanvas(document.getElementById('qr-canvas'), qrUrl, {
        width: 150,
        margin: 1,
        color: {
          dark: '#000000',
          light: '#ffffff'
        }
      });
    }
  }, [qrUrl]);

  const allRequiredUploaded = () => {
    return Object.values(documents).every(doc => 
      doc.optional || 
      doc.status === 'uploaded' || 
      doc.status === 'selected' // Consideramos también los archivos seleccionados
    );
  };

  const handleNext = async () => {
    if (!allRequiredUploaded()) {
      MySwal.fire({
        icon: 'warning',
        title: 'Información requerida',
        text: 'Por favor, sube todos los documentos requeridos.',
      });
      return;
    }

    // Verificamos archivos pendientes de subir desde la computadora
    const pendingUploads = Object.entries(documents)
      .filter(([_, doc]) => doc.status === 'selected')
      .map(([key, _]) => key);

    if (pendingUploads.length === 0) {
      // No hay archivos para subir, todos fueron subidos desde el móvil
      onNext();
      return;
    }

    // Si hay archivos para subir, procedemos con la carga
    try {
      setIsUploading(true);
      setProgress(0);

      const formData = new FormData();
      pendingUploads.forEach(docType => {
        const doc = documents[docType];
        formData.append('files[]', doc.file);
        formData.append('categories[]', docType);
      });

      await api.post(
        `/customers/${clientData.id}/purchase-requests/${purchaseRequestId}/documents`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (event) => {
            const progressValue = Math.round((event.loaded / event.total) * 100);
            setProgress(progressValue);
          },
        }
      );

      onNext();
    } catch (error) {
      console.error('Error uploading documents:', error);
      setErrorMessage('Hubo un problema al cargar los documentos. Por favor, vuelve a intentarlo.');
    } finally {
      setIsUploading(false);
    }
  };

  const handleShareMobileAccess = () => {
    if (!qrUrl) return;

    MySwal.fire({
      title: 'Subir documentos desde el celular',
      html: `
        <div class="text-center">
          <canvas id="modal-qr-canvas" class="mb-4"></canvas>
          <p class="mb-3">El cliente puede escanear este código QR para subir los documentos desde su celular.</p>
          
          <div class="alert alert-warning d-flex align-items-center gap-2 mb-4" style="font-size: 0.9rem;">
            <div class="container-fluid">
              <div class="d-flex align-items-center gap-2">
                <i class="bi bi-exclamation-triangle-fill"></i>
                <div class="text-start">
                  Este link expirará en 30 minutos.
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="row g-3">
            <div class="col-6">
              <p class="text-muted small mb-2">Copia este enlace:</p>
              <div class="input-group">
                <input 
                  type="text" 
                  value="${qrUrl}" 
                  class="form-control form-control-sm"
                  readonly
                />
                <button 
                  class="btn btn-outline-secondary copy-button"
                  type="button"
                  style="border: 1px solid #dce7f1 !important"
                  onclick="copyToClipboard('${qrUrl}', this)"
                >
                  <i class="bi bi-clipboard"></i>
                </button>
              </div>
            </div>
            <div class="col-6">
              <p class="text-muted small mb-2">O comparte con el cliente:</p>
              <a 
                href="https://wa.me/?text=${encodeURIComponent(
                  `Puedes subir tus documentos desde aquí: ${qrUrl}`
                )}" 
                target="_blank" 
                class="btn btn-success w-100"
                style="background-color: #25D366; border-color: #25D366"
              >
                <i class="bi bi-whatsapp me-2"></i>
                Enviar por WhatsApp
              </a>
            </div>
          </div>
        </div>
      `,
      showConfirmButton: false,
      showCloseButton: true,
      didOpen: () => {
        // Generar QR en el modal
        QRCode.toCanvas(document.getElementById('modal-qr-canvas'), qrUrl, {
          width: 200,
          margin: 2,
          color: {
            dark: '#000000',
            light: '#ffffff'
          }
        });

        window.copyToClipboard = (text, button) => {
          navigator.clipboard.writeText(text).then(() => {
            const originalContent = button.innerHTML;
            button.innerHTML = '<i class="bi bi-check"></i>';
            button.disabled = true;
            setTimeout(() => {
              button.innerHTML = originalContent;
              button.disabled = false;
            }, 2000);
          });
        };
      }
    });
  };

  // Modificamos el renderizado del DocumentItem para mostrar el preview
  const renderDocumentItem = (key, doc) => (
    <DocumentItem
      key={key}
      icon={DOCUMENTS_MAP[key]?.icon || faFile}
      name={DOCUMENTS_MAP[key]?.name || key}
      optional={doc.optional}
      status={doc.status}
      onFileUpload={(file) => handleFileUpload(key, file)}
      filePreview={doc.preview}
      onReject={() => handleReject(key)}
      isUploading={isUploading}
      uploadedFromMobile={doc.uploadedFromMobile}
      onPreviewClick={() => {
        if (doc.preview) {
          MySwal.fire({
            title: doc.title || DOCUMENTS_MAP[key]?.name || key,
            imageUrl: doc.preview,
            imageAlt: 'Vista previa del documento',
            width: '80%',
            confirmButtonText: 'Cerrar'
          });
        }
      }}
    />
  );

  return (
    <div id="documentUploadStep" className="container my-5">
      <h4 className="text-center mb-4">Carga de Documentos</h4>

      <div className="row justify-content-center align-items-start">
        {/* Columna Documentos */}
        <div className="col-md-8">
          <h5>Desde tu computadora</h5>
          <p className="text-muted small mb-4">
            Arrastra y suelta los documentos en las áreas correspondientes.
          </p>

          <ul className="document-list">
            {Object.entries(documents).map(([key, doc]) => 
              renderDocumentItem(key, doc)
            )}
          </ul>
        </div>

        {/* Columna QR */}
        <div className="col-md-4">
          <div className="qr-section text-center">
            <div className="card">
              <div className="card-body p-1">
                <FontAwesomeIcon icon={faMobileScreen} className="display-4 text-primary mb-3" />
                <h5>¿Prefieres usar el celular?</h5>
                <p className="text-muted small mb-4">
                  Escanea el código QR para subir los documentos desde tu teléfono
                </p>
                
                {qrUrl && (
                  <div className="qr-container mb-3">
                    <canvas id="qr-canvas" className="mb-3"></canvas>
                    <button 
                      className="btn btn-outline-primary btn-sm"
                      onClick={handleShareMobileAccess}
                    >
                      <i className="bi bi-share me-2"></i>
                      Compartir acceso
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isUploading && (
        <>
          <div className="progress mt-3">
            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
              {progress}%
            </div>
          </div>
          <span>Subiendo documentos...</span>
        </>
      )}

      {errorMessage && (
        <div className="alert alert-warning text-center mt-3" role="alert">
          <i className="bi bi-exclamation-triangle me-2"></i>
          {errorMessage}
        </div>
      )}

      <NavigationButtons
        onBack={onBack}
        onNext={handleNext}
        isNextDisabled={!allRequiredUploaded()}
        isLoading={isUploading}
      />
    </div>
  );
};

export default DocumentUploadStep;

export const handleValidationErrors = (error) => {
  // Si es un error de validación (422)
  if (error.response?.status === 422) {
    const errors = error.response.data.errors;
    
    // Convertir los errores en una lista formateada
    const errorMessages = Object.entries(errors)
      .map(([field, messages]) => {
        // Si hay múltiples mensajes para un campo, los unimos
        const messageList = Array.isArray(messages) ? messages.join('\n') : messages;
        return `${messageList}`;
      })
      .join('\n');

    return {
      title: 'Error de validación',
      html: `<div class="text-start"><ul class="list-unstyled mb-0">${
        errorMessages.split('\n')
          .map(msg => `<li><i class="bi bi-exclamation-circle text-danger me-2"></i>${msg}</li>`)
          .join('')
      }</ul></div>`,
      icon: 'error'
    };
  }

  // Para otros tipos de errores, devolver un mensaje genérico
  return {
    title: 'Error',
    text: error.response?.data?.message || 'Ha ocurrido un error inesperado',
    icon: 'error'
  };
}; 
// src/components/PurchaseFlow/Step/DocumentItem.js
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock, faTimesCircle, faEye, faMobileScreen } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';

const PendingDocumentItem = ({ icon, name, status, onFileUpload, filePreview, onReject, isUploading, uploadedFromMobile, optional }) => {
  const [showModal, setShowModal] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!isUploading) {
        onFileUpload(acceptedFiles[0]);
      }
    },
    [onFileUpload, isUploading]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, disabled: isUploading });

  const handlePreviewClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isUploading) {
      setShowModal(true);
    }
  }, [isUploading]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <li className={`document-item ${status}`} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="document-info">
          <FontAwesomeIcon icon={icon} size="2x" className="me-2" />
          <span className="document-name">{name} {optional && <span className="badge bg-secondary rounded-pill ms-2"><small>Opcional</small></span>}</span>
          {uploadedFromMobile && (
            <span className="badge bg-primary ms-2">
              <FontAwesomeIcon icon={faMobileScreen} className="me-1" />
              Subido desde móvil
            </span>
          )}
        </div>
        <div className="document-status">
          {status === 'selected' || status === 'uploaded' ? (
            <>
              <button
                className="btn btn-link btn-sm text-success"
                onClick={handlePreviewClick}
                disabled={isUploading}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              <FontAwesomeIcon icon={faCheckCircle} className="status-icon success" />
            </>
          ) : (
            <FontAwesomeIcon icon={faClock} className="status-icon pending" />
          )}
        </div>
      </li>
      {showModal && (
          <div className="modal-backdrop document-viewer-modal-backdrop fade show"></div>
      )}
      <Modal
        show={showModal && !!filePreview}
        onHide={handleCloseModal}
        size="lg"
        centered
        className="document-preview-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <img
            src={filePreview}
            alt="Vista previa del documento"
            style={{ 
              maxWidth: '100%', 
              maxHeight: '70vh',
              display: 'block',
              margin: '0 auto'
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button 
            className="btn btn-secondary"
            onClick={handleCloseModal}
          >
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PendingDocumentItem;

// src/components/Profile/CurrentAccount.js
import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import Skeleton from 'react-loading-skeleton';
import LoanCard from './LoanCard';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

const CurrentAccount = () => {
  const [loans, setLoans] = useState([]);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const response = await api.get('account');
        setLoans(response.data.loans);
        setPayments(response.data.payments);
      } catch (error) {
        console.error('Error al obtener los datos de la cuenta:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountData();
  }, []);

  useEffect(() => {
    const initialLoans = [
      {
        id: '12345',
        issueDate: '01/01/2024',
        currentBalance: 5000,
        totalBalance: 12000,
        installments: [
          {
            installmentNumber: 1,
            dueDate: '15/01/2024',
            amount: 1000,
            paymentsMade: 500,
            penalties: 50,
            amountDue: 550,
          },
          {
            installmentNumber: 2,
            dueDate: '15/02/2024',
            amount: 1000,
            paymentsMade: 0,
            penalties: 0,
            amountDue: 1000,
          },
          {
            installmentNumber: 3,
            dueDate: '15/03/2024',
            amount: 1000,
            paymentsMade: 0,
            penalties: 0,
            amountDue: 1000,
          },
        ],
      },
      {
        id: '455612',
        issueDate: '12/06/2024',
        currentBalance: 9000,
        totalBalance: 12000,
        installments: [
          {
            installmentNumber: 1,
            dueDate: '15/07/2024',
            amount: 1000,
            paymentsMade: 500,
            penalties: 50,
            amountDue: 550,
          },
          {
            installmentNumber: 2,
            dueDate: '15/08/2024',
            amount: 1000,
            paymentsMade: 0,
            penalties: 0,
            amountDue: 1000,
          },
          {
            installmentNumber: 3,
            dueDate: '15/09/2024',
            amount: 1000,
            paymentsMade: 0,
            penalties: 0,
            amountDue: 1000,
          },
        ],
      },
    ];
    setLoans(initialLoans);

    const initialPayments = [
      {
        id: 'pay123',
        date: '01/08/2024',
        amount: 1000,
        status: 'Pendiente',
        method: 'Tarjeta de Crédito',
      },
      {
        id: 'pay456',
        date: '15/08/2024',
        amount: 500,
        status: 'Confirmado',
        method: 'Transferencia Bancaria',
      },
      {
        id: 'pay789',
        date: '20/08/2024',
        amount: 1500,
        status: 'Anulado',
        method: 'Efectivo',
      },
    ];
    setPayments(initialPayments);
  }, []);

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'Confirmado':
        return 'bg-light-success';
      case 'Anulado':
        return 'bg-light-danger';
      case 'Pendiente':
        return 'bg-light-warning';
      case 'Completado':
        return 'bg-light-success';
      default:
        return 'bg-light-secondary';
    }
  };

  const handleCancelPayment = (paymentId) => {
    const paymentToCancel = payments.find((payment) => payment.id === paymentId);
    if (paymentToCancel && paymentToCancel.status !== 'Anulado') {
      Swal.fire({
        title: '¿Anular pago?',
        text: `¿Estás seguro de que quieres anular el pago de $${paymentToCancel.amount}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, anular',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedPayments = payments.map((payment) =>
            payment.id === paymentId ? { ...payment, status: 'Anulado' } : payment
          );
          setPayments(updatedPayments);
          Swal.fire('Anulado', 'El pago ha sido anulado.', 'success');
        }
      });
    }
  };

  const handlePrintReceipt = (paymentId) => {
    const paymentToPrint = payments.find((payment) => payment.id === paymentId);
    if (paymentToPrint) {
      Swal.fire({
        title: 'Imprimir comprobante',
        text: `Pago de $${paymentToPrint.amount} realizado el ${paymentToPrint.date} por ${paymentToPrint.method}.`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Imprimir',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(`Imprimiendo comprobante para el pago ${paymentId}`);
          Swal.fire('Comprobante impreso', '', 'success');
        }
      });
    }
  };

  if (loading) {
    return (
      <>
        <h5 className='mb-3'>Préstamos Vigentes</h5>
        <div className="mb-4" >
          <Skeleton height={68} className="mb-3" />
          <Skeleton height={68} className="mb-3" />
        </div>  

        <h5 className='mt-5 mb-3'>Últimos Pagos</h5>
        <div className="mb-4" >
          <Skeleton height={20} className="mb-1" />
          <Skeleton height={20} className="mb-1" />
          <Skeleton height={20} className="mb-1" />
          <Skeleton height={20} className="mb-1" />
        </div>
      </>
    );
  }

  return (
    <div>
      <h5 className='mb-3'>Préstamos Vigentes</h5>
      <div>
        {loans.length > 0 ? (
          loans.map((loan) => (
            <LoanCard 
              key={loan.id} 
              loan={loan} 
            />
          ))
        ) : (
          <p>No hay préstamos vigentes.</p>
        )}
      </div>
      
      <h5 className='mt-5 mb-3'>Últimos Pagos</h5>
      <div className="table-responsive">
        {payments.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Monto</th>
                <th>Medio de Pago</th>
                <th>Status</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr key={payment.id}>
                  <td>{payment.date}</td>
                  <td>${payment.amount}</td>
                  <td>{payment.method}</td>
                  <td>
                    <span className={`badge ${getStatusBadgeClass(payment.status)}`}>
                      {payment.status}
                    </span>
                  </td>
                  <td>
                    <button
                      className="btn btn-reimprimir p-1 border-0"
                      onClick={() => handlePrintReceipt(payment.id)}
                    >
                      <i className="bi bi-printer"></i>
                    </button>
                    <button
                      className="btn btn-anular p-1 border-0"
                      onClick={() => handleCancelPayment(payment.id)}
                      disabled={payment.status === 'Anulado'}
                    >
                      <i className="bi bi-x-circle"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No hay pagos recientes.</p>
        )}
      </div>
    </div>
  );
};

export default CurrentAccount;

// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [user, setUser] = useState(null);
  const [channelId, setChannel] = useState(null);
  const [branchId, setBranch] = useState(null);
  const [commerceName, setCommerceName] = useState(null);
  const [commerceLogo, setCommerceLogo] = useState(null);
  const [branchName, setBranchName] = useState(null);
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const storedUser = localStorage.getItem('user');
    const storedBranch = localStorage.getItem('branchId');
    const storedChannel = localStorage.getItem('channelId');
    const storedCommerce = localStorage.getItem('commerceName');
    const storedLogo = localStorage.getItem('commerceLogo');
    const storedBranchName = localStorage.getItem('branchName');
    const storedConfig = localStorage.getItem('config');

    if (token && token !== 'undefined') {
      const decodedToken = jwtDecode(token);

      if (decodedToken.exp * 1000 < Date.now()) {
        logout();
      } else {
        setAuthToken(token);
        setUser(JSON.parse(storedUser));
        setBranch(storedBranch);
        setChannel(storedChannel);
        setCommerceName(storedCommerce);
        setCommerceLogo(storedLogo);
        setBranchName(storedBranchName);
        setConfig(JSON.parse(storedConfig));
      }
    }
    setLoading(false);
  }, []);

  const login = (token, userData, channelId, branchId, commerceName, branchName, commerceLogo, config) => {
    setAuthToken(token);
    setUser(userData);
    setChannel(channelId);
    setBranch(branchId);
    setCommerceName(commerceName);
    setCommerceLogo(commerceLogo);
    setBranchName(branchName);
    setConfig(config);
    localStorage.setItem('authToken', token);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('branchId', branchId);
    localStorage.setItem('channelId', channelId);
    localStorage.setItem('commerceName', commerceName);
    localStorage.setItem('commerceLogo', commerceLogo);
    localStorage.setItem('branchName', branchName);
    localStorage.setItem('config', JSON.stringify(config));
  };

  const logout = async () => {
    try {
      await api.post('/logout', {}, { headers: { Authorization: `Bearer ${authToken}` } });
    } catch (error) {
      console.error('Error during logout:', error);
    }
    setAuthToken(null);
    setUser(null);
    setChannel(null);
    setBranch(null);
    setCommerceName(null);
    setCommerceLogo(null);
    setBranchName(null);
    setConfig(null);
    localStorage.clear();
  };  

  return (
    <AuthContext.Provider
      value={{
        authToken,
        user,
        channelId,
        branchId,
        commerceName,
        branchName,
        commerceLogo,
        config,
        setConfig,
        login,
        logout,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

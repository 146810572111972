import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="footer clearfix mb-0 text-muted">
          <div className="float-start">
            <small className="text-muted">
              {process.env.REACT_APP_VERSION || 'dev'}
            </small>
          </div>
          <div className="float-end">
            <p className="mb-2">
              RetailGo by
              <Link to="https://cirenio.com">
                <img 
                  className="ms-1 mb-1 dark-hidden" 
                  src="/assets/images/logo-cirenio.svg" 
                  height="18px" 
                  alt="Logo de Cirenio" 
                />
              </Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

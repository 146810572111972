// src/context/ClientContext.js
import React, { createContext, useState } from 'react';

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const [clientData, setClientData] = useState(null);
  const [clientStatus, setClientStatus] = useState(null);
  const [clientAuthorizations, setClientAuthorizations] = useState(null);

  const value = {
    clientData,
    setClientData,
    clientStatus,
    setClientStatus,
    clientAuthorizations,
    setClientAuthorizations,
  };

  return (
    <ClientContext.Provider value={value}>
      {children}
    </ClientContext.Provider>
  );
};

// src/components/PaymentFlow/LoanCard.js

import React, { useState, useEffect } from 'react';
import InstallmentRow from './InstallmentRow';
import { formatCurrency, formatDate } from '../../utils/numberFormatter';
import api from '../../services/api';
import Skeleton from 'react-loading-skeleton';

const LoanCard = ({ loan, clientId, onInstallmentSelection }) => {
  const [expanded, setExpanded] = useState(false);
  const [installments, setInstallments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totals, setTotals] = useState(null);

  // Inicializa el estado de las cuotas
  const [installmentsState, setInstallmentsState] = useState([]);

  useEffect(() => {
    setInstallmentsState(
      loan.installments.map((installment, index) => ({
        ...installment,
        isChecked: installment.isChecked || false,
        amountToPay: installment.amountToPay || '',
        inputDisabled: installment.inputDisabled !== undefined ? installment.inputDisabled : index !== 0,
        checkboxDisabled: installment.checkboxDisabled !== undefined ? installment.checkboxDisabled : index !== 0,
        inputError: false,
      }))
    );
  }, [loan]);

  const toggleInstallments = () => {
    setExpanded(!expanded);
  };

  const handleInstallmentChange = (index, newState) => {
    setInstallmentsState((prev) => {
      const updated = prev.map((installment, idx) => {
        if (idx === index) {
          return { ...installment, ...newState };
        }
        return { ...installment };
      });
  
      // Lógica para habilitar/deshabilitar cuotas siguientes
      if (
        parseFloat(updated[index].amountToPay) >= parseFloat(updated[index].amountDue) &&
        !updated[index].inputError
      ) {
        if (index + 1 < updated.length) {
          updated[index + 1] = {
            ...updated[index + 1],
            inputDisabled: false,
            checkboxDisabled: false,
          };
        }
      } else {
        // Deshabilitar todas las cuotas siguientes
        for (let i = index + 1; i < updated.length; i++) {
          updated[i] = {
            ...updated[i],
            inputDisabled: true,
            checkboxDisabled: true,
            isChecked: false,
            amountToPay: '',
          };
        }
      }
  
      return updated;
    });
  };  

  const fetchInstallments = async () => {
    if (!expanded || installments.length > 0) return;
    
    try {
      setLoading(true);
      const response = await api.get(`/customers/${clientId}/loans/${loan.uid}/installments`);
      
      setInstallments(response.data.installments);
      setTotals(response.data.totals);
    } catch (error) {
      console.error('Error fetching installments:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    installmentsState.forEach((installment, index) => {
      onInstallmentSelection(loan.id, index, parseFloat(installment.amountToPay) || 0);
    });
  }, [installmentsState]);

  useEffect(() => {
    fetchInstallments();
  }, [expanded]);

  return (
    <div className={`card mb-3 border border-primary shadow-sm loan-card ${expanded ? 'expanded' : ''}`}>
      <div
        className="card-header d-flex justify-content-between p-2 align-items-center"
        onClick={toggleInstallments}
      >
        <div className="col-6 text-left">
          <h6 className="text-primary mb-1">
            <strong>Venta #{loan.id}</strong>
          </h6>
          <p className="mb-0 small">
            <strong>Fecha:</strong> { formatDate(loan.issueDate) }
          </p>
          <p className="mb-0 small">
            <strong>Producto:</strong> {loan.product}
          </p>
        </div>
        <div className="col-5">
          <p className={`mb-0 ${loan.overdueAmount > 0 ? 'text-danger' : ''}`}>
            <strong>Saldo Actual:</strong> { formatCurrency(loan.totalDebt) }
          </p>
          <p className="mb-0">
            <strong>Saldo Total:</strong> { formatCurrency(loan.outstandingBalance) }
          </p>
        </div>
        <div>
          <i className={`bi ${expanded ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
        </div>
      </div>

      {expanded && (
        <div className="card-body p-0">
          {loading ? (
            <div className="p-3">
              <Skeleton count={3} height={40} className="mb-2" />
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table mb-1 small table-striped table-borderless">
                <thead>
                  <caption className="m-0 p-0"><strong>Cuotas</strong></caption>
                  <tr className="mb-0 small">
                    <th>#</th>
                    <th>Vencimiento</th>
                    <th>Total</th>
                    <th>Punitorios</th>
                    <th>Pagado</th>
                    <th>Pendiente</th>
                    {/* <th>Sel.</th>
                    <th>A Pagar</th> */}
                  </tr>
                </thead>
                <tbody>
                  {installments.map((installment) => (
                    <InstallmentRow
                      key={installment.number}
                      installment={installment}
                      onInstallmentSelection={onInstallmentSelection}
                      onInstallmentChange={handleInstallmentChange}
                      loanId={loan.id}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LoanCard;

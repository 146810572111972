// src/components/PurchaseFlow/Step/OfferStep.js
import React, { useState, useEffect, useContext } from 'react';
import api from '../../../../services/api';
import Skeleton from 'react-loading-skeleton';
import NavigationButtons from '../../NavigationButtons';
import { formatNumber, parseLocaleNumber } from '../../../../utils/numberFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faBlender, faMobileAlt, faHome, faLaptop, faBox, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import 'react-loading-skeleton/dist/skeleton.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './OfferStep.css';
import { SelectedOfferContext } from '../../../../context/SelectedOfferContext';
import { ClientContext } from '../../../../context/ClientContext';
import { useAuth } from '../../../../context/AuthContext';
import AuthorizationModal from '../../../PurchaseFlow/AuthorizationModal';
import { handleValidationErrors } from '../../../../utils/errorHandler';

const MySwal = withReactContent(Swal);

const OfferStep = ({ onNext, clientData, setPurchaseRequestId }) => {
  const { setSelectedOffer } = useContext(SelectedOfferContext);
  const { clientAuthorizations } = useContext(ClientContext);
  const [amount, setAmount] = useState('');
  const [displayAmount, setDisplayAmount] = useState('');
  const [maxAmountState, setMaxAmount] = useState(clientData?.available_credit || 0);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [authorizationData, setAuthorizationData] = useState(null); // Estado para autorización manual
  const [categories, setCategories] = useState([]);
  const [authorizationsEnabled] = useState(process.env.REACT_APP_ENABLE_AUTHORIZATIONS === 'true');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [lastCalculatedAmount, setLastCalculatedAmount] = useState('');
  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false);
  const [requestedAmount, setRequestedAmount] = useState(null);
  const { channelId, branchId } = useAuth();
  useEffect(() => {
    const loadCategories = () => {
      const categoriesConfig = process.env.REACT_APP_PRODUCT_CATEGORIES;
      if (!categoriesConfig) return [];

      return categoriesConfig.split(',').map(category => {
        const [id, label, iconName] = category.split(':');
        const icon = {
          faBlender,
          faMobileAlt,
          faHome,
          faLaptop
        }[iconName] || faBlender;

        return { id, label, icon };
      });
    };

    setCategories(loadCategories());
  }, []);

  const handleRequestAuthorization = async (payload) => {
    try {
      const apiPayload = {
        "commerce_id": channelId,
        "channel_branch_id": branchId,
        "amount": payload.amount,
        "observation": payload.observation,
        "password": payload.password
      }

      const response = await api.post(`/customers/${clientData.id}/authorizations`, apiPayload);
      
      if (response.status === 201) {
        setShowAuthorizationModal(false);
        
        MySwal.fire({
          title: 'Operación exitosa',
          text: response.data.message,
          icon: response.data.authorization.status === 'approved' ? 'success' : 'info',
        });

        if(response.data.authorization.status === 'approved') {
        }
      }



      MySwal.fire({
        title: 'Autorización pendiente',
        text: 'Tu solicitud ha sido enviada. Por favor espera a que sea procesada.',
        icon: 'info',
      });
    } catch (error) {
      console.error('Error al solicitar autorización:', error);
      const errorConfig = handleValidationErrors(error);
      MySwal.fire(errorConfig);
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9,]/g, '');
    
    if (value === '') {
      setAmount('');
      setDisplayAmount('');
      setPlans([]);
      setSelectedPlan(null);
      setLastCalculatedAmount('');
      return;
    }

    // Convertir al formato español para mostrar
    const numericValue = parseLocaleNumber(value);
    if (!isNaN(numericValue)) {
      setAmount(numericValue.toString());
      setDisplayAmount(formatNumber(numericValue));
      setPlans([]);
      setSelectedPlan(null);
    }
  };

  const handleRequestMoreCredit = (e) => {
    e?.preventDefault();
    setRequestedAmount(null);
    setShowAuthorizationModal(true);
  };

  const handleAmountValidation = () => {
    const numericAmount = parseFloat(amount);
    if (!numericAmount || numericAmount <= 0) {
      MySwal.fire({
        icon: 'warning',
        title: 'Monto inválido',
        text: 'Por favor, ingresa un monto válido.',
      });
      return false;
    }

    if (numericAmount > maxAmountState) {
      if (authorizationsEnabled) {
        MySwal.fire({
          icon: 'warning',
          title: 'Monto excede el límite disponible',
          html: `
            El monto ingresado supera tu límite disponible por $${formatNumber(numericAmount - maxAmountState)}.<br><br>
            ¿Deseas solicitar una autorización por $${formatNumber(numericAmount)}?
          `,
          showConfirmButton: true,
          confirmButtonText: `Solicitar autorización por $${formatNumber(numericAmount)}`,
          showCancelButton: true,
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            setRequestedAmount(numericAmount);
            setShowAuthorizationModal(true);
          }
        });
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Límite excedido',
          text: `El monto no puede superar el límite disponible de $${formatNumber(maxAmountState)}.`,
          confirmButtonText: 'Entendido'
        });
      }
      return false;
    }

    return true;
  };

  const fetchPlans = async () => {
    if (!handleAmountValidation()) return;

    // Validar que el monto haya cambiado
    if (amount === lastCalculatedAmount) {
      MySwal.fire({
        icon: 'info',
        title: 'Monto sin cambios',
        text: 'Modifica el monto para calcular nuevas cuotas.',
      });
      return;
    }

    if (!selectedCategory && categories.length > 0) {
      MySwal.fire({
        icon: 'warning',
        title: 'Categoría requerida',
        text: 'Por favor, selecciona una categoría de producto.',
      });
      return;
    }

    if (!productName.trim()) {
      MySwal.fire({
        icon: 'warning',
        title: 'Nombre del producto requerido',
        text: 'Por favor, ingresa el nombre del producto.',
      });
      return;
    }

    setLoading(true);
    try {
      const response = await api.post(`/customers/${clientData.id}/purchase-requests`, {
        industry: selectedCategory || 'default',
        industry_id: 1, // TO-DO include industry_id in the request
        amount: parseFloat(amount),
        product_name: productName.trim(),
        product_description: productDescription.trim() || null,
      });

      setPurchaseRequestId(response.data.id);
      const updatedOffers = response.data.offers.map((offer) => ({
        ...offer,
        purchase_request_id: response.data.id,
      }));

      setLastCalculatedAmount(amount); // Guardar el monto calculado
      setPlans(updatedOffers);
      setSelectedPlan(null); // Asegurar que no hay plan seleccionado

      if (updatedOffers.length === 0) {
        MySwal.fire({
          icon: 'info',
          title: 'No hay planes disponibles',
          html: `
            <div class="text-start">
              <p class="mb-3">
                En este momento no podemos ofrecerte planes de financiación para este monto.
                Esto puede deberse a diferentes motivos:
              </p>
              <ul class="list-unstyled">
                <li class="mb-2">
                  <i class="bi bi-circle-fill me-2 text-primary" style="font-size: 8px;"></i>
                  El monto solicitado supera tu límite máximo de cuota mensual disponible
                </li>
                <li class="mb-2">
                  <i class="bi bi-circle-fill me-2 text-primary" style="font-size: 8px;"></i>
                  El monto es inferior al mínimo requerido para financiar en cuotas
                </li>
                <li class="mb-2">
                  <i class="bi bi-circle-fill me-2 text-primary" style="font-size: 8px;"></i>
                  Existen restricciones temporales en tu perfil crediticio
                </li>
              </ul>
              <p class="mt-3">
                Te sugerimos:
              </p>
              <ul class="list-unstyled">
                <li class="mb-2">
                  <i class="bi bi-check2 me-2 text-success"></i>
                  Probar con un monto diferente
                </li>
                <li class="mb-2">
                  <i class="bi bi-check2 me-2 text-success"></i>
                  Consultar por otras opciones de pago
                </li>
                ${authorizationsEnabled ? `
                <li class="mb-2">
                  <i class="bi bi-check2 me-2 text-success"></i>
                  <a href="#" onclick="return false;" class="text-decoration-none" id="requestMoreCredit">
                    Solicitar una autorización especial
                  </a>
                </li>
                ` : ''}
              </ul>
            </div>
          `,
          confirmButtonText: 'Entendido',
          showCloseButton: true,
          didOpen: () => {
            if (authorizationsEnabled) {
              document.getElementById('requestMoreCredit').addEventListener('click', () => {
                MySwal.close();
                setShowAuthorizationModal(true);
              });
            }
          }
        });
      }
    } catch (error) {
      console.error('Error al crear la PurchaseRequest:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se pudieron obtener los planes. Por favor, intenta nuevamente.',
      });
    } finally {
      setLoading(false);
    }
  };

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const handleNext = async () => {
    if (!selectedPlan) {
      Swal.fire({
        icon: 'warning',
        title: 'Selección requerida',
        text: 'Por favor, selecciona un plan antes de continuar.',
      });
      return;
    }

    // Validar que el monto actual coincida con el monto calculado
    if (amount !== lastCalculatedAmount) {
      Swal.fire({
        icon: 'warning',
        title: 'Monto modificado',
        text: 'El monto ha cambiado. Por favor, calcula las cuotas nuevamente.',
      });
      return;
    }

    try {
      const applicantId = clientData?.id;

      await api.post(
        `/customers/${applicantId}/purchase-requests/${selectedPlan.purchase_request_id}/select-offer`,
        { offer_id: selectedPlan.id }
      );

      // Guardar la oferta seleccionada en el contexto
      setSelectedOffer({
        amount: parseFloat(amount),
        term: selectedPlan.installments,
        interestRate: selectedPlan.interest_rate,
        monthlyPayment: selectedPlan.installment_amount,
        firstDueDate: selectedPlan.first_due_date_formatted
      });

      onNext({ selectedPlan });
    } catch (error) {
      console.error('Error al seleccionar la oferta:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un problema al seleccionar la oferta. Intenta nuevamente.',
      });
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      fetchPlans();
    }
  };

  const renderSkeletons = () => {
    return (
      <div className="row justify-content-center mt-5">
        {[...Array(3)].map((_, index) => (
          <div className="col-md-4" key={index}>
            <div className="card offer-option">
              <div className="card-body text-center">
                <Skeleton height={30} width={150} />
                <Skeleton height={20} width={100} className="mt-2" />
                <Skeleton height={20} width={180} className="mt-2" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="row pt-3 d-flex justify-content-center">
      <h4 className="text-center mb-4">¿Qué producto vas a vender?</h4>

      {/* Selector de Categorías - Solo se muestra si hay categorías configuradas */}
      {categories.length > 0 && !selectedCategory && (
        <div className="row justify-content-center">
          {categories.map((category) => (
            <div className="col-md-3" key={category.id}>
              <div
                className={`card category-option ${selectedCategory === category.id ? 'selected' : ''}`}
                onClick={() => setSelectedCategory(category.id)}
                style={{ cursor: 'pointer' }}
              >
                <div className="card-body text-center">
                  <FontAwesomeIcon icon={category.icon} size="2x" className="mb-3" />
                  <h5 className="card-title">{category.label}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Campos de producto y monto */}
      {(selectedCategory || categories.length === 0) && (
        <div className="col-md-8">
          {/* Campo de nombre del producto */}
          <div className="form-group mb-3">
            <div className="input-group">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faBox} />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Nombre del producto (ej: Heladera Samsung RT29K577J)"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
            </div>
          </div>

          {/* Campo de descripción del producto (opcional) */}
          <div className="form-group mb-3">
            <div className="input-group">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faFileAlt} />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Descripción (opcional)"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
              />
            </div>
          </div>

          {/* Input de monto - Se muestra si hay categoría seleccionada o no hay categorías configuradas */}
          <div className="input-group">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faDollarSign} />
            </span>
            <input
              type="text"
              className="form-control"
              id="amount"
              name="amount"
              autoComplete="off"
              placeholder="¿Cuál es el precio?"
              required
              value={displayAmount}
              onChange={handleAmountChange}
              onKeyPress={handleInputKeyPress}
            />
            <button 
              className="btn btn-primary" 
              type="button" 
              onClick={fetchPlans}
              disabled={amount === lastCalculatedAmount || !amount} // Deshabilitar si el monto no cambió o está vacío
            >
              Calcular Cuotas
            </button>
          </div>
          <p>
            <small className="text-muted">
              {`Monto máximo ${clientAuthorizations?.status === 'approved' ? 'autorizado' : 'disponible'}: $${formatNumber(maxAmountState)}.`}
              {authorizationsEnabled && (
                <a href="#" onClick={handleRequestMoreCredit}>
                  Solicitar más cupo
                </a>
              )}
            </small>
          </p>
        </div>
      )}

      {loading ? (
        renderSkeletons()
      ) : (
        plans.length > 0 && (
          <>
            <div id="plansContainer" className="row justify-content-center mt-5">
            {plans.map((plan) => (
              <div className="col-md-4" key={plan.id}>
                <div className={`card offer-option ${selectedPlan?.id === plan.id ? 'selected' : ''}`} onClick={() => selectPlan(plan)} style={{ cursor: 'pointer' }} >
                  <div className="card-body text-center">
                    <h4 className="card-title">{plan.installments} Cuotas</h4>
                    <p className="card-text">
                      {`de $${formatNumber(plan.installment_amount)}`}
                    </p>
                    <p className="card-text"> {`Primera cuota: ${plan.first_due_date_formatted}`}</p>
                  </div>
                </div>
              </div>
              ))}
            </div>
            <NavigationButtons onNext={handleNext} nextLabel="Continuar" />
            
          </>
        )
      )}

      <AuthorizationModal
        show={showAuthorizationModal}
        onClose={() => setShowAuthorizationModal(false)}
        onRequest={handleRequestAuthorization}
        initialAmount={requestedAmount}
      />
    </div>
  );
};

export default OfferStep;

// src/components/PaymentFlow/InstallmentRow.js

import React, { useState, useEffect } from 'react';
import { formatCurrency, formatDate } from '../../utils/numberFormatter';

const InstallmentRow = ({ installment, loanId, onInstallmentSelection }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [amountToPay, setAmountToPay] = useState('');
  const [inputError, setInputError] = useState(false);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    
    if (checked) {
      const amount = installment.balances.remaining.total;
      setAmountToPay(amount.toString());
      onInstallmentSelection(loanId, installment.number, amount);
    } else {
      setAmountToPay('');
      onInstallmentSelection(loanId, installment.number, 0);
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmountToPay(value);
    
    const amount = parseFloat(value) || 0;
    const remainingAmount = installment.balances.remaining.total;

    const error = amount > remainingAmount;
    setInputError(error);

    if (!error) {
      onInstallmentSelection(loanId, installment.number, amount);
    }
  };

  const isOverdue = new Date(installment.dates.due_date) < new Date();

  return (
    <tr className="mb-0 small">
      <td>{installment.number}</td>
      <td>{formatDate(installment.dates.due_date,2)}</td>
      <td>{formatCurrency(installment.financial.total,2)}</td>
      <td className={isOverdue ? 'text-danger' : ''}>{ installment.financial.punitives.total ? formatCurrency(installment.financial.punitives.total,2) : '-'}</td>
      <td>{formatCurrency(installment.balance.paid.total,2)}</td>
      <td className={isOverdue ? 'text-danger' : ''}>{formatCurrency(installment.balance.remain.total,2)}</td>
      {/* <td>
        <input
          type="checkbox"
          className="form-check-input"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
      </td>
      <td>
        <input
          type="number"
          className={`form-control form-control-sm ${inputError ? 'is-invalid' : ''}`}
          value={amountToPay}
          onChange={handleAmountChange}
          disabled={!isChecked}
          min="0"
          max={installment.balances.remaining.total}
          step="0.01"
        />
        {inputError && (
          <div className="invalid-feedback">
            Excede el monto pendiente
          </div>
        )}
      </td> */}
    </tr>
  );
};

export default InstallmentRow;

// src/pages/HomePage.js
import React, { useState, useEffect } from 'react';
import api from '../services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function HomePage() {
  // const [compras, setCompras] = useState([]);
  // const [pagos, setPagos] = useState([]);

  // useEffect(() => {}, []);

  // // Función para asignar clases de acuerdo al estado
  // const getStatusBadgeClass = (status) => {
  //   switch (status) {
  //     case 'Activo':
  //       return 'bg-light-success';
  //     case 'Anulado':
  //       return 'bg-light-danger';
  //     case 'Pendiente':
  //       return 'bg-light-warning';
  //     case 'Completado':
  //       return 'bg-light-success';
  //     default:
  //       return 'bg-light-secondary';
  //   }
  // };

  // // Función para manejar acciones con SweetAlert
  // const handleAction = (action, data) => {
  //   let modalContentText = '';
  //   let modalActionFn = null;

  //   switch (action) {
  //     case 'imprimirCompra':
  //       modalContentText = `¿Confirmas que quieres imprimir el contrato de la venta de ${data.nombre}?`;
  //       modalActionFn = () => console.log('Imprimiendo contrato de venta:', data);
  //       break;
  //     case 'anularCompra':
  //       modalContentText = `¿Confirmas que quieres anular la venta de ${data.nombre}?`;
  //       modalActionFn = () => {
  //         setCompras((prevCompras) =>
  //           prevCompras.map((compra) =>
  //             compra.dni === data.dni ? { ...compra, status: 'Anulado' } : compra
  //           )
  //         );
  //       };
  //       break;
  //     case 'digitalizarDocumentos':
  //       modalContentText = `¿Confirmas que quieres digitalizar los documentos de ${data.nombre}?`;
  //       modalActionFn = () => console.log('Digitalizando documentos para:', data);
  //       break;
  //     case 'anularPago':
  //       modalContentText = `¿Confirmas que quieres anular el pago de ${data.dni}?`;
  //       modalActionFn = () => {
  //         setPagos((prevPagos) =>
  //           prevPagos.map((pago) =>
  //             pago.dni === data.dni ? { ...pago, status: 'Anulado' } : pago
  //           )
  //         );
  //       };
  //       break;
  //     case 'imprimirPago':
  //       modalContentText = `¿Confirmas que quieres imprimir el comprobante del pago de ${data.dni}?`;
  //       modalActionFn = () => console.log('Imprimiendo comprobante de pago:', data);
  //       break;
  //     default:
  //       break;
  //   }

  //   // SweetAlert de confirmación
  //   MySwal.fire({
  //     title: 'Confirmar acción',
  //     text: modalContentText,
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Confirmar',
  //     cancelButtonText: 'Cancelar',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       modalActionFn();
  //       MySwal.fire('Éxito', 'Acción realizada exitosamente', 'success');
  //     }
  //   });
  // };

  return (
    <div className="page-content">
      <section className="row">
        <h1>Crédito de la casa</h1>
        <p className="text-muted">Para comenzar, busca un cliente por DNI.</p>
        {/* <div className="col-lg-6">
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title mb-0">Últimas ventas</h5>
                <div className="col-sm-12 col-md-6">
                  <div className="dataTables_filter">
                    <input
                      type="search"
                      id="searchCompras"
                      className="form-control form-control-sm"
                      placeholder="DNI - CUIL"
                      aria-controls="tableCompras"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover" id="tableCompras">
                  <thead>
                    <tr>
                      <th>DNI</th>
                      <th>Nombre</th>
                      <th>Monto</th>
                      <th>Fecha</th>
                      <th className="text-center">Status</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {compras.map((compra, index) => (
                      <tr key={index}>
                        <td>{compra.dni}</td>
                        <td>{compra.nombre}</td>
                        <td>{compra.monto}</td>
                        <td>{compra.fecha}</td>
                        <td className="text-center">
                          <span className={`badge ${getStatusBadgeClass(compra.status)}`}>
                            {compra.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className="btn btn-reimprimir p-1"
                            onClick={() => handleAction('imprimirCompra', compra)}
                          >
                            <i className="bi bi-printer"></i>
                          </button>
                          <button
                            className="btn btn-anular p-1"
                            onClick={() => handleAction('anularCompra', compra)}
                          >
                            <i className="bi bi-x-circle"></i>
                          </button>
                          <button
                            className="btn btn-upload p-1"
                            onClick={() => handleAction('digitalizarDocumentos', compra)}
                          >
                            <i className="bi bi-upload"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title m-0">Últimos pagos</h5>
                <div className="col-sm-12 col-md-6">
                  <div className="dataTables_filter">
                    <input
                      type="search"
                      id="searchPagos"
                      className="form-control form-control-sm"
                      placeholder="DNI - CUIL"
                      aria-controls="tablePagos"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover" id="tablePagos">
                  <thead>
                    <tr>
                      <th>DNI</th>
                      <th>Fecha</th>
                      <th>Monto</th>
                      <th className="text-center">Status</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagos.map((pago, index) => (
                      <tr key={index}>
                        <td>{pago.dni}</td>
                        <td>{pago.fecha}</td>
                        <td>{pago.monto}</td>
                        <td className="text-center">
                          <span className={`badge ${getStatusBadgeClass(pago.status)}`}>
                            {pago.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className="btn btn-anular p-1"
                            onClick={() => handleAction('anularPago', pago)}
                          >
                            <i className="bi bi-x-circle"></i>
                          </button>
                          <button
                            className="btn btn-reimprimir p-1"
                            onClick={() => handleAction('imprimirPago', pago)}
                          >
                            <i className="bi bi-printer"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </div>
  );
}

export default HomePage;

// src/components/NewClientModal.js
import React from 'react';
import PropTypes from 'prop-types';
import { formatCUIL } from '../../utils/documentFormatter';
const NewClientModal = ({ client, onConfirm, onClose, loading }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
    <div className="modal-backdrop fade show"></div>
    <div className="modal fade text-left w-100 show" tabIndex="-1" style={{ display: 'block' }} aria-modal="true" role="dialog" >
    <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
        <div className="modal-header">
            <h6 className="modal-title">Cliente Nuevo</h6>
            <button type="button" className="btn-close" onClick={onClose}></button>
        </div>
        <div className="modal-body">
            <p>¿Deseas dar de alta al siguiente cliente?</p>
            <div className="d-flex justify-content-around">
                <div className="card profile-card text-center" >
                <div className="card-body pb-0">
                    <div className="avatar avatar-xl mb-3">
                    <img src={client.gender === 'F' ? '/assets/compiled/jpg/female-profile.jpg' : '/assets/compiled/jpg/male-profile.jpg'}  alt="Avatar" className="rounded-circle" />
                    </div>
                    <h6 className="card-title text-primary">{client.name}</h6>
                    <p className="text-muted">CUIL: {formatCUIL(client.cuit)}</p>
                    
                </div>
                <div className='card-footer border-0 m-0 pt-0'>
                    <button className="btn btn-primary m-1" onClick={() => onConfirm(client)} disabled={loading} > {loading ? <span className="spinner-border spinner-border-sm" /> : 'Confirmar'} </button>
                    <button className="btn btn-secondary m-1" onClick={onClose}>Cancelar</button>
                </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
    </>
);
};

NewClientModal.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    cuit: PropTypes.string,
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewClientModal;

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useAuth } from '../../context/AuthContext'; 
import { formatNumber, parseLocaleNumber } from '../../utils/numberFormatter';

const AuthorizationModal = ({ show, onClose, onRequest, initialAmount }) => {
  const [amount, setAmount] = useState('');
  const [displayAmount, setDisplayAmount] = useState('');
  const [observation, setObservation] = useState('');
  const [password, setPassword] = useState('');
  const { config } = useAuth();
  
  useEffect(() => {
    if (show) {
      if (initialAmount) {
        setAmount(initialAmount.toString());
        setDisplayAmount(formatNumber(initialAmount));
      } else {
        setAmount('');
        setDisplayAmount('');
      }
      setObservation('');
      setPassword('');
    }
  }, [show, initialAmount]);

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9,]/g, '');
    
    if (value === '') {
      setAmount('');
      setDisplayAmount('');
      return;
    }

    const numericValue = parseLocaleNumber(value);
    if (!isNaN(numericValue)) {
      setAmount(numericValue.toString());
      setDisplayAmount(formatNumber(numericValue));
    }
  };

  const handleRequest = () => {
    onRequest({ amount, observation, password });
  };

  const shouldShowAdminAuthorizationAlert = () => {
    const amountValue = parseFloat(amount);
    const maxAmount = config.self_authorization?.max_amount;
    
    return (
      (amountValue > maxAmount) || 
      (!config.self_authorization?.self_authorization)
    );
  };

  const shouldShowMerchantPassword = () => {
    const amountValue = parseFloat(amount);
    const minAmount = config.self_authorization?.min_amount_for_authentication;
    const maxAmount = config.self_authorization?.max_amount;

    return (
      config.self_authorization &&
      minAmount !== null &&
      amountValue > minAmount &&
      amountValue <= maxAmount
    );
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Solicitar Autorización</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="authorizationAmount" className="mt-1">
            <Form.Label>Monto Solicitado</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el monto"
              autoComplete="off"
              value={displayAmount}
              onChange={handleAmountChange}
            />
          </Form.Group>
          <Form.Group controlId="authorizationObservation" className="mt-3">
            <Form.Label>Información Adicional</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Por favor, ingresa el motivo por el cual se solicita la autorización"
              value={observation}
              onChange={(e) => setObservation(e.target.value.slice(0, 255))}
              maxLength={255}
            />
            <Form.Text className="text-muted">
              Máximo 255 caracteres
            </Form.Text>
          </Form.Group>
          {shouldShowMerchantPassword() && (
            <Form.Group controlId="password" className="mt-3">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Ingrese su contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
              />
              <Form.Text className="text-muted">
                Se requiere contraseña para montos superiores a ${formatNumber(config.self_authorization?.min_amount_for_authentication)}
              </Form.Text>
            </Form.Group>
          )}
        </Form>

        {shouldShowAdminAuthorizationAlert() && (
          <div className="mt-4 p-3 bg-warning bg-opacity-10 border border-warning rounded">
            <span className="text-secondary">
              Esta solicitud deberá ser autorizada por un administrador.
            </span>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>
        <Button 
          variant="primary" 
          onClick={handleRequest} 
          disabled={!amount || parseFloat(amount) <= 0 || observation.trim().length < 1}
        >
          Solicitar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AuthorizationModal;

import React, { createContext, useState } from 'react';

export const SelectedOfferContext = createContext();

export const SelectedOfferProvider = ({ children }) => {
  const [selectedOffer, setSelectedOffer] = useState(null);

  return (
    <SelectedOfferContext.Provider value={{ selectedOffer, setSelectedOffer }}>
      {children}
    </SelectedOfferContext.Provider>
  );
};
import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCamera, 
  faImages, 
  faFileAlt,
  faIdCard,
  faIdCardAlt,
  faFileInvoiceDollar,
  faUserCircle,
  faSmile,
  faIdBadge
} from '@fortawesome/free-solid-svg-icons';
import api from '../services/api';
import Swal from 'sweetalert2';
import '../styles/documentUpload.css';
import { formatCurrency } from '../utils/numberFormatter';

const DOCUMENTS_MAP = {
  documentFront: { name: 'DNI Frente', icon: faIdCard },
  documentBack: { name: 'DNI Dorso', icon: faIdCardAlt },
  paycheck: { name: 'Recibo de Sueldo', icon: faFileInvoiceDollar },
  selfie: { name: 'Foto Selfie', icon: faUserCircle },
  selfieSmiling: { name: 'Foto Selfie Sonriendo', icon: faSmile },
  selfieDocument: { name: 'Foto Selfie con Documento', icon: faIdBadge },
};

const MobileOnboardingPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [documents, setDocuments] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});
  const [purchaseRequest, setPurchaseRequest] = useState(null);
  const [isDocumentsInitialized, setIsDocumentsInitialized] = useState(false);

  useEffect(() => {
    validateToken();
  }, []);

  useEffect(() => {
    if (isDocumentsInitialized && areRequiredDocsUploaded()) {
      Swal.fire({
        title: '¡Documentos Completados!',
        html: `
          <p>Avísale a <strong>${purchaseRequest?.seller?.full_name}</strong> que has completado la carga de documentos.</p>
          ${Object.entries(documents).some(([_, doc]) => doc.optional && doc.status !== 'uploaded') ? 
            '<small class="text-muted"><i class="bi bi-info-circle me-1"></i>Aún puedes subir los documentos opcionales si lo deseas.</i></small>' : ''}
        `,
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Cerrar',
        cancelButtonText: 'Continuar cargando',
      }).then((result) => {
        if (result.isConfirmed) {
          window.close(); // Intenta cerrar la pestaña
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Acción para continuar cargando documentos opcionales
          // No se necesita hacer nada aquí, el usuario simplemente continuará en la página
        }
      });
    }
  }, [documents, isDocumentsInitialized]);

  const validateToken = async () => {
    try {
      const response = await api.post('/temporary-token/validate', { token });
      
      localStorage.setItem('authToken', response.data.token);
      localStorage.setItem('tokenScope', JSON.stringify(response.data.scope));
      
      setTokenData(response.data);
      
      // Obtenemos la información de la solicitud
      const { customer_id, purchase_request_id } = response.data.scope;
      await fetchPurchaseRequest(customer_id, purchase_request_id);
      
      initializeDocuments();
    } catch (error) {
      console.error('Error validating token:', error);
      setError('El enlace ha expirado o no es válido');
    } finally {
      setLoading(false);
    }
  };

  const fetchPurchaseRequest = async (customerId, purchaseRequestId) => {
    try {
      const response = await api.get(
        `/customers/${customerId}/purchase-requests/${purchaseRequestId}`
      );
      setPurchaseRequest(response.data);
    } catch (error) {
      console.error('Error fetching purchase request:', error);
      setError('No se pudo obtener la información de la solicitud');
    }
  };

  const initializeDocuments = () => {
    const requiredDocs = process.env.REACT_APP_FLOW_NEW_REQUIRED_DOCS.split(',');
    const optionalDocs = process.env.REACT_APP_FLOW_NEW_OPTIONAL_DOCS.split(',');
    const initialDocuments = {};

    requiredDocs.forEach((doc) => {
      initialDocuments[doc] = { 
        status: 'pending', 
        optional: false 
      };
    });

    optionalDocs.forEach((doc) => {
      initialDocuments[doc] = { 
        status: 'pending', 
        optional: true 
      };
    });

    setDocuments(initialDocuments);
    setIsDocumentsInitialized(true);
  };

  const handleFileInput = async (documentType, file) => {
    try {
      setUploadProgress(prev => ({
        ...prev,
        [documentType]: 0
      }));

      const formData = new FormData();
      formData.append('files[]', file);
      formData.append('categories[]', documentType);

      const { customer_id, purchase_request_id } = tokenData.scope;
      
      const response = await api.post(
        `/customers/${customer_id}/purchase-requests/${purchase_request_id}/documents`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(prev => ({
              ...prev,
              [documentType]: percentCompleted
            }));
          }
        }
      );

      // Limpiamos el progreso
      setUploadProgress(prev => {
        const newProgress = { ...prev };
        delete newProgress[documentType];
        return newProgress;
      });

      // Actualizamos el estado del documento
      setDocuments(prev => ({
        ...prev,
        [documentType]: {
          ...prev[documentType],
          status: 'uploaded'
        }
      }));

      Swal.fire({
        title: '¡Éxito!',
        text: 'Documento subido correctamente',
        icon: 'success',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
      });

    } catch (error) {
      console.error('Error uploading document:', error);
      setUploadProgress(prev => {
        const newProgress = { ...prev };
        delete newProgress[documentType];
        return newProgress;
      });
      
      Swal.fire({
        title: 'Error',
        text: 'No se pudo subir el documento',
        icon: 'error'
      });
    }
  };

  const areRequiredDocsUploaded = () => {
    return Object.values(documents).every(doc => 
      doc.optional || doc.status === 'uploaded'
    );
  };

  const renderDocumentCard = (docType, docInfo) => {
    const isUploaded = docInfo.status === 'uploaded';
    const isUploading = uploadProgress[docType] !== undefined;
    const progress = uploadProgress[docType] || 0;

    return (
      <div key={docType} className="mob-document-upload-container">
        {isUploaded ? (
          <div className="mob-document-uploaded">
            <FontAwesomeIcon icon={DOCUMENTS_MAP[docType]?.icon || faFileAlt} className="me-2" />
            {DOCUMENTS_MAP[docType]?.name || docType}
            {docInfo.optional && <span className="badge bg-secondary ms-2">Opcional</span>}
            <i className="bi bi-check-circle-fill text-success ms-2"></i>
          </div>
        ) : (
          <div className="mob-document-pending">
            <div className="mob-document-title">
              <FontAwesomeIcon icon={DOCUMENTS_MAP[docType]?.icon || faFileAlt} className="me-2" />
              {DOCUMENTS_MAP[docType]?.name || docType}
              {docInfo.optional && <span className="badge bg-secondary ms-2">Opcional</span>}
            </div>
            {isUploading ? (
              <div className="upload-progress">
                <div className="progress">
                  <div 
                    className="progress-bar progress-bar-striped progress-bar-animated" 
                    role="progressbar" 
                    style={{ width: `${progress}%` }}
                    aria-valuenow={progress} 
                    aria-valuemin="0" 
                    aria-valuemax="100"
                  >
                    {progress}%
                  </div>
                </div>
                <small className="text-muted mt-1">Subiendo documento...</small>
              </div>
            ) : (
              <div className="mob-document-actions">
                <input
                  type="file"
                  id={`camera-${docType}`}
                  accept="image/*"
                  capture="environment"
                  onChange={(e) => handleFileInput(docType, e.target.files[0])}
                  style={{ display: 'none' }}
                />
                <input
                  type="file"
                  id={`gallery-${docType}`}
                  accept="image/*"
                  onChange={(e) => handleFileInput(docType, e.target.files[0])}
                  style={{ display: 'none' }}
                />
                <button
                  className="btn btn-primary"
                  onClick={() => document.getElementById(`camera-${docType}`).click()}
                >
                  <FontAwesomeIcon icon={faCamera} className="me-2" />
                  Cámara
                </button>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => document.getElementById(`gallery-${docType}`).click()}
                >
                  <FontAwesomeIcon icon={faImages} className="me-2" />
                  Galería
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  if (loading || !isDocumentsInitialized) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-4">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  }

  return (
    <div className="container mt-4">
      {purchaseRequest && (
        <div className="card mb-4">
          <div className="card-body">
            <h5 className="card-title mb-3">Información de la Compra</h5>
            
            <div className="row g-3">
              <div className="col-12">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <small className="text-muted d-block">Cliente</small>
                    <strong>{purchaseRequest.customer.name}</strong>
                  </div>
                  <div className="text-end">
                    <small className="text-muted d-block">DNI</small>
                    <strong>{purchaseRequest.customer.dni}</strong>
                  </div>
                </div>
              </div>
              
              <div className="col-12">
                <hr className="my-2" />
              </div>
              
              <div className="col-6">
                <small className="text-muted d-block">Vendedor</small>
                <strong>{purchaseRequest.seller.full_name}</strong>
              </div>
              
              <div className="col-6 text-end">
                <small className="text-muted d-block">Sucursal</small>
                <strong>{purchaseRequest.branch.name}</strong>
              </div>
              
              <div className="col-12">
                <hr className="my-2" />
              </div>

              <div className="col-6">
                <small className="text-muted d-block">Producto</small>
                <strong>{purchaseRequest.product.name}</strong>
              </div>

              <div className="col-6 text-end">
                <small className="text-muted d-block">Monto</small>
                <strong>{formatCurrency(purchaseRequest.amount, 2)}</strong>
              </div>
            </div>
          </div>
        </div>
      )}

      <h4 className="text-center mb-4">Carga tus Documentos</h4>
      <p className="text-muted text-center mb-4">
        Por favor, sube los documentos requeridos para continuar con el proceso
      </p>

      {/* Documentos Requeridos */}
      <h6 className="mb-3">
        Documentos Requeridos
        {areRequiredDocsUploaded() && (
          <span className="badge bg-success ms-2">
            <i className="bi bi-check-circle me-1"></i>
            Completado
          </span>
        )}
      </h6>
      <div className="documents-grid mb-4">
        {Object.entries(documents)
          .filter(([_, doc]) => !doc.optional)
          .map(([docType, docInfo]) => renderDocumentCard(docType, docInfo))}
      </div>

      {/* Documentos Opcionales */}
      {Object.entries(documents).some(([_, doc]) => doc.optional) && (
        <>
          <h6 className="mb-3">Documentos Opcionales</h6>
          <div className="documents-grid">
            {Object.entries(documents)
              .filter(([_, doc]) => doc.optional)
              .map(([docType, docInfo]) => renderDocumentCard(docType, docInfo))}
          </div>
        </>
      )}
    </div>
  );
};

export default MobileOnboardingPage; 
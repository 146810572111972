import React, { useState, useEffect } from 'react';
import { formatNumber, formatDate, formatCurrency } from '../../utils/numberFormatter';
import InstallmentRow from '../PaymentFlow/InstallmentRow';
import api from '../../services/api';
import { Tab, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faIdCardAlt, faFileInvoiceDollar, faFileAlt, faFile, faQrcode, faShare, faUpload, faCreditCard, faCalendarAlt, faChartLine, faUser, faBuilding, faTags, faUserTie } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import QRCode from 'qrcode';
import DocumentItem from '../DocumentItem/PendingDocumentItem';
import PendingDocumentItem from '../DocumentItem/PendingDocumentItem';
import UploadedDocumentItem from '../DocumentItem/UploadedDocumentItem';
import Modal from 'react-bootstrap/Modal';
import { Document, Page, pdfjs } from 'react-pdf';
// import '../PurchaseFlow/Steps/DocumentUpload/DocumentUpload.css';

const MySwal = withReactContent(Swal);

// Configuración necesaria para react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const InstallmentsSkeleton = ({ installmentsCount }) => (
  <div className="table-responsive">
    <table className="table small">
      <thead>
        <tr>
          <th>Cuota</th>
          <th>Vencimiento</th>
          <th>Monto</th>
          <th>Pagado</th>
          <th>Pendiente</th>
          <th>Interés Punit.</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: installmentsCount }).map((_, index) => (
          <tr key={index}>
            <td><div className="skeleton-cell" style={{ width: '30px' }}></div></td>
            <td><div className="skeleton-cell" style={{ width: '100px' }}></div></td>
            <td><div className="skeleton-cell" style={{ width: '100px' }}></div></td>
            <td><div className="skeleton-cell" style={{ width: '100px' }}></div></td>
            <td><div className="skeleton-cell" style={{ width: '100px' }}></div></td>
            <td><div className="skeleton-cell" style={{ width: '100px' }}></div></td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const SaleDetailModal = ({ saleId, customerId, onClose }) => {
  const [sale, setSale] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingInstallments, setLoadingInstallments] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [installments, setInstallments] = useState([]);
  const [installmentsLoaded, setInstallmentsLoaded] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [uploadingDocuments, setUploadingDocuments] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [numPages, setNumPages] = useState(null);

  const [contracts, setContracts] = useState([]);
  const [contractsLoaded, setContractsLoaded] = useState(false);
  const [loadingContracts, setLoadingContracts] = useState(false);
  
  useEffect(() => {
    fetchSaleDetails();
  }, [saleId]);

  const fetchSaleDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`customers/${customerId}/sales/${saleId}`);
      setSale(response.data);
    } catch (error) {
      console.error('Error fetching sale details:', error);
      setError('No se pudo cargar la información de la venta');
      
      // Mostrar mensaje de error con SweetAlert
      MySwal.fire({
        title: 'Error',
        text: error.response?.data?.message || 'No se pudo cargar la información de la venta',
        icon: 'error',
        confirmButtonText: 'Cerrar',
      }).then(() => {
        // Cerrar el modal cuando el usuario confirme el mensaje de error
        onClose();
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchInstallments = async () => {
    if (!sale.loan?.uid || installmentsLoaded) return;
    
    setLoadingInstallments(true);
    try {
      const response = await api.get(
        `/customers/${sale.customer.id}/loans/${sale.loan.uid}/installments`
      );
      setInstallments(response.data.installments);
      setInstallmentsLoaded(true);
    } catch (error) {
      console.error('Error fetching installments:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se pudieron cargar las cuotas',
      });
    } finally {
      setLoadingInstallments(false);
    }
  };

  const fetchContracts = async () => {
    setLoadingContracts(true);
    try {
      const response = await api.get(`/contracts/${sale.id}`);
      setContracts(response.data);
      setContractsLoaded(true);
    } catch (error) {
      console.error('Error fetching contract:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se pudo obtener el contrato',
      });
    } finally {
      setLoadingContracts(false);
    }
  };

  const mapContractData = (contract) => {
    return {
      id: contract.id,
      type: 'contract',
      title: contract.type,
      status: contract.status,
      signed: contract.signed,
      file: { url: contract.file_url, name: contract.name },
      uploaded_at: contract.created_at
    };
  };

  const getBalanceCardState = () => {
    if (!sale?.loan?.financial) return 'bg-light-primary';
    
    if (sale.loan.financial.total_debt === 0) {
      return 'bg-light-success';
    }
    if (sale.loan.financial.overdue_total > 0) {
      return 'bg-light-danger';
    }
    return 'bg-light-primary';
  };

  const getDueDateCardState = () => {
    if (!sale?.loan?.next_payment?.due_date) return 'bg-light-primary';
    const isOverdue = new Date(sale.loan.next_payment.due_date) < new Date();
    return isOverdue ? 'bg-light-danger' : 'bg-light-primary';
  };

  const getStatusCardState = () => {
    if (!sale) return 'bg-light-primary';
    
    if (sale.status === 'created') {
      return 'bg-light-warning';
    }
    const isOverdue = sale.loan?.next_payment?.due_date 
      ? new Date(sale.loan.next_payment.due_date) < new Date()
      : false;
    if (isOverdue || sale.loan?.status?.stop_debit) {
      return 'bg-light-danger';
    }
    return 'bg-light-success';
  };

  const showQRModal = async (url, title, description, whatsappUrl = null) => {
    try {
      const canvas = document.createElement('canvas');
      await QRCode.toCanvas(canvas, url, {
        width: 200,
        margin: 2,
        color: {
          dark: '#000000',
          light: '#ffffff'
        }
      });

      const qrDataUrl = canvas.toDataURL();

      MySwal.fire({
        title,
        html: `
          <div class="text-center">
            <img src="${qrDataUrl}" class="mb-4" alt="QR Code" />
            <p class="mb-3">${description}</p>
            ${whatsappUrl ? `
              <hr />
              <div class="row g-3">
                <div class="col-6">
                  <p class="text-muted small mb-2">Copia este enlace:</p>
                  <div class="input-group">
                    <input 
                      type="text" 
                      value="${url}" 
                      class="form-control form-control-sm"
                      readonly
                    />
                    <button 
                      class="btn btn-outline-secondary copy-button"
                      type="button"
                      style="border: 1px solid #dce7f1 !important"
                      onclick="copyToClipboard('${url}', this)"
                    >
                      <i class="bi bi-clipboard"></i>
                    </button>
                  </div>
                </div>
                <div class="col-6">
                  <p class="text-muted small mb-2">O comparte con el cliente:</p>
                  <a 
                    href="${whatsappUrl}" 
                    target="_blank" 
                    class="btn btn-success w-100"
                    style="background-color: #25D366; border-color: #25D366"
                  >
                    <i class="bi bi-whatsapp me-2"></i>
                    Enviar por WhatsApp
                  </a>
                </div>
              </div>
            ` : `
              <p class="text-muted small mb-2">O copia este enlace para compartir con el cliente:</p>
              <div class="input-group">
                <input 
                  type="text" 
                  value="${url}" 
                  class="form-control form-control-sm"
                  readonly
                />
                <button 
                  class="btn btn-outline-secondary btn-sm copy-button"
                  type="button"
                  style="border: 1px solid #dce7f1 !important"
                  onclick="copyToClipboard('${url}', this)"
                >
                  <i class="bi bi-clipboard"></i>
                </button>
              </div>
            `}
            <small class="text-muted d-block mt-3">
              El acceso expirará al final del día
            </small>
          </div>
        `,
        showConfirmButton: false,
        showCloseButton: true,
        didOpen: () => {
          // Agregar función copyToClipboard al window
          window.copyToClipboard = (text, button) => {
            navigator.clipboard.writeText(text).then(() => {
              const originalContent = button.innerHTML;
              button.innerHTML = '<i class="bi bi-check"></i>';
              button.disabled = true;
              setTimeout(() => {
                button.innerHTML = originalContent;
                button.disabled = false;
              }, 2000);
            });
          };
        }
      });
    } catch (error) {
      console.error('Error generating QR code:', error);
      MySwal.fire({
        title: 'Error',
        text: 'No se pudo generar el código QR',
        icon: 'error'
      });
    }
  };

  const handleGenerateMobileToken = async () => {
    try {
      const response = await api.post('/temporary-token/generate', {
        type: 'sale',
        sale_id: sale.id,
        customer_id: sale.customer.id,
        expires_in: 30 // 30 minutos
      });

      const { token } = response.data.data;
      const mobileUrl = `${window.location.origin}/m/docs/${token}`;
      
      showQRModal(
        mobileUrl,
        'Acceso móvil para este documento',
        'Escanea el código QR con la cámara de tu celular para subir este documento'
      );
    } catch (error) {
      console.error('Error al generar token móvil:', error);
      MySwal.fire({
        title: 'Error',
        text: 'No se pudo generar el acceso móvil',
        icon: 'error'
      });
    }
  };

  const handleShareWithCustomer = async () => {
    try {
      const response = await api.post('/temporary-token/generate', {
        type: 'customer',
        sale_id: sale.id,
        customer_id: sale.customer.id,
        expires_in: 15 // 15 minutos
      });

      const { token } = response.data.data;
      const customerUrl = `${window.location.origin}/m/docs/${token}`;
      const whatsappText = encodeURIComponent(
        `Hola ${sale.customer.name}, aquí puedes subir el documento pendiente: ${customerUrl}`
      );
      const whatsappUrl = sale.customer.phone ? `https://wa.me/${sale.customer.phone}?text=${whatsappText}` : `https://wa.me/?text=${whatsappText}`;

      showQRModal(
        customerUrl,
        'Solicitar al cliente',
        'El cliente puede escanear este código QR para subir este documento',
        whatsappUrl
      );
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'No se pudo generar el enlace para el cliente',
        icon: 'error'
      });
    }
  };

  const handleFileUpload = async (documentId, file) => {
    if (!file) return;

    try {
      setIsUploading(true);
      
      // Guardamos preview del archivo
      const preview = file.type.startsWith('image/') 
        ? URL.createObjectURL(file) 
        : null;
      
      setUploadingDocuments(prev => ({
        ...prev,
        [documentId]: { file, preview, status: 'uploading' }
      }));

      const formData = new FormData();
      formData.append('files[]', file);
      formData.append('document_ids[]', documentId);

      // Hacemos la petición
      const response = await api.post(`/customers/${sale.customer.id}/sales/${sale.id}/documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      // Actualizamos el estado
      setUploadingDocuments(prev => ({
        ...prev,
        [documentId]: { ...prev[documentId], status: 'uploaded' }
      }));

      // Mostramos mensaje de éxito
      MySwal.fire({
        title: '¡Documento subido!',
        text: 'El documento se ha subido correctamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });

      fetchContracts();

    } catch (error) {
      console.error('Error uploading document:', error);
      setUploadingDocuments(prev => ({
        ...prev,
        [documentId]: { ...prev[documentId], status: 'error' }
      }));
      
      MySwal.fire({
        title: 'Error',
        text: error.response?.data?.message || 'No se pudo subir el documento',
        icon: 'error'
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleReject = (documentId) => {
    setUploadingDocuments(prev => {
      const newState = { ...prev };
      delete newState[documentId];
      return newState;
    });
  };

  const getDocumentIcon = (type) => {
    switch (type) {
      case 'dni_front': return faIdCard;
      case 'dni_back': return faIdCardAlt;
      case 'service': return faFileInvoiceDollar;
      case 'contract': return faFileAlt;
      default: return faFile;
    }
  };

  const handleViewDocument = (doc) => {
    setSelectedDocument(doc);
    setShowDocumentModal(true);
  };

  const handleDownloadDocument = (doc) => {
    window.open(doc.file.url, '_blank');
  };

  const DocumentViewerModal = () => {
    if (!selectedDocument) return null;

    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(
      selectedDocument.file.name.split('.').pop().toLowerCase()
    );

    return (
      <>
        {showDocumentModal && (
          <div className="modal-backdrop document-viewer-modal-backdrop fade show"></div>
        )};
        <Modal
          show={showDocumentModal}
          onHide={() => setShowDocumentModal(false)}
          size="lg"
          centered
          className="document-viewer-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedDocument.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="document-viewer">
              {isImage ? (
                <img
                  src={selectedDocument.file.url}
                  alt={selectedDocument.title}
                  className="img-fluid w-100"
                  style={{ maxHeight: '80vh', objectFit: 'contain' }}
                />
              ) : (
                <Document
                  file={selectedDocument.file.url}
                  onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  className="d-flex justify-content-center"
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      className="mb-3"
                    />
                  ))}
                </Document>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-primary"
              onClick={() => handleDownloadDocument(selectedDocument)}
            >
              <i className="bi bi-download me-2"></i>
              Descargar
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => setShowDocumentModal(false)}
            >
              Cerrar
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const SaleDetailSkeleton = () => (
    <>
      {/* Header Skeleton */}
      <div className="modal-header">
        <div className="d-flex flex-column">
          <div className="skeleton-text w-150"></div>
          <div className="skeleton-text w-100 mt-1"></div>
        </div>
      </div>

      {/* Body Skeleton */}
      <div className="modal-body p-0">
        {/* Cliente y Venta Info Skeleton */}
        <div className="card shadow-sm border-0 rounded-0 mb-2">
          <div className="card-body">
            <div className="row g-4">
              <div className="col-md-6 border-end">
                <div className="skeleton-text w-100 mb-3"></div>
                <div className="ms-4">
                  <div className="skeleton-text w-75 mb-2"></div>
                  <div className="skeleton-text w-50"></div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="skeleton-text w-100 mb-3"></div>
                <div className="ms-4">
                  <div className="skeleton-text w-75 mb-2"></div>
                  <div className="skeleton-text w-50"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Cards Skeleton */}
        <div className="p-4">
          <div className="row g-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="col-md-4">
                <div className="card border-0 bg-light" style={{ height: '120px' }}>
                  <div className="card-header bg-transparent">
                    <div className="skeleton-text w-50"></div>
                  </div>
                  <div className="card-body">
                    <div className="skeleton-text w-75 mx-auto"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Tabs Skeleton */}
        <div className="p-4">
          <div className="skeleton-text w-100 mb-3"></div>
          <div className="skeleton-text w-75 mb-2"></div>
          <div className="skeleton-text w-50"></div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {/* Siempre mostramos el header con el botón de cerrar */}
            <div className="modal-header">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <h5 className="modal-title fw-bold mb-0">
                    {loading ? 'Cargando detalles...' : `Venta #${saleId}`}
                  </h5>
                </div>
              </div>
              <button 
                type="button" 
                className="btn-close" 
                onClick={onClose}
                aria-label="Cerrar"
              ></button>
            </div>

            {/* Contenido del modal */}
            <div className="modal-body p-0">
              {loading ? (
                <SaleDetailSkeleton />
              ) : error ? (
                <div className="modal-body">
                  <div className="alert alert-danger" role="alert">
                    <i className="bi bi-exclamation-triangle me-2"></i>
                    {error}
                  </div>
                </div>
              ) : (
                <>
                  <div className="card shadow-sm border-0 rounded-0 mb-2">
                    <div className="card-body">
                      <div className="row g-4">
                        <div className="col-md-6 border-end">
                          <h6 className="text-muted mb-3">
                            <FontAwesomeIcon icon={faUser} className="me-2" />
                            Información del Cliente
                          </h6>
                          <div className="ms-4">
                            <p className="mb-2">
                              <strong className="d-block">{sale.customer.name}</strong>
                              <small className="text-muted">
                                DNI: {sale.customer.dni}
                              </small>
                            </p>
                            <p className="mb-0">
                              <small className="text-muted">Teléfono: </small>
                              {sale.customer.phone}
                            </p>
                          </div>
                        </div>
                        
                        <div className="col-md-6">
                          <h6 className="text-muted mb-3">
                            <FontAwesomeIcon icon={faTags} className="me-2" />
                            Detalles de la Venta
                          </h6>
                          <div className="ms-4">
                            <div className="d-flex justify-content-between mb-2">
                              <span className="text-muted">Producto:</span>
                              <strong>{sale.product_name}</strong>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <span className="text-muted">Monto:</span>
                              <strong>{formatCurrency(sale.loan.financial.capital)}</strong>
                            </div>
                            <div className="d-flex justify-content-between">
                              <span className="text-muted">Cuotas:</span>
                              <strong>{sale.installments.quantity} x {formatCurrency(sale.installments.amount)}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-4">
                    <div className="row g-4">
                      <div className="col-md-4">
                        <div className={`card border-0 ${getBalanceCardState()} mb-2`} style={{ height: '120px' }}>
                          <div className="card-header m-0 p-1 ps-2 bg-transparent">
                            <small className="text-muted"><FontAwesomeIcon icon={faCreditCard} className="" /> Saldo Pendiente</small>
                          </div>
                          <div className="card-body p-3 d-flex flex-column">
                            <div className="text-center">
                              {sale.loan.financial.total_debt > 0 ? (
                                <>
                                  <h5 className="mb-0">{formatCurrency(sale.loan.financial.total_debt)}</h5>
                                </>
                              ) : (
                                <h5 className="mb-0">Al día</h5>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className={`card border-0 ${getDueDateCardState()} mb-2`} style={{ height: '120px' }}>
                          <div className="card-header m-0 p-1 ps-2 bg-transparent">
                            <small className="text-muted"><FontAwesomeIcon icon={faCalendarAlt} /> Próximo Vencimiento</small>
                          </div>
                          <div className="card-body p-3 d-flex flex-column">
                            <div className="text-center">
                              {sale.loan.next_payment?.due_date ? (
                                <>
                                  <h5 className="mb-0">{formatDate(sale.loan.next_payment.due_date)}</h5>
                                </>
                              ) : (
                                <h5 className="text-danger">Sin vencimiento</h5>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className={`card border-0 ${getStatusCardState()} mb-2`} style={{ height: '120px' }}>
                          <div className="card-header m-0 p-1 ps-2 bg-transparent">
                            <small className="text-muted"><FontAwesomeIcon icon={faChartLine} /> Estado</small>
                          </div>
                          <div className="card-body p-3 d-flex flex-column">
                            <div className="text-center">
                              <h5 className={`mb-0 ${sale.status === 'created' ? '' : (sale.loan.status.stop_debit ? 'text-danger' : 'text-success')}`}>
                                {sale.status === 'created' ? 'Documentación Pendiente' : sale.loan.status.label}
                              </h5>
                              {sale.loan.status.stop_debit && (
                                <small className="text-danger">
                                  <i className="bi bi-exclamation-circle me-1"></i>
                                  {sale.loan.status.stop_debit_detail || 'Débito suspendido'}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="px-4">
                    <Tab.Container activeKey={activeTab} onSelect={(key) => {
                      setActiveTab(key);
                      if (key === 'installments' && !installmentsLoaded) {
                        fetchInstallments();
                      }
                      if (key === 'documents' && !contractsLoaded) {
                        fetchContracts();
                      }
                    }}>
                      <Nav variant="tabs" className="nav-tabs-custom">
                        <Nav.Item>
                          <Nav.Link eventKey="overview" className="px-4">
                            <FontAwesomeIcon icon={faChartLine} className="me-2" />
                            Resumen
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="installments" className="px-4">
                            <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                            Cuotas
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="documents" className="px-4">
                            <FontAwesomeIcon icon={faFile} className="me-2" />
                            Documentos
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Tab.Content className="p-4 bg-white border border-0 rounded-bottom">
                        <Tab.Pane eventKey="overview">
                          <div className="row g-3">
                            <div className="col-md-6">
                              <h6 className="mb-3">Información de Venta</h6>
                              <table className="table mb-1 small table-striped table-borderless">
                                <tbody>
                                  <tr>
                                    <td>Producto</td>
                                    <td>{sale.product_name}</td>
                                  </tr>
                                  <tr>
                                    <td>Fecha</td>
                                    <td>{formatDate(sale.sale_date)}</td>
                                  </tr>
                                  <tr>
                                    <td>Vendedor</td>
                                    <td>{sale.seller.full_name}</td>
                                  </tr>
                                  <tr>
                                    <td>Sucursal</td>
                                    <td>{sale.branch.name}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="col-md-6">
                              <h6 className="mb-3">Detalles Financieros</h6>
                              <table className="table mb-1 small table-striped table-borderless">
                                <tbody>
                                  <tr>
                                    <td>Capital</td>
                                    <td className="text-end">{formatCurrency(sale.loan.financial.capital,2)}</td>
                                  </tr>
                                  <tr>
                                    <td>Monto Total</td>
                                    <td className="text-end">{formatCurrency(sale.loan.financial.total,2)}</td>
                                  </tr>
                                  <tr>
                                    <td>Saldo Total</td>
                                    <td className="text-end">{formatCurrency(sale.loan.financial.outstanding_balance,2)}</td>
                                  </tr>
                                  <tr>
                                    <td>TNA</td>
                                    <td className="text-end">{sale.loan.financial.rate * 100}%</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="installments">
                          {loadingInstallments ? (
                            <InstallmentsSkeleton installmentsCount={sale.installments.quantity} />
                          ) : (
                            <div className="table-responsive">
                              <table className="table mb-1 small table-striped table-borderless">
                                <thead>
                                  <tr>
                                    <th>Cuota</th>
                                    <th>Vencimiento</th>
                                    <th>Monto</th>
                                    <th>Punitorio</th>
                                    <th>Pagado</th>
                                    <th>Pendiente</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {installments.map((installment) => (
                                    <InstallmentRow
                                      key={installment.number}
                                      installment={installment}
                                      loanId={sale.loan.uid}
                                    />
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </Tab.Pane>

                        <Tab.Pane eventKey="documents">
                          <h6 className="mb-3">Documentos de la venta</h6>
                          <div className="document-upload-container">
                            {loadingContracts ? (
                              <div className="document-list">
                                {[1].map((i) => (
                                  <div key={i} className="document-item skeleton">
                                    <div className="d-flex align-items-center gap-2 p-0 border rounded mb-2">
                                      <div className="skeleton-circle"></div>
                                      <div className="skeleton-text w-75"></div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <ul className="document-list">
                                {contracts
                                  .filter(contract => {
                                    const hasSignedContract = contracts.some(c => c.signed);
                                    return hasSignedContract ? contract.signed : true;
                                  })
                                  .map((contract) => (
                                    <UploadedDocumentItem
                                      key={contract.id}
                                      icon={getDocumentIcon(contract.type)}
                                      name={contract.type}
                                      uploadDate={null}
                                      onView={() => handleViewDocument(mapContractData(contract))}
                                      onDownload={() => handleDownloadDocument(mapContractData(contract))}
                                    />
                                  ))}
                              </ul>
                            )}
                            
                            <ul className="document-list">
                              {sale.documents.map((doc) => {
                                if (doc.status !== 'uploaded') {
                                  return (
                                    <PendingDocumentItem
                                      key={doc.id}
                                      icon={getDocumentIcon(doc.type)}
                                      name={doc.title}
                                      status={uploadingDocuments[doc.id]?.status || 'pending'}
                                      onFileUpload={(file) => handleFileUpload(doc.id, file)}
                                      filePreview={uploadingDocuments[doc.id]?.preview}
                                      onReject={() => handleReject(doc.id)}
                                      isUploading={isUploading}
                                    />
                                  );
                                }
                                
                              })}
                            </ul>

                            {sale.documents.some(doc => doc.status === 'pending') && (
                              <div className="mt-4">
                                <div className="row g-3">
                                  <div className="col-md-6">
                                    <button
                                      className="btn btn-outline-success w-100"
                                      onClick={() => handleGenerateMobileToken()}
                                      disabled={isUploading}
                                    >
                                      <FontAwesomeIcon icon={faQrcode} className="me-2" />
                                      Escanear desde Móvil
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button
                                      className="btn btn-outline-info w-100"
                                      onClick={() => handleShareWithCustomer()}
                                      disabled={isUploading}
                                    >
                                      <FontAwesomeIcon icon={faShare} className="me-2" />
                                      Solicitar al Cliente
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </>
              )}
            </div>

            {/* Footer siempre visible */}
            <div className="modal-footer">
              <button 
                type="button" 
                className="btn btn-secondary" 
                onClick={onClose}
                disabled={isUploading} // Solo deshabilitamos si está subiendo documentos
              >
                {loading ? 'Cancelar' : 'Cerrar'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <DocumentViewerModal />
    </>
  );
};

// Estilos para el skeleton
const styles = `
.skeleton-text {
  height: 1rem;
  background: #eee;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

.w-50 { width: 50% !important; }
.w-75 { width: 75% !important; }
.w-100 { width: 100% !important; }
.w-150 { width: 150px !important; }

@keyframes pulse {
  0% {
    opacity: .6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .6;
  }
}

.skeleton-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #eee;
  animation: pulse 1.5s infinite;
}

.document-item.skeleton {
  animation: none;
}
`;

// Inyectar estilos
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default SaleDetailModal;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const MobileErrorPage = ({ error }) => {
  const getErrorMessage = () => {
    switch (error) {
      case 'token_expired':
        return {
          title: 'Sesión expirada',
          message: 'Tu sesión ha caducado. Por favor, solicita un nuevo código QR desde una computadora para continuar.'
        };
      case 'invalid_token':
        return {
          title: 'Acceso inválido',
          message: 'El enlace que estás usando ya no es válido. Necesitas generar un nuevo código QR desde una computadora.'
        };
      default:
        return {
          title: 'Error de acceso',
          message: 'Ha ocurrido un error. Por favor, solicita un nuevo código QR desde una computadora para continuar.'
        };
    }
  };

  const { title, message } = getErrorMessage();

  return (
    <div className="container">
      <div className="text-center mt-5">
        <div className="error-icon mb-4">
          <FontAwesomeIcon 
            icon={faExclamationTriangle} 
            className="text-warning"
            style={{ fontSize: '3rem' }}
          />
        </div>
        <h4 className="mb-3">{title}</h4>
        <p className="text-muted mb-4">{message}</p>
        <div className="qr-hint mt-4">
          <FontAwesomeIcon 
            icon={faQrcode} 
            className="text-primary mb-2"
            style={{ fontSize: '2rem' }}
          />
          <p className="small text-muted">
            Escanea un nuevo código QR para continuar con la digitalización de documentos
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobileErrorPage; 
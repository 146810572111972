// src/components/Profile/ProofDocuments.js
import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import Skeleton from 'react-loading-skeleton';

const ProofDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get('documents');
        setDocuments(response.data);
      } catch (error) {
        console.error('Error al obtener los documentos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  if (loading) {
    return <Skeleton count={5} />;
  }

  return (
    <div>
      <h3>Constancias</h3>
      <ul>
        {documents.map((doc) => (
          <li key={doc.id}>
            <a href={doc.url} target="_blank" rel="noopener noreferrer">
              {doc.nombre}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProofDocuments;

// src/components/PurchaseFlow/Step/ConfirmationStep.js
import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatCurrency, formatNumber } from '../../../utils/numberFormatter';
import { 
  faCheck, 
  faPrint, 
  faDownload, 
  faHome, 
  faFileAlt,
  faFileSignature,
  faInfoCircle,
  faFlagCheckered,
  faClock,
  faCalendar,
  faListCheck,
  faHandHoldingUsd,
  faCalendarCheck,
  faExclamationTriangle,
  faDollarSign,
  faCalculator,
  faPercentage,
  faCheckCircle,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { SelectedOfferContext } from '../../../context/SelectedOfferContext';
import { ClientContext } from '../../../context/ClientContext';
import api from '../../../services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SaleDetailModal from '../../Sales/SaleDetailModal';
import './ConfirmationStep.css';
import QRCode from 'qrcode';

const MySwal = withReactContent(Swal);

const ConfirmationStep = ({ onNext, purchaseRequestId, clientData }) => {
  const { selectedOffer } = useContext(SelectedOfferContext);
  const { setClientData } = useContext(ClientContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contractDownloaded, setContractDownloaded] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);
  const [saleId, setSaleId] = useState(null);
  const [customerMyAccount, setCustomerMyAccount] = useState(null);

  const updateAvailableCredit = async () => {
    try {
      const response = await api.get(`/customers/${clientData.id}/credit-info`);
      
      if (response.data.status === 'COMPLETED') {
        setClientData(prevData => ({
          ...prevData,
          total_limit: response.data.limits.total_limit,
          available_credit: response.data.limits.available_limit,
          status: 'COMPLETED'
        }));
      } else if (response.data.status === 'REJECTED') {
        setClientData(prevData => ({
          ...prevData,
          total_limit: 0,
          available_credit: 0,
          status: 'REJECTED',
          message: response.data.message
        }));
      }
    } catch (error) {
      if (error.response?.status === 403) {
        setClientData(prevData => ({
          ...prevData,
          total_limit: 0,
          available_credit: 0,
          status: 'REJECTED',
          message: error.response.data.message || 'No hay crédito disponible.'
        }));
      }
      console.error('Error al actualizar límite de crédito:', error);
    }
  };

  const handleConfirmation = async () => {
    setIsProcessing(true);
    try {
      const response = await api.post(
        `/customers/${clientData.id}/purchase-requests/${purchaseRequestId}/confirm`
      );
      
      setContracts(response.data.contracts);
      setSaleId(response.data.sale_id);
      setCustomerMyAccount(response.data.customer);

      updateAvailableCredit();
      
      onNext(null, 'success');
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al confirmar la operación.',
      });
      console.error('Error confirming purchase:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDownloadContract = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `contrato-${purchaseRequestId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      setContractDownloaded(true);
    } catch (error) {
      console.error('Error downloading contract:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error al descargar el contrato.',
      });
    }
  };

  const handlePrintContract = (url) => {
    const printWindow = window.open(url, '_blank');
    printWindow.onload = () => {
      printWindow.print();
    };
    setContractDownloaded(true);
  };

  const handleViewDetail = () => {
    setSelectedSale({ id: saleId, customer: { id: clientData.id } });
    setShowDetailModal(true);
  };

  const handleShareMyAccount = () => {
    if (!customerMyAccount) return;

    const clientName = clientData.firstName || '';

    const whatsappText = encodeURIComponent(
      `Hola ${clientName}, aquí puedes ver toda la información de tus préstamos y realizar gestiones: ${customerMyAccount.my_account_url}`
    );
    const whatsappUrl = customerMyAccount.phone ? `https://wa.me/${customerMyAccount.phone}?text=${whatsappText}` : `https://wa.me/?text=${whatsappText}`;

    showQRModal(
      customerMyAccount.my_account_url,
      'Portal Mi Cuenta',
      'El cliente puede escanear este código QR para acceder a "Mi Cuenta", para realizar gestiones y consultas.',
      whatsappUrl
    );
  };

  const showQRModal = async (url, title, description, whatsappUrl = null) => {
    try {
      const canvas = document.createElement('canvas');
      await QRCode.toCanvas(canvas, url, {
        width: 200,
        margin: 2,
        color: {
          dark: '#000000',
          light: '#ffffff'
        }
      });

      const qrDataUrl = canvas.toDataURL();

      MySwal.fire({
        title,
        html: `
          <div class="text-center">
            <img src="${qrDataUrl}" class="mb-4" alt="QR Code" />
            <p class="mb-3">${description}</p>
              <hr />
              <div class="row g-3">
                <div class="col-${whatsappUrl ? '6' : '12'}">
                  <p class="text-muted small mb-2">${whatsappUrl ? 'Copia este enlace:' : 'O copia este enlace para compartir con el cliente:'}</p>
                  <div class="input-group">
                    <input 
                      type="text" 
                      value="${url}" 
                      class="form-control form-control-sm"
                      readonly
                    />
                    <button 
                      class="btn btn-outline-secondary copy-button"
                      type="button"
                      style="border: 1px solid #dce7f1 !important"
                      onclick="copyToClipboard('${url}', this)"
                    >
                      <i class="bi bi-clipboard"></i>
                    </button>
                  </div>
                </div>
                ${whatsappUrl ? `
                  <div class="col-6">
                    <p class="text-muted small mb-2">O comparte con el cliente:</p>
                    <a 
                      href="${whatsappUrl}" 
                      target="_blank" 
                      class="btn btn-success w-100"
                      style="background-color: #25D366; border-color: #25D366"
                    >
                      <i class="bi bi-whatsapp me-2"></i>
                      Enviar por WhatsApp
                    </a>
                  </div>
                ` : ''}
              </div>
          </div>
        `,
        showConfirmButton: false,
        showCloseButton: true,
        didOpen: () => {
          window.copyToClipboard = (text, button) => {
            navigator.clipboard.writeText(text).then(() => {
              const originalContent = button.innerHTML;
              button.innerHTML = '<i class="bi bi-check"></i>';
              button.disabled = true;
              setTimeout(() => {
                button.innerHTML = originalContent;
                button.disabled = false;
              }, 2000);
            });
          };
        }
      });
    } catch (error) {
      console.error('Error generando QR:', error);
    }
  };

  if (contracts.length === 0) {
    return (
      <div className="container my-5">
        <h2 className="text-center mb-2">
          <FontAwesomeIcon icon={faFileSignature} className="me-2" />
          Confirmación de Préstamo
        </h2>
        <p className="text-center mb-4 text-muted">Revisa los detalles antes de confirmar la operación.</p>

        <hr className="my-4" />

        <div className="row justify-content-center m-2">
          <div className="summary-section">
              <h2>
                  <FontAwesomeIcon icon={faCalculator} />
                  Resumen del Crédito
              </h2>

              <div className="summary-item">
                  <div className="summary-label">
                      <FontAwesomeIcon icon={faDollarSign} />
                      Monto Solicitado
                  </div>
                  <div className="summary-value">{formatCurrency(selectedOffer.amount,2)}</div>
              </div>

              <div className="summary-item">
                  <div className="summary-label">
                      <FontAwesomeIcon icon={faCalendar} />
                      Cuotas
                  </div>
                  <div className="summary-value">{selectedOffer.term} cuotas de {formatCurrency(selectedOffer.monthlyPayment,2)}</div>
              </div>
              <div className="summary-item">
                  <div className="summary-label">
                      <FontAwesomeIcon icon={faPercentage} />
                      TNA (Tasa Nominal Anual)
                  </div>
                  <div className="summary-value">
                    {selectedOffer.interestRate*100}%
                  </div>
              </div>

              <div className="summary-item">
                  <div className="summary-label">
                      <FontAwesomeIcon icon={faCalendar} />
                      Primer vencimiento
                  </div>
                  <div className="summary-value">{selectedOffer.firstDueDate}</div>
              </div>
          </div>
        </div>

        <div className="row justify-content-center m-2">
          <div className="terms-section">
              <div className="terms-item">
                  <div className="terms-icon text-primary">
                      <FontAwesomeIcon icon={faHandHoldingUsd} />
                  </div>
                  <div>
                      <h6 className="mb-1 mt-1">Métodos de Cobro</h6>
                      <p className="mb-0"> - Débito automático en tarjeta o cuenta bancaria.</p>
                      <p className="mb-0"> - Débito interno o DEBIN Spot si está habilitado.</p>
                      <p className="mb-0"> - Transferencia bancaria o pago en el domicilio de la financiera.</p>
                  </div>
              </div>

              <div className="terms-item">
                  <div className="terms-icon text-primary">
                      <FontAwesomeIcon icon={faCalendarCheck} />
                  </div>
                  <div>
                  <h6 className="mb-1 mt-1">Pago Anticipado</h6>
                      <p>Puede cancelar anticipadamente sin penalización.</p>
                  </div>
              </div>

              <div className="terms-item">
                  <div className="terms-icon text-primary">
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                  </div>
                  <div>
                  <h6 className="mb-1 mt-1">Penalización por Mora</h6>
                      <p>Interés punitorio del 50% sobre la tasa vigente.</p>
                  </div>
              </div>
          </div>
        </div>

        <div className="text-center mt-4">
          <button 
            className="btn btn-primary btn-lg" 
            onClick={handleConfirmation}
            disabled={isProcessing}
          >
            {isProcessing ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Procesando...
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                Confirmar y Generar Préstamo
              </>
  
            )}
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
    <div className="">
      {/* Header con ícono de éxito */}
      <div className="text-center my-4 border-bottom pb-4">
        <div className="stepper-success-icon">
          <FontAwesomeIcon icon={faCheck} />
        </div>
        <h2 className="text-success">¡Préstamo generado con éxito!</h2>
        <p>El préstamo ha sido aprobado y generado correctamente.</p>
        <h6>
          <span className="badge bg-warning rounded-pill d-inline-flex align-items-center gap-2 p-3" style={{color: '#212529'}}>
            <FontAwesomeIcon icon={faFileSignature} />
            Estado: Pendiente de Firma
          </span>
        </h6>
        <p className="text-muted mt-2">
          El contrato debe imprimirse y firmarse antes de entregar el producto.
        </p>
      </div>

        {/* Botones de acción principales */}
        <div className="action-buttons">
          <div className="row g-2 justify-content-center">
            <div className="col-md-4">
              <button 
                className="btn btn-primary w-100"
                onClick={() => {
                  handlePrintContract(contracts[0].url);
                  setContractDownloaded(true);
                }}
              >
                <FontAwesomeIcon icon={faPrint} className="me-2" />
                Imprimir Contrato
              </button>
            </div>
            <div className="col-md-4">
              <button 
                className="btn btn-outline-primary w-100"
                onClick={() => {
                  // handleDownloadContract(contracts[0].url);
                  handlePrintContract(contracts[0].url); // TODO: Implementar descarga del contrato
                  setContractDownloaded(true);
                }}
              >
                <FontAwesomeIcon icon={faDownload} className="me-2" />
                Descargar Contrato
              </button>
            </div>
          </div>
        </div>

        {/* Instrucciones */}
        <div className="row justify-content-center">
          <div className="steps-guide col-md-8">
            <h6 className="steps-guide-title">
              <FontAwesomeIcon icon={faListCheck} className="me-2" />
              Próximos pasos:
            </h6>
            
            <div className="steps-list">
              <div className="step-item">
                <div className="step-marker">1</div>
                <div className="step-content">
                  Imprime el contrato y entrégalo al cliente para su firma.
                </div>
              </div>
              
              <div className="step-item">
                <div className="step-marker">2</div>
                <div className="step-content">
                  Una vez firmado, entrega el producto.
                </div>
              </div>

              {customerMyAccount && (
                <div className="step-item">
                  <div className="step-marker">3</div>
                  <div className="step-content">
                    Comparte con el cliente el acceso a su portal <button className="btn btn-link text-primary p-0" onClick={handleShareMyAccount}>Mi Cuenta</button>
                  </div>
                </div>
              )}
            </div>

            <div className="steps-divider">
              <FontAwesomeIcon icon={faFlagCheckered} className="me-2" />
              FIN DEL PROCESO CON EL CLIENTE
            </div>

            <div className="steps-list">
              <div className="step-item">
                <div className="step-marker">
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <div className="step-content">
                  Más tarde, sube el contrato firmado al sistema.
                </div>
              </div>
            </div>
          </div>
        </div>

      {/* Recordatorio */}
      <div className="alert alert-warning d-flex align-items-center">
        <FontAwesomeIcon icon={faInfoCircle} className="fs-4 me-2" />
        <div>
          <strong>Importante:</strong> el contrato firmado debe ser subido desde la sección "Digitalizar Documentos".
        </div>
      </div>

      {contractDownloaded && (
        <div className="navigation-buttons border-top">
          <button 
            className="btn btn-light-secondary"
            onClick={() => window.location.href = '/'}
          >
            <FontAwesomeIcon icon={faHome} className="me-2" />
            Volver al Inicio
          </button>
          <button 
            className="btn btn-primary"
            onClick={handleViewDetail}
          >
            <FontAwesomeIcon icon={faFileAlt} className="me-2" />
            Ver Detalle de Venta
          </button>
        </div>
      )}
    </div>
    {showDetailModal && (
      <SaleDetailModal
        saleId={selectedSale.id}
        customerId={selectedSale.customer.id}
        onClose={() => {
          setShowDetailModal(false);
          setSelectedSale(null);
        }}
      />
    )}
    </>
  );
};

export default ConfirmationStep;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import { formatNumber } from '../utils/numberFormatter';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import QRCode from 'qrcode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faIdCard, faIdCardAlt, faFileInvoiceDollar, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import DocumentItem from '../components/DocumentItem/PendingDocumentItem';
import '../styles/documentUpload.css';

const MySwal = withReactContent(Swal);

const DocumentsPage = () => {
  const navigate = useNavigate();
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSale, setSelectedSale] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [mobileToken, setMobileToken] = useState(null);
  const [branchToken, setBranchToken] = useState(null);
  const [generatingToken, setGeneratingToken] = useState(false);
  const [filters, setFilters] = useState({
    search: '',
    status: 'created'
  });
  const [uploadingDocuments, setUploadingDocuments] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    fetchSales();
  }, [filters]);

  const fetchSales = async () => {
    try {
      setLoading(true);
      const response = await api.get('/sales', { 
        params: { 
          ...filters
        } 
      });

      setSales(response.data.sales);
    } catch (error) {
      console.error('Error al obtener ventas:', error);
      MySwal.fire({
        title: 'Error',
        text: 'No se pudieron cargar las ventas',
        icon: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const showQRModal = (url, title, description, whatsappUrl = null) => {
    MySwal.fire({
      title,
      html: `
        <div class="text-center">
          <canvas id="qr-canvas" class="mb-4"></canvas>
          <p class="mb-3">${description}</p>
          ${whatsappUrl ? `
            <hr />
            <div class="row g-3">
              <div class="col-6">
                <p class="text-muted small mb-2">Copia este enlace:</p>
                <div class="input-group">
                  <input 
                    type="text" 
                    value="${url}" 
                    class="form-control form-control-sm"
                    readonly
                  />
                  <button 
                    class="btn btn-outline-secondary copy-button"
                    type="button"
                    style="border: 1px solid #dce7f1 !important"
                    onclick="copyToClipboard('${url}', this)"
                  >
                    <i class="bi bi-clipboard"></i>
                  </button>
                </div>
              </div>
              <div class="col-6">
                <p class="text-muted small mb-2">O comparte con el cliente:</p>
                <a 
                  href="${whatsappUrl}" 
                  target="_blank" 
                  class="btn btn-success w-100"
                  style="background-color: #25D366; border-color: #25D366"
                >
                  <i class="bi bi-whatsapp me-2"></i>
                  Enviar por WhatsApp
                </a>
              </div>
            </div>
          ` : `
            <p class="text-muted small mb-2">O copia este enlace:</p>
            <div class="input-group">
              <input 
                type="text" 
                value="${url}" 
                class="form-control form-control-sm"
                readonly
              />
              <button 
                class="btn btn-outline-secondary btn-sm copy-button"
                type="button"
                style="border: 1px solid #dce7f1 !important"
                onclick="copyToClipboard('${url}', this)"
              >
                <i class="bi bi-clipboard"></i>
              </button>
            </div>
          `}
          <small class="text-muted d-block mt-3">
            El acceso expirará al final del día
          </small>
        </div>
      `,
      showCloseButton: true,
      showConfirmButton: false,
      width: 600,
      didRender: () => {
        const canvas = document.getElementById('qr-canvas');
        QRCode.toCanvas(canvas, url, {
          width: 256,
          margin: 1,
          color: {
            dark: '#000000',
            light: '#ffffff'
          }
        });

        // Add copy function to window scope
        window.copyToClipboard = function(text, button) {
          navigator.clipboard.writeText(text);
          button.innerHTML = '<i class="bi bi-check-lg"></i>';
          // button.classList.add('btn-success');
          setTimeout(() => {
            button.innerHTML = '<i class="bi bi-clipboard"></i>';
            // button.classList.remove('btn-success');
          }, 2000);
        };
      }
    });
  };

  const handleShowBranchQR = async () => {
    try {
      if (mobileToken) {
        const mobileUrl = `${window.location.origin}/m/docs/${mobileToken}`;
        showQRModal(
          mobileUrl,
          'Acceso móvil a documentación',
          'Escanea el código QR con la cámara de tu celular para ver todas las ventas pendientes'
        );
        return;
      }

      setGeneratingToken(true);
      const response = await api.post('/temporary-token/generate', {
        type: 'branch',
        expires_in: 120 // 2 horas
      });
      
      const token = response.data.data.token;
      setMobileToken(token);
      
      const mobileUrl = `${window.location.origin}/m/docs/${token}`;
      showQRModal(
        mobileUrl,
        'Acceso móvil a documentación',
        'Escanea el código QR con la cámara de tu celular para ver todas las ventas pendientes'
      );
    } catch (error) {
      console.error('Error al generar token de sucursal:', error);
      MySwal.fire({
        title: 'Error',
        text: 'No se pudo generar el acceso móvil',
        icon: 'error'
      });
    } finally {
      setGeneratingToken(false);
    }
  };

  const handleGenerateMobileToken = async (saleId, customerId) => {
    try {
      const response = await api.post('/temporary-token/generate', {
        type: 'sale',
        sale_id: saleId,
        customer_id: customerId,
        expires_in: 30 // 30 minutos
      });

      const { token } = response.data.data;
      const mobileUrl = `${window.location.origin}/m/docs/${token}`;
      
      showQRModal(
        mobileUrl,
        'Acceso móvil para esta venta',
        'Escanea el código QR con la cámara de tu celular para subir los documentos de esta venta'
      );
    } catch (error) {
      console.error('Error al generar token móvil:', error);
      MySwal.fire({
        title: 'Error',
        text: 'No se pudo generar el acceso móvil',
        icon: 'error'
      });
    }
  };

  const handleShareWithCustomer = async (sale) => {
    try {
      const response = await api.post('/temporary-token/generate', {
        type: 'customer',
        sale_id: sale.id,
        customer_id: sale.customer.id,
        expires_in: 15 // 15 minutos
      });

      const { token } = response.data.data;
      const customerUrl = `${window.location.origin}/m/docs/${token}`;
      const whatsappText = encodeURIComponent(
        `Hola ${sale.customer.name}, aquí puedes subir los documentos pendientes de tu compra: ${customerUrl}`
      );
      const whatsappUrl = `https://wa.me/${sale.customer.phone}?text=${whatsappText}`;

      showQRModal(
        customerUrl,
        'Solicitar al cliente',
        'El cliente puede escanear este código QR para subir sus documentos',
        whatsappUrl
      );
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'No se pudo generar el enlace para el cliente',
        icon: 'error'
      });
    }
  };

  const handleFileUpload = (saleId, docType, file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadingDocuments(prev => ({
        ...prev,
        [docType]: {
          file,
          preview: reader.result,
          status: 'uploaded'
        }
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleReject = (docType) => {
    setUploadingDocuments(prev => ({
      ...prev,
      [docType]: null
    }));
  };

  const handleUploadDocuments = async (saleId) => {
    try {
      setIsUploading(true);
      setUploadProgress(0);

      const formData = new FormData();
      const files = [];
      const documentIds = [];

      Object.entries(uploadingDocuments).forEach(([docType, docData]) => {
        if (docData?.file) {
          const pendingDoc = selectedSale.pending_documents.find(doc => doc.type === docType);
          if (pendingDoc) {
            files.push(docData.file);
            documentIds.push(pendingDoc.id);
          }
        }
      });

      files.forEach((file, index) => {
        formData.append('files[]', file);
      });
      
      documentIds.forEach(id => {
        formData.append('document_ids[]', id);
      });

      const response = await api.post(
        `/customers/${selectedSale.customer.id}/sales/${saleId}/documents`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (event) => {
            const progressValue = Math.round((event.loaded / event.total) * 100);
            setUploadProgress(progressValue);
          },
        }
      );

      // Actualizamos el estado de la venta en el listado
      setSales(prevSales => 
        prevSales.map(sale => {
          if (sale.id === saleId) {
            return {
              ...sale,
              status: response.data.sale_status,
              pending_documents: sale.pending_documents.filter(
                doc => !documentIds.includes(doc.id)
              )
            };
          }
          return sale;
        })
      );

      MySwal.fire({
        icon: 'success',
        title: 'Documentos subidos',
        text: 'Los documentos se han subido correctamente'
      });

      setShowUploadModal(false);
      setUploadingDocuments({});

    } catch (error) {
      console.error('Error al subir documentos:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un problema al subir los documentos'
      });
    } finally {
      setIsUploading(false);
    }
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'created':
        return 'bg-warning';
      case 'pending':
        return 'bg-info';
      case 'verified':
        return 'bg-success';
      default:
        return 'bg-secondary';
    }
  };

  const formatStatus = (status) => {
    switch (status) {
      case 'created':
        return 'Pendiente';
      case 'pending':
        return 'Por Verificar';
      case 'verified':
        return 'Verificado';
      default:
        return status;
    }
  };

  const renderPendingDocuments = (documents) => {
    if (!documents || documents.length === 0) {
      return (
        <span className="text-muted">
          <i className="bi bi-check-circle text-success me-1"></i>
          Sin documentos pendientes
        </span>
      );
    }

    return (
      <ul className="list-unstyled mb-0">
        {documents.map((doc, index) => (
          <li key={index} className="mb-1">
            <i className="bi bi-exclamation-circle text-warning me-1"></i>
            {doc.title}
          </li>
        ))}
      </ul>
    );
  };

  if (loading) {
    return (
      <div className="text-center p-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="page-content">
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3>Digitalización de Documentos</h3>
            <p className="text-subtitle text-muted">Sube los documentos requeridos para completar las operaciones</p>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <button 
              className="btn btn-outline-primary float-end"
              onClick={handleShowBranchQR}
              disabled={generatingToken}
            >
              {generatingToken ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Generando acceso...
                </>
              ) : (
                <>
                  <i className="bi bi-qr-code me-2"></i>
                  Cargar desde Móvil
                </>
              )}
            </button>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="card">
          <div className="card-header">
            <div className="row g-3">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por cliente o venta..."
                  name="search"
                  value={filters.search}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-6">
                <select
                  className="form-select"
                  name="status"
                  value={filters.status}
                  onChange={handleFilterChange}
                >
                  <option value="">Todos los estados</option>
                  <option value="created">Pendiente de documentación</option>
                  <option value="pending">Pendiente de verificar</option>
                  <option value="verified">Verificado</option>
                </select>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Venta</th>
                    <th>Cliente</th>
                    <th>Fecha</th>
                    <th>Monto Total</th>
                    <th>Estado</th>
                    <th>Documentos Pendientes</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {sales.map((sale) => (
                    <tr key={sale.id}>
                      <td>#{sale.id}</td>
                      <td>
                        <a 
                          href="#" 
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/clientes/${sale.customer.dni}`);
                          }}
                          className="text-decoration-none"
                        >
                          {sale.customer.name || `Cliente ${sale.customer.dni}`}
                        </a>
                        <br />
                        <small className="text-muted">
                          DNI: {sale.customer.dni}
                        </small>
                      </td>
                      <td>{new Date(sale.sale_date).toLocaleDateString()}</td>
                      <td>${formatNumber(sale.amount)}</td>
                      <td>
                        <span className={`badge ${getStatusBadgeClass(sale.status)}`}>
                          {formatStatus(sale.status)}
                        </span>
                      </td>
                      <td>
                        {renderPendingDocuments(sale.pending_documents)}
                      </td>
                      <td>
                        <div className="btn-group">
                          <button 
                            className="btn btn-outline-primary btn-sm"
                            onClick={() => {
                              setSelectedSale(sale);
                              setShowUploadModal(true);
                            }}
                            disabled={!sale.pending_documents?.length}
                            title={sale.pending_documents?.length ? "Subir desde computadora" : "No hay documentos pendientes"}
                          >
                            <i className="bi bi-upload me-1"></i>
                            Subir
                          </button>
                          <button
                            className="btn btn-outline-success btn-sm"
                            onClick={() => handleGenerateMobileToken(sale.id, sale.customer.id)}
                            disabled={!sale.pending_documents?.length}
                            title={sale.pending_documents?.length ? "Generar acceso móvil" : "No hay documentos pendientes"}
                          >
                            <i className="bi bi-phone me-1"></i>
                            Escanear
                          </button>
                          <button
                            className="btn btn-outline-info btn-sm"
                            onClick={() => handleShareWithCustomer(sale)}
                            disabled={!sale.pending_documents?.length}
                            title={sale.pending_documents?.length ? "Solicitar al cliente" : "No hay documentos pendientes"}
                          >
                            <i className="bi bi-share me-1"></i>
                            Solicitar
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      {/* Modal de carga de documentos */}
      {showUploadModal && selectedSale && (
        <>  
        <div className="modal-backdrop fade show"></div>
        <div className="modal fade show document-upload-modal" style={{ display: 'block' }} tabIndex="-1">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Cargar Documentos - Venta {selectedSale.id}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setShowUploadModal(false);
                    setUploadingDocuments({});
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="document-upload-container">
                  <p className="text-center text-muted mb-4">
                    Arrastra y suelta los documentos en las áreas correspondientes o haz clic para seleccionar los archivos.
                  </p>
                  <ul className="document-list">
                    {selectedSale.pending_documents?.map((doc) => (
                      <DocumentItem
                        key={doc.type}
                        icon={
                          doc.type === 'dni_front' ? faIdCard :
                          doc.type === 'dni_back' ? faIdCardAlt :
                          doc.type === 'service' ? faFileInvoiceDollar :
                          doc.type === 'contract' ? faFileAlt :
                          faFile
                        }
                        name={doc.title}
                        status={uploadingDocuments[doc.type]?.status || 'pending'}
                        onFileUpload={(file) => handleFileUpload(selectedSale.id, doc.type, file)}
                        filePreview={uploadingDocuments[doc.type]?.preview}
                        onReject={() => handleReject(doc.type)}
                        isUploading={isUploading}
                      />
                    ))}
                  </ul>

                  {isUploading && (
                    <div className="mt-4">
                      <div className="progress">
                        <div 
                          className="progress-bar progress-bar-striped progress-bar-animated" 
                          role="progressbar" 
                          style={{ width: `${uploadProgress}%` }} 
                          aria-valuenow={uploadProgress} 
                          aria-valuemin="0" 
                          aria-valuemax="100"
                        >
                          {uploadProgress}%
                        </div>
                      </div>
                      <p className="text-center mt-2">Subiendo documentos...</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setShowUploadModal(false);
                    setUploadingDocuments({});
                  }}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleUploadDocuments(selectedSale.id)}
                  disabled={
                    isUploading || 
                    !Object.keys(uploadingDocuments).length || 
                    !Object.values(uploadingDocuments).every(doc => doc?.status === 'uploaded')
                  }
                >
                  {isUploading ? 'Subiendo...' : 'Subir Documentos'}
                </button>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
    </div>
  );
};

export default DocumentsPage; 
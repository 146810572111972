import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { faCheckCircle, faExclamationTriangle, faTimes, faPlusCircle, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';

const relationships = ['Conyuge', 'Familiar', 'Amigo', 'Compañero de trabajo', 'Vecino', 'Otro'];

const Referrals = forwardRef((props, ref) => {
  const {
    formData,
    handleChange,
    activeSection,
    sectionName,
    setSectionValidity,
    sectionValidity,
    handleSectionClick,
  } = props;

  const [errors, setErrors] = useState({});
  const [touchedFields, setTouchedFields] = useState({});

  const validateField = (name, value, index) => {
    let error = '';

    const field = name.split('.').pop();

    if (['phone', 'firstName', 'lastName'].includes(field)) {
      const isDuplicate = checkForDuplicates(name, value, index);
      if (isDuplicate) {
        error = `El ${name === 'phone' ? 'número de teléfono' : 'referido'} ya existe.`;
      }
    }
  
    switch (field) {
      case 'phone':
        if (!value) {
          error = 'El número de teléfono es requerido';
        } else if (value.length < 10 || value.length > 13) {
          error = 'El número de teléfono debe tener entre 10 y 13 caracteres';
        }
        break;
      case 'firstName':
      case 'lastName':
        if (!value) {
          error = `${field === 'firstName' ? 'El nombre' : 'El apellido'} es requerido`;
        }
        break;
      case 'relationship':
        if (!value) {
          error = 'El vínculo es requerido';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [`referrals.${index}.${field}`]: error,
    }));

    return !error;
  };

  const checkForDuplicates = (field, value, index) => {
    const normalizedValue = value.trim().toLowerCase();
  
    return formData.referrals.some((referral, idx) => {
      if (idx === index) return false; // Ignorar el referido actual
      const isPhoneDuplicate = field === 'phone' && referral.phone.trim().toLowerCase() === normalizedValue;
      const isNameDuplicate =
        (field === 'firstName' || field === 'lastName') &&
        referral.firstName.trim().toLowerCase() === formData.referrals[index].firstName.trim().toLowerCase() &&
        referral.lastName.trim().toLowerCase() === formData.referrals[index].lastName.trim().toLowerCase();
  
      const isCombinedDuplicate =
        referral.phone.trim().toLowerCase() === formData.referrals[index].phone.trim().toLowerCase() &&
        referral.firstName.trim().toLowerCase() === formData.referrals[index].firstName.trim().toLowerCase() &&
        referral.lastName.trim().toLowerCase() === formData.referrals[index].lastName.trim().toLowerCase();
  
      return isPhoneDuplicate || isNameDuplicate || isCombinedDuplicate;
    });
  };  

  const validateSection = () => {
    const newErrors = {};
  
    formData.referrals.forEach((referral, index) => {
      ['firstName', 'lastName', 'phone', 'relationship'].forEach((field) => {
        const value = referral[field];
        const isValidField = validateField(field, value, index);
        if (!isValidField) {
          newErrors[`referrals.${index}.${field}`] = errors[`referrals.${index}.${field}`];
        }
      });
    });
  
    if (formData.referrals.length < 2) {
        newErrors['general'] = 'Se requieren al menos dos referidos.';
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [`general`]: newErrors['general'] ?? '',
    }));

    const isValid = Object.keys(newErrors).length === 0;
    setSectionValidity((prev) => ({ ...prev, [sectionName]: isValid }));

    return isValid;
  };

  const handlePhoneChange = (e, index) => {
    const numericValue = e.target.value.replace(/\D/g, '');
    if (numericValue.length <= 13) {
      handleDynamicChange(e, index, 'phone', numericValue);
    }
  };

  const handleDynamicChange = (e, index, field, valueOverride = null) => {
    const fieldName = `referrals.${index}.${field}`;
    setTouchedFields(prev => ({
      ...prev,
      [fieldName]: true
    }));

    handleChange(
      { target: { name: field, value: valueOverride ?? e.target.value } },
      { isNested: true, arrayIndex: index, nestedKey: 'referrals' }
    );
  };

  const addReferral = () => {
    const newReferral = { firstName: '', lastName: '', phone: '', relationship: '' };
    const newIndex = formData.referrals.length;

    const newTouchedFields = { ...touchedFields };
    ['firstName', 'lastName', 'phone', 'relationship'].forEach(field => {
      newTouchedFields[`referrals.${newIndex}.${field}`] = false;
    });
    setTouchedFields(newTouchedFields);

    handleChange({
      target: { name: 'referrals', value: [...formData.referrals, newReferral] },
    });
  };

  const removeReferral = (index) => {
    const updatedReferral = formData.referrals.filter((_, i) => i !== index);
    
    const newTouchedFields = { ...touchedFields };
    ['firstName', 'lastName', 'phone', 'relationship'].forEach(field => {
      delete newTouchedFields[`referrals.${index}.${field}`];
    });
    setTouchedFields(newTouchedFields);

    handleChange(
      { target: { name: 'referrals', value: updatedReferral } },
      { isNested: false }
    );
  };

  const showExplanationDialog = () => {
    Swal.fire({
      title: '¿Para qué necesitamos referidos?',
      html: `
        <div class="text-start">
          <p class="mb-3">
            Los referidos son contactos de confianza que pueden dar fe de tu identidad. 
            No son co-deudores ni tendrán ninguna responsabilidad sobre la compra. 
            Solo serán contactados para verificar tu identidad en caso de ser necesario.
          </p>
        </div>
      `,
      confirmButtonText: 'Entendido',
      customClass: {
        container: 'my-swal'
      }
    });
  };

  const getRequiredFields = (referralsCount) => {
    const fields = [];
    for (let i = 0; i < referralsCount; i++) {
      fields.push(
        `referrals.${i}.firstName`,
        `referrals.${i}.lastName`,
        `referrals.${i}.phone`,
        `referrals.${i}.relationship`
      );
    }
    return fields;
  };

  useEffect(() => {
    const requiredFields = getRequiredFields(formData.referrals.length);
    const allFieldsTouched = requiredFields.every(field => touchedFields[field]);
    
    if (allFieldsTouched) {
      validateSection();
    }
  }, [touchedFields]);

  useImperativeHandle(ref, () => ({
    validateSection,
  }));

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading${sectionName}`}>
        <button
          className={`accordion-button ${activeSection !== sectionName ? 'collapsed' : ''}`}
          type="button"
          onClick={() => handleSectionClick(sectionName)}
        >
          <FontAwesomeIcon icon={faUserFriends} className="me-2" />
          Referidos{' '}
          {sectionValidity[sectionName] === true && (
            <FontAwesomeIcon icon={faCheckCircle} className="text-success ms-2" />
          )}
          {sectionValidity[sectionName] === false && (
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger ms-2" />
          )}
        </button>
      </h2>
      <div
        className={`accordion-collapse collapse ${activeSection === sectionName ? 'show' : ''}`}
        aria-labelledby={`heading${sectionName}`}
      >
        <div className="accordion-body">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-link text-primary p-0 text-decoration-none"
                onClick={showExplanationDialog}
              >
                <i className="bi bi-info-circle me-1"></i>
                ¿Para qué necesitamos referidos?
              </button>
              <div className="text-danger ms-3 mb-0" style={{ minHeight: '24px' }}>
                {errors.general && errors.general}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-link text-primary text-decoration-none"
              onClick={addReferral}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="me-1" />
              Agregar Referido
            </button>
          </div>
          {formData.referrals.map((referido, index) => (
            <div className="row mb-4" key={index}>
              <div className="d-flex justify-content-between align-items-center">
                <h5>Referido {index + 1}</h5>
                <button
                  type="button"
                  className="btn btn-link text-danger"
                  onClick={() => removeReferral(index)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Nombre<sup>*</sup></label>
                <input
                  type="text"
                  className={`form-control ${errors[`referrals.${index}.firstName`] ? 'is-invalid' : ''}`}
                  value={referido.firstName || ''}
                  onChange={(e) => handleDynamicChange(e, index, 'firstName')}
                  onBlur={(e) => validateField(`firstName`, e.target.value, index)}
                  required
                  autoComplete="off"
                />
                {errors[`referrals.${index}.firstName`] && (
                  <div className="invalid-feedback">{errors[`referrals.${index}.firstName`]}</div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Apellido<sup>*</sup></label>
                <input
                  type="text"
                  className={`form-control ${errors[`referrals.${index}.lastName`] ? 'is-invalid' : ''}`}
                  value={referido.lastName || ''}
                  onChange={(e) => handleDynamicChange(e, index, 'lastName')}
                  onBlur={(e) => validateField(`lastName`, e.target.value, index)}
                  required
                  autoComplete="off"
                />
                {errors[`referrals.${index}.lastName`] && (
                  <div className="invalid-feedback">{errors[`referrals.${index}.lastName`]}</div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Teléfono<sup>*</sup></label>
                <input
                  type="tel"
                  className={`form-control ${errors[`referrals.${index}.phone`] ? 'is-invalid' : ''}`}
                  value={referido.phone || ''}
                  onChange={(e) => handlePhoneChange(e, index)}
                  onBlur={(e) => validateField(`phone`, e.target.value, index)}
                  required
                  autoComplete="off"
                />
                {errors[`referrals.${index}.phone`] && (
                  <div className="invalid-feedback">{errors[`referrals.${index}.phone`]}</div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Vínculo<sup>*</sup></label>
                <select
                  className={`form-control ${errors[`referrals.${index}.relationship`] ? 'is-invalid' : ''}`}
                  value={referido.relationship || ''}
                  onChange={(e) => handleDynamicChange(e, index, 'relationship')}
                  onBlur={(e) => validateField(`relationship`, e.target.value, index)}
                  required
                  autoComplete="off"
                >
                  <option value="">Seleccione un vínculo</option>
                  {relationships.map((relationship, i) => (
                    <option key={i} value={relationship}>
                      {relationship}
                    </option>
                  ))}
                </select>
                {errors[`referrals.${index}.relationship`] && (
                  <div className="invalid-feedback">{errors[`referrals.${index}.relationship`]}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Referrals;

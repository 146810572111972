// src/components/HomonymModal.js
import React from 'react';
import { formatCUIL } from '../../utils/documentFormatter';

const HomonymModal = ({ homonyms, onSelectClient, onClose }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
  return (
    <>
    <div className="modal-backdrop fade show"></div>
    <div className="modal fade text-left w-100 show" tabIndex="-1" style={{ display: 'block' }} aria-modal="true" role="dialog" >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">Selecciona el cliente correcto</h6>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <p>Se encontraron varias personas con el mismo DNI. Selecciona el cliente correcto:</p>
            <div className="d-flex justify-content-around">
              {homonyms.map((client, index) => (
                <div key={index} className="card profile-card text-center homonym-card ms-1 me-1" onClick={() => onSelectClient(client)} style={{ cursor: 'pointer', width: '18rem' }} >
                  <div className="card-body pb-0">
                    <div className="avatar avatar-xl mb-3">
                      <img src={client.gender === 'F' ? '/assets/compiled/jpg/female-profile.jpg' : '/assets/compiled/jpg/male-profile.jpg'}  alt="Avatar" className="rounded-circle" />
                    </div>
                    <h6 className="card-title text-primary">{client.name}</h6>
                    <p className="text-muted">CUIL: {formatCUIL(client.cuit)}</p>
                  </div>
                  <div className='card-footer border-0 m-0 pt-0'>
                    {client.exists ? <span className="badge bg-success">Cliente Existente</span> : <span className="badge bg-primary">Nuevo Cliente</span>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default HomonymModal;

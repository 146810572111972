export const formatNumber = (number, minDecimalPlaces = 0) => {
  if (number === null || number === undefined) return '0';

  return new Intl.NumberFormat('es-AR', {
    minimumFractionDigits: minDecimalPlaces,
    maximumFractionDigits: 2,
  }).format(number || 0);
};

export const parseLocaleNumber = (stringNumber) => {
  // Si está vacío retornamos 0
  if (!stringNumber) return 0;

  // Limpiamos el string de espacios
  const cleanString = stringNumber.replace(/\s/g, '');

  // Si tiene coma decimal pero no tiene punto de miles
  if (cleanString.includes(',') && !cleanString.includes('.')) {
    return parseFloat(cleanString.replace(',', '.'));
  }

  // Si tiene punto de miles y coma decimal
  if (cleanString.includes('.') && cleanString.includes(',')) {
    return parseFloat(cleanString.replace(/\./g, '').replace(',', '.'));
  }

  // Si solo tiene puntos (miles)
  if (cleanString.includes('.') && !cleanString.includes(',')) {
    return parseFloat(cleanString.replace(/\./g, ''));
  }

  // Si no tiene separadores
  return parseFloat(cleanString) || 0;
};
  
export const formatCurrency = (amount, minDecimalPlaces = 0) => {
  if (amount === null || amount === undefined) return '$0';
  
  return new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: minDecimalPlaces,
    maximumFractionDigits: 2,
  }).format(amount);
}; 

export const formatDate = (dateString) => {
  if (!dateString) return '';

  let date;
  
  // Si la fecha viene en formato ISO con timezone (2025-01-31T00:00:00.000000Z)
  if (dateString.includes('T')) {
    date = new Date(dateString);
    date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  } 
  // Si viene en formato DD-MM-YYYY HH:mm:ss
  else if (dateString.includes('-') && dateString.includes(':')) {
    const [datePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('-');
    date = new Date(year, month - 1, day);
  }
  // Si viene en formato YYYY-MM-DD
  else if (dateString.includes('-')) {
    const parts = dateString.split('-');
    if (parts[0].length === 4) {
      // Formato YYYY-MM-DD
      const [year, month, day] = parts;
      date = new Date(year, month - 1, day);
    } else {
      // Formato DD-MM-YYYY
      const [day, month, year] = parts;
      date = new Date(year, month - 1, day);
    }
  }

  // Verificamos si la fecha es válida
  if (isNaN(date.getTime())) {
    return dateString; // Retornamos el string original si la fecha es inválida
  }

  return date.toLocaleDateString('es-AR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });
};

// src/components/PaymentFlow/LoanCard.js

import React, { useState, useEffect } from 'react';

const LoanCard = ({ loan }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleInstallments = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`card mb-3 border border-primary shadow-sm loan-card ${expanded ? 'expanded' : ''}`}>
      <div className="card-header d-flex justify-content-between p-2 align-items-center" onClick={toggleInstallments}>
        <div className="col-6 text-left">
          <h6 className="text-primary">
            <strong>Préstamo #{loan.id}</strong>
          </h6>
          <p className="mb-0">
            <strong>Fecha:</strong> {loan.issueDate}
          </p>
        </div>
        <div className="col-5">
          <p className="mb-0">
            <strong>Saldo Actual:</strong> ${loan.currentBalance}
          </p>
          <p className="mb-0">
            <strong>Saldo Total:</strong> ${loan.totalBalance}
          </p>
        </div>
        <div>
          <i className={`toggle-icon bi ${expanded ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
        </div>
      </div>
      {expanded && (
        <div className="card-body">
          <div className="form-group">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Venc.</th>
                  <th>Monto</th>
                  <th>Pagos</th>
                  <th>Pendiente</th>
                </tr>
              </thead>
              <tbody>
                {loan.installments.map((installment, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{installment.dueDate}</td>
                    <td>${installment.amount}</td>
                    <td>${installment.paymentsMade}</td>
                    <td>${installment.amountDue}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanCard;

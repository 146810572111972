// src/layouts/MobileLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import './MobileLayout.css';

const logoSmall = process.env.REACT_APP_CLIENT_LOGO || '/assets/images/cirenio.svg';

const MobileLayout = () => {
  return (
    <div className="layout-horizontal">
      <div className="header-top d-flex align-items-center">
        <img 
          src={logoSmall} 
          alt="Logo"
          style={{ height: '30px', marginRight: '20px' }}
        />
        <h4 className="mb-0">Portal de Digitalización</h4>
      </div>

      <div className="mobile-container">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default MobileLayout; 
// src/pages/ProfilePage.js
import React, { useEffect, useState, useMemo, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './ProfilePage.css';
import { ClientContext } from '../context/ClientContext';
import { useAuth } from '../context/AuthContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../services/api';
import PurchaseFlow from '../components/PurchaseFlow/PurchaseFlow';
import CurrentAccount from '../components/Profile/CurrentAccount';
import ClientProfile from '../components/Profile/ClientProfile';
import LoanRefinancing from '../components/Profile/LoanRefinancing';
import ProofDocuments from '../components/Profile/ProofDocuments';
import PaymentFlow from '../components/PaymentFlow/PaymentFlow';
import ClientSales from '../components/Profile/ClientSales';
import AuthorizationModal from '../components/PurchaseFlow/AuthorizationModal';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import HomonymModal from '../components/Modals/HomonymModal';
import NewClientModal from '../components/Modals/NewClientModal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { formatNumber, formatCurrency } from '../utils/numberFormatter';
import { formatDNI } from '../utils/documentFormatter';
import { handleValidationErrors } from '../utils/errorHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faTags, faArrowsRotate, faFileLines, faCirclePlus, faChevronRight, faFolderOpen, faUser } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import QRCode from 'qrcode';

const MySwal = withReactContent(Swal);

const ProfilePage = () => {
  const { dni } = useParams();
  const { clientData, setClientData, clientStatus, setClientStatus, clientAuthorizations, setClientAuthorizations  } = useContext(ClientContext);
  const { channelId, branchId, config, setConfig } = useAuth();
  const [loading, setLoading] = useState(true);
  const [showPurchaseFlow, setShowPurchaseFlow] = useState(false);
  const [showPaymentFlow, setShowPaymentFlow] = useState(false);
  const [activeContent, setActiveContent] = useState(null);
  const [homonyms, setHomonyms] = useState([]);
  const [showHomonymModal, setShowHomonymModal] = useState(false);
  
  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false);
  const [showNewClientModal, setShowNewClientModal] = useState(false);
  const [selectedNewClient, setSelectedNewClient] = useState(null);

  const [canRetryCredit, setCanRetryCredit] = useState(true);
  const [retryCountdown, setRetryCountdown] = useState(0);

  const [isRequesting, setIsRequesting] = useState(false);
  const abortControllerRef = useRef(null);
  const [isMounted, setIsMounted] = useState(true);

  const [showShareModal, setShowShareModal] = useState(false);

  const handleRequestAuthorization = async (payload) => {
    try {
      const apiPayload = {
        "commerce_id": channelId,
        "channel_branch_id": branchId,
        "amount": payload.amount,
        "observation": payload.observation,
        "password": payload.password
      }

      const response = await api.post(`/customers/${clientData.id}/authorizations`, apiPayload);
  
      if (response.status === 201) {
        setClientAuthorizations(response.data.authorization);
        setShowAuthorizationModal(false);
        
        MySwal.fire({
          title: 'Operación exitosa',
          text: response.data.message,
          icon: response.data.authorization.status === 'approved' ? 'success' : 'info',
        });

        if(response.data.authorization.status === 'approved') {
          setProgressText('Autorizado');
          setClientData(prevData => ({
            ...prevData,
            available_credit: response.data.authorization.amount,
          }));
        }
      }

    } catch (error) {
      console.error('Error al solicitar autorización:', error);
      const errorConfig = handleValidationErrors(error);
      MySwal.fire(errorConfig);
    }
  };

  const fetchCustomerData = async (dni) => {
    try {
      const response = await api.get(`/customers/search?document=${dni}`);
      return response.data.results;
    } catch (error) {
      console.error('Error al buscar cliente en el backoffice:', error);
      return [];
    }
  };

  function splitFullName(fullName) {
    const words = fullName.trim().toLowerCase().split(' ');
  
    let firstName = '';
    let lastName = '';
  
    if (words.length === 1) {
      firstName = words[0];
    } else if (words.length === 2) {
      lastName = words[0];
      firstName = words[1];
    } else if (words.length === 3) {
      lastName = words[0];
      firstName = words.slice(1).join(' ');
    } else if (words.length >= 4) {
      lastName = words.slice(0, 2).join(' ');
      firstName = words.slice(2).join(' ');
    }

    return {
      firstName: toProperCase(firstName),
      lastName: toProperCase(lastName),
    };
  }
  
  function toProperCase(text) {
    return text
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }  

  const handleNewClient = async (client) => {
    setLoading(true);
    try {
      const response = await api.post('/customers', {
        cuil_cuit: client.cuit,
        name: client.name,
        birthdate: client.birthdate,
        gender: client.gender,
      });
  
      if (response.status === 201) {
        const { id } = response.data;
        const { firstName, lastName } = splitFullName(client.name);
  
        const newClientData = {
          id,
          dni: client.dni,
          cuit: client.cuit,
          firstName,
          lastName,
          birthDate: client.birthdate,
          gender: client.gender,
          type: client.type,
          status: 'PENDING_EVALUATION',
          total_limit: 0,
          available_credit: 0
        };
  
        setClientData(newClientData);
        setClientStatus(client.type);
        setShowNewClientModal(false);
        setShowHomonymModal(false);
        setLoading(false);
  
        MySwal.fire({
          title: 'Cliente creado',
          text: 'El cliente ha sido creado correctamente.',
          icon: 'success',
        });
  
        if (isMounted) {
          const creditInfo = await fetchCreditInfo(id);
          if (creditInfo && isMounted) {
            setClientData(prevData => ({
              ...prevData,
              total_limit: creditInfo.total_limit,
              available_credit: creditInfo.available_credit,
              status: creditInfo.status,
              client_type: creditInfo.client_type,
              risk_level: creditInfo.risk_level,
              employment_status: creditInfo.employment_status
            }));
          }
        }
      }
    } catch (error) {
      if (!isMounted) return;
      
      console.error('Error al crear el cliente:', error);
      MySwal.fire({
        title: 'Error al crear el cliente',
        text: 'Hubo un problema al crear el cliente. Por favor, intenta nuevamente.',
        icon: 'error',
      });
      setLoading(false);
    }
  };

  const handleExistingClient = async (client) => {
    const firstName = client.firstname || splitFullName(client.name).firstName;
    const lastName = client.lastname || splitFullName(client.name).lastName;
    
    const genderMap = { M: 'M', F: 'F' };
    const gender = genderMap[client.gender] || 'otro';

    const clientDataFromPadron = {
      id: client.id,
      dni: client.dni,
      cuit: client.cuit,
      firstName,
      lastName,
      birthDate: client.birthdate,
      gender,
      type: client.type,
      status: 'PENDING_EVALUATION',
      total_limit: 0,
      available_credit: 0,
      client_type: null,
      risk_level: null,
      employment_status: null,
      my_account_url: client.my_account_url
    };

    setClientData(clientDataFromPadron);
    setClientStatus(client.type);
    setLoading(false);

    if (isMounted) {
      const creditInfo = await fetchCreditInfo(client.id);

      if (creditInfo && isMounted) {
        setClientData(prevData => ({
          ...prevData,
          total_limit: creditInfo.total_limit,
          available_credit: creditInfo.available_credit,
          status: creditInfo.status,
          client_type: creditInfo.client_type,
          risk_level: creditInfo.risk_level,
          employment_status: creditInfo.employment_status
        }));
      }
    }
  };

  const fetchClientProfile = async (dni) => {
    setLoading(true);
    try {
      const customerData = await fetchCustomerData(dni);
  
      if (!isMounted) return;
  
      if (customerData.length === 0) {
        MySwal.fire({
          title: 'DNI no encontrado',
          text: 'El DNI ingresado no es existente o válido. Por favor, verifica los datos.',
          icon: 'warning',
        });
        setLoading(false);
        return;
      }
  
      if (customerData.length > 1) {
        setHomonyms(customerData);
        setShowHomonymModal(true);
        setLoading(false);
        return;
      }
  
      const customer = customerData[0];
      if (customer.exists) {
        await handleExistingClient(customer);
      } else {
        setSelectedNewClient(customer);
        setShowNewClientModal(true);
        setLoading(false);
      }
    } catch (error) {
      if (!isMounted) return;
      
      console.error('Error al buscar cliente:', error);
      MySwal.fire({
        title: 'Error al buscar cliente',
        text: 'Hubo un problema al realizar la búsqueda. Intenta nuevamente.',
        icon: 'error',
      });
      setLoading(false);
    }
  };

  const startRetryCountdown = () => {
    setCanRetryCredit(false);
    setRetryCountdown(60);
    
    const timer = setInterval(() => {
      setRetryCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setCanRetryCredit(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleManualCreditCheck = async () => {
    if (!canRetryCredit || !isMounted) return;
    
    try {
      setIsRequesting(true);
      const response = await api.get(`/customers/${clientData.id}/credit-info`, {
        signal: abortControllerRef.current?.signal
      });
      
      if (!isMounted) return;
      
      if (response.data.status === 'PENDING_EVALUATION') {
        setClientData(prevData => ({
          ...prevData,
          status: 'PENDING_EVALUATION'
        }));
      } else {
        setClientData(prevData => ({
          ...prevData,
          total_limit: response.data.limits.total_limit,
          available_credit: response.data.limits.available_limit,
          status: 'COMPLETED',
          client_type: response.data.client_type,
          risk_level: response.data.risk_level,
          employment_status: response.data.employment_status
        }));
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error al consultar límite:', error);
      }
    } finally {
      if (isMounted) {
        setIsRequesting(false);
        startRetryCountdown();
      }
    }
  };

  const handleAuthorizationCheck = async () => {
    // TODO: Implementar la consulta de la autorización.
    window.location.reload(); // workaround para que se actualice la info de la autorización.
  };

  const fetchCreditInfo = async (clientId) => {
    const MAX_RETRIES = 5;
    const RETRY_DELAY = 5000;
    let retryCount = 0;

    const attemptFetch = async () => {
      try {
        if (!isMounted) return null;
        
        console.log(`Iniciando intento ${retryCount + 1} de ${MAX_RETRIES}`);
        
        const response = await api.get(`/customers/${clientId}/credit-info`, {
          signal: abortControllerRef.current?.signal
        });
        
        if (!isMounted) return null;
        
        if (response.data.status === 'PENDING_EVALUATION') {
          if (retryCount < MAX_RETRIES) {
            retryCount++;
            console.log(`Esperando ${RETRY_DELAY}ms para el siguiente intento`);
            
            try {
              await new Promise((resolve, reject) => {
                const timeoutId = setTimeout(resolve, RETRY_DELAY);
                abortControllerRef.current?.signal.addEventListener('abort', () => {
                  clearTimeout(timeoutId);
                  reject(new Error('Cancelled'));
                });
              });
              
              if (!isMounted) return null;
              return attemptFetch();
            } catch (error) {
              if (error.message === 'Cancelled') {
                console.log('Consulta cancelada durante la espera');
                return null;
              }
              throw error;
            }
          } else {
            console.log('Máximo de intentos alcanzado');
            startRetryCountdown();
            return {
              total_limit: 0,
              available_credit: 0,
              status: 'PENDING_EVALUATION'
            };
          }
        } else if (response.data.status === 'REJECTED') {
          return {
            total_limit: 0,
            available_credit: 0,
            status: 'REJECTED',
            message: response.data.message
          };
        } else {
          console.log('Consulta exitosa');
          setProgressText('Disponible');
          // Si hay información de self_management, actualizamos solo esa parte del config
          if (response.data.self_management) {
            setConfig(prevConfig => ({
              ...prevConfig,
              self_authorization: response.data.self_management
            }));

            // También actualizamos en localStorage
            const storedConfig = JSON.parse(localStorage.getItem('config') || '{}');
            localStorage.setItem('config', JSON.stringify({
              ...storedConfig,
              self_authorization: response.data.self_management
            }));
          }

          // Carga autorización actual.
          if(response.data.authorizations.latest) {
            setClientAuthorizations(response.data.authorizations.latest);

            if(response.data.authorizations.latest.status === 'approved') {
              // retornar datos para el progress bar.
              setProgressText('Autorizado');
              return {
                total_limit: response.data.limits.total_limit,
                available_credit: response.data.authorizations.latest.amount,
                status: 'COMPLETED',
                client_type: response.data.client_type,
                risk_level: response.data.risk_level,
                employment_status: response.data.employment_status
              };
            }
          }

          return {
            total_limit: response.data.limits.total_limit,
            available_credit: response.data.limits.available_limit,
            status: 'COMPLETED',
            client_type: response.data.client_type,
            risk_level: response.data.risk_level,
            employment_status: response.data.employment_status
          };
        }
      } catch (error) {
        if (error.name === 'AbortError' || error.message === 'Cancelled') {
          console.log('Consulta de crédito cancelada');
          return null;
        }
        if (error.response?.status === 403) {
          return {
            total_limit: 0,
            available_credit: 0,
            status: 'REJECTED',
            message: error.response.data.message || 'No hay crédito disponible.'
          };
        }
        console.error('Error al obtener información de crédito:', error);
        return null;
      }
    };

    setIsRequesting(true);
    const result = await attemptFetch();
    
    // Solo desactivamos isRequesting si:
    // 1. El componente sigue montado
    // 2. Obtuvimos un resultado exitoso (status COMPLETED) o alcanzamos el máximo de intentos
    if (isMounted && (result?.status === 'COMPLETED' || retryCount === MAX_RETRIES)) {
      setIsRequesting(false);
    }
    
    return result;
  };  

  useEffect(() => {
    setIsMounted(true);
    
    // Limpiamos el controller anterior si existe
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    // Creamos un nuevo controller
    abortControllerRef.current = new AbortController();

    if (dni) {
      // Reiniciamos estados
      setClientData(null);
      setClientStatus('');
      setActiveContent(null);
      setShowPurchaseFlow(false);
      setShowPaymentFlow(false);
      setShowHomonymModal(false);
      setShowNewClientModal(false);
      setCanRetryCredit(true);
      setRetryCountdown(0);
      setIsRequesting(false);

      // Iniciamos la búsqueda del perfil
      fetchClientProfile(dni);
    }

    return () => {
      setIsMounted(false);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [dni]);
  
  const clientPermissionsMap = {
    NEW: {
      canPurchase: true,
      canViewCurrentAccount: false,
      canRefinance: false,
      canViewProofDocuments: false,
      canMakePayments: false,
      canEditProfile: false,
      canViewSales: true,
    },
    RENEWER: {
      canPurchase: true,
      canViewCurrentAccount: false,
      canRefinance: false,
      canViewProofDocuments: false,
      canMakePayments: true,
      canEditProfile: false,
      canViewSales: true,
    },
    OLD: {
      canPurchase: false,
      canViewCurrentAccount: false,
      canRefinance: false,
      canViewProofDocuments: false,
      canMakePayments: true,
      canEditProfile: false,
      canViewSales: true,
    },
    RESTRICTED: {
      canPurchase: false,
      canViewCurrentAccount: false,
      canRefinance: false,
      canViewProofDocuments: false,
      canMakePayments: false,
      canEditProfile: false,
      canViewSales: true,
    },
    BLACKLISTED: {
      canPurchase: false,
      canViewCurrentAccount: false,
      canRefinance: false,
      canViewProofDocuments: false,
      canMakePayments: false,
      canEditProfile: false,
      canViewSales: true,
    },
  };  

  const permissions = useMemo(() => {
    return clientPermissionsMap[clientStatus] || {
      canPurchase: false,
      canViewCurrentAccount: false,
      canRefinance: false,
      canViewProofDocuments: false,
      canMakePayments: false,
      canEditProfile: false,
      canViewSales: false,
    };
  }, [clientStatus]);

  const [progressValue, setProgressValue] = useState(0);
  const [progressText, setProgressText] = useState('Disponible');

  const percentage = useMemo(() => {
    if (!clientData || clientData.total_limit === 0) return 0;
    const available = parseFloat(clientData.available_credit);
    const total = parseFloat(clientData.total_limit);
    const calculatedPercentage = (available / total) * 100;
    return calculatedPercentage;
  }, [clientData]);
  
  useEffect(() => {
    if (percentage >= 0) {
      setProgressValue(0);
      setTimeout(() => setProgressValue(percentage), 50);
    }
  }, [percentage]);
  
  const progressColor = useMemo(() => {
    if (percentage < 10) return '#ff0000'; // Red
    if (percentage < 30) return '#ffc107'; // Yellow
    return '#198722'; // Green
  }, [percentage]);
  
  const renderProgressBar = () => {
    if (clientData.status === 'PENDING_EVALUATION') {
      return (
        <div className="text-center">
          <div className="progress-container">
            <CircularProgressbarWithChildren
              value={100}
              styles={buildStyles({
                pathColor: '#6c757d',
                trailColor: '#d6d6d6',
                pathTransitionDuration: 0.8,
              })}
            >
              <div style={{ textAlign: 'center' }}>
                {isRequesting ? (
                  <div className="spinner-border spinner-border-sm text-secondary mb-2" role="status">
                    <span className="visually-hidden">Calculando...</span>
                  </div>
                ) : (
                  <i className="bi bi-clock-history text-secondary mb-2" style={{ fontSize: '1.2rem' }}></i>
                )}
                <p className="text-muted" style={{ fontSize: '12px', margin: '4px 0' }}>
                  Calculando crédito
                </p>
              </div>
            </CircularProgressbarWithChildren>
          </div>
          
          <div className="mt-4 w-100">
            <small className="text-muted d-block mb-2">
              <i className="bi bi-info-circle me-1"></i>
              Estamos evaluando tu perfil crediticio
            </small>
            {canRetryCredit && !isRequesting ? (
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={handleManualCreditCheck}
              >
                <i className="bi bi-arrow-clockwise me-1"></i>
                Consultar crédito
              </button>
            ) : !canRetryCredit && !isRequesting ? (
              <small className="text-muted d-block">
                podrás consultarlo en {retryCountdown} segundos
              </small>
            ) : null}
          </div>
        </div>
      );
    } else if (clientData.status === 'REJECTED') {
      return (
        <div className="text-center">
          <div className="progress-container">
            <CircularProgressbarWithChildren
              value={100}
              styles={buildStyles({
                pathColor: '#dc3545', // Color rojo para indicar rechazo
                trailColor: '#d6d6d6',
              })}
            >
              <div className='text-center'>
                <i className="bi bi-exclamation-triangle-fill text-danger mb-2" style={{ fontSize: '1.5rem' }}></i>
                <p className="text-danger mt-0" ><small>Sin Crédito</small></p>
              </div>
            </CircularProgressbarWithChildren>
          </div>
          
          <div className="mt-4 w-100">
            <p className="text-danger">
              <small>No hay línea crediticia para este perfil.</small>
            </p>
          </div>
        </div>
      );
    }

    return (
      <div style={{ width: '150px', margin: '0 auto' }}>
        <CircularProgressbarWithChildren
          value={progressValue}
          maxValue={100}
          styles={buildStyles({
            pathColor: progressColor,
            trailColor: '#d6d6d6',
            pathTransitionDuration: 0.8,
          })}
        >
          <div className="text-center">
            <p className={`m-0 ${progressText === 'Autorizado' ? 'text-success fw-bold' : 'text-muted'}`}>
              <small>{progressText}</small>
            </p>
            <strong className="text-black" style={{ fontSize: '18px' }}>
              {clientData ? formatCurrency(clientData.available_credit) : 0}
            </strong>
          </div>
        </CircularProgressbarWithChildren>
      </div>
    );
  };
  
  const startPurchaseFlow = () => {
    setShowPurchaseFlow(true);
    setActiveContent(null);
    setShowPaymentFlow(false);
  };

  const closePurchaseFlow = () => {
    setShowPurchaseFlow(false);
  };

  const startPaymentFlow = () => {
    setShowPurchaseFlow(false);
    setActiveContent(null);
    setShowPaymentFlow(true);
  };

  const closePaymentFlow = () => {
    setShowPaymentFlow(false);
  };

  const renderActiveContent = () => {
    if (showPurchaseFlow) {
      return (
        <PurchaseFlow clientStatus={clientStatus} maxAmount={clientData.available_credit} onClose={closePurchaseFlow} />
      );
    }

    if (showPaymentFlow) {
      return <PaymentFlow clientData={clientData} onClose={closePaymentFlow} />;
    }

    switch (activeContent) {
      case 'CurrentAccount':
        return <CurrentAccount />;
      case 'ClientProfile':
        return <ClientProfile />;
      case 'LoanRefinancing':
        return <LoanRefinancing />;
      case 'ProofDocuments':
        return <ProofDocuments />;
      case 'Sales':
        return <ClientSales clientId={clientData.id} />;
      default:
        return <p>Selecciona una opción para comenzar.</p>;
    }
  };

  const handleShareMiCuenta = async () => {
    if (!clientData?.my_account_url) return;

    try {
      const canvas = document.createElement('canvas');
      await QRCode.toCanvas(canvas, clientData.my_account_url, {
        width: 200,
        margin: 2,
        color: {
          dark: '#000000',
          light: '#ffffff'
        }
      });

      const qrDataUrl = canvas.toDataURL();

      MySwal.fire({
        title: 'Portal Mi Cuenta',
        html: `
          <div class="text-center">
            <img src="${qrDataUrl}" class="mb-4" alt="QR Code" />
            <p class="mb-3">El cliente puede escanear este código QR para acceder a "Mi Cuenta", para realizar gestiones y consultas.</p>
            
            <div class="alert alert-warning d-flex align-items-center gap-2 mb-4 p-2" style="font-size: 0.9rem; max-width: 100%;">
              <div class="container-fluid">
                <div class="d-flex align-items-center gap-2">
                  <i class="bi bi-exclamation-triangle-fill"></i>
                  <div class="text-start">
                    Este link es único y personal para <strong>${clientData.firstName} ${clientData.lastName}</strong>. 
                    No debe ser compartido con otros clientes.
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div class="row g-3">
              <div class="col-6">
                <p class="text-muted small mb-2">Copia este enlace:</p>
                <div class="input-group">
                  <input 
                    type="text" 
                    value="${clientData.my_account_url}" 
                    class="form-control form-control-sm"
                    readonly
                  />
                  <button 
                    class="btn btn-outline-secondary copy-button"
                    type="button"
                    style="border: 1px solid #dce7f1 !important"
                    onclick="copyToClipboard('${clientData.my_account_url}', this)"
                  >
                    <i class="bi bi-clipboard"></i>
                  </button>
                </div>
              </div>
              <div class="col-6">
                <p class="text-muted small mb-2">O comparte con el cliente:</p>
                <a 
                  href="https://wa.me/?text=${encodeURIComponent(
                    `Hola ${clientData.firstName}, aquí puedes ver toda la información de tus préstamos y realizar gestiones: ${clientData.my_account_url}`
                  )}" 
                  target="_blank" 
                  class="btn btn-success w-100"
                  style="background-color: #25D366; border-color: #25D366"
                >
                  <i class="bi bi-whatsapp me-2"></i>
                  Enviar por WhatsApp
                </a>
              </div>
            </div>
          </div>
        `,
        showConfirmButton: false,
        showCloseButton: true,
        didOpen: () => {
          window.copyToClipboard = (text, button) => {
            navigator.clipboard.writeText(text).then(() => {
              const originalContent = button.innerHTML;
              button.innerHTML = '<i class="bi bi-check"></i>';
              button.disabled = true;
              setTimeout(() => {
                button.innerHTML = originalContent;
                button.disabled = false;
              }, 2000);
            });
          };
        }
      });
    } catch (error) {
      console.error('Error generando QR:', error);
    }
  };

  if (loading || showHomonymModal || showNewClientModal) {
    return (
      <div className="page-content">
        {loading && (
          <div className="loading-overlay">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Cargando...</span>
            </div>
          </div>
        )}

        {showHomonymModal && (
          <HomonymModal
            homonyms={homonyms}
            onSelectClient={async (client) => {
              if (client.exists) {
                await handleExistingClient(client);
                setShowHomonymModal(false);
              } else {
                setSelectedNewClient(client);
                setShowNewClientModal(true);
              }
            }}
            onClose={() => {
              // setShowHomonymModal(false);
              window.location.href = '/';
            }}
          />
        )}

        {showNewClientModal && (
          <NewClientModal
            client={selectedNewClient}
            onConfirm={handleNewClient}
            onClose={() => {
              // setShowNewClientModal(false);
              window.location.href = '/';
            }}
            loading={loading}
          />
        )}

        <div className="row">
          <div className="col-12 col-lg-3">
            <div className="card profile-card text-center">
              <div className="card-body">
                <Skeleton circle={true} height={100} width={100} className="mb-3" />
                <h3><Skeleton width={120} /></h3>
                <p className="text-muted"><Skeleton width={160} /></p>
                <Skeleton width={150} height={30} />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-9">
            <div className="row">
              <div className="col-md-6">
                <div className="card btn-card shadow mb-1" >
                  <div className="card-body text-center">
                    <Skeleton circle={true} height={50} width={50} className="mb-3" />
                    <h5 className="card-title mt-2"><Skeleton width={100} height={20} /></h5>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card btn-card shadow mb-1" >
                  <div className="card-body text-center">
                    <Skeleton circle={true} height={50} width={50} className="mb-3" />
                    <h5 className="card-title mt-2"><Skeleton width={100} height={20} /></h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-2">
              <div className="card-body">
                <p><Skeleton height={40} /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-12 col-lg-3">
          <div className="card profile-card">

            <div className="card-header position-relative m-0 p-4 pb-0">
              <button
                  className="btn btn-link btn-sm position-absolute top-0 end-0 text-primary me-1 mt-1"
                  onClick={handleShareMiCuenta}
                  title="Acceder a Mi Cuenta"
                  disabled={!clientData?.my_account_url}
                >
                <i className="bi bi-person-workspace"></i>
              </button>

              {/* Información básica */}
              <div className="row">
                <div className="d-flex align-items-center me-3">
                  <div className="avatar avatar-xl me-3">
                    <img src={clientData.gender === 'F' ? '/assets/compiled/jpg/female-profile.jpg' : '/assets/compiled/jpg/male-profile.jpg'}  alt="Avatar"  className="rounded-circle" />
                  </div>
                  <div>
                    <h3 className="mb-0">
                      {clientData?.lastName?.length > 11 ? `${clientData?.lastName?.substring(0,11)}...` : clientData?.lastName}<br></br> <small> {clientData?.firstName?.length > 14 ? `${clientData?.firstName?.substring(0,14)}...` : clientData?.firstName}</small>
                    </h3>
                    <small className="text-muted">DNI: { formatDNI(clientData?.dni)}</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="divider">
                <div className="divider-text small">Estado Crediticio</div>
              </div>

              <div className="row text-center">
                <div className="col-12">
                  <div className="mt-2 mb-2" style={{ margin: '0 auto', position: 'relative', }} >
                    {renderProgressBar()}
                  </div>
                  {clientData.status !== 'PENDING_EVALUATION' && clientData.status !== 'REJECTED' && (
                    <div className="mt-4 w-100">
                      <small className="text-muted d-block mb-2">
                        Crédito Total: ${formatNumber(clientData.total_limit)}
                      </small>
                    </div>
                  )}
                  {clientAuthorizations && ['pending', 'rejected', 'cancelled'].includes(clientAuthorizations.status) && (
                    <>
                      <div className={`alert ${clientAuthorizations.status === 'pending' ? 'alert-warning' : 'alert-danger'} mb-0 p-1`}>
                        <i className="bi bi-exclamation-triangle-fill me-2"></i>
                        <span>
                          {clientAuthorizations.status === 'pending' && "Autorización pendiente."}
                          {['rejected', 'cancelled'].includes(clientAuthorizations.status) && `Última autorización fue ${clientAuthorizations.status_label}.`}
                        </span>
                      </div>

                      {clientAuthorizations && clientAuthorizations.status === 'pending' && (
                        <button
                          className="btn btn-sm btn-outline-secondary mt-2"
                          onClick={handleAuthorizationCheck}
                        >
                          <i className="bi bi-arrow-clockwise me-1"></i>Consultar estado
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="divider">
                <div className="divider-text small">Información Adicional</div>
              </div>

              {/* Información Adicional */}
              <div className="row g-3 mt-2">

                {/* Situación Laboral */}
                <div className="col-12 mt-2">
                  {isRequesting ? (
                    <Skeleton className="card mb-0 border-0" height={60} />
                  ) : (
                    <div className="card bg-light-info mb-0">
                      <div className="card-body p-2 ps-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar avatar-sm bg-info me-2">
                            <i className="bi bi-briefcase"></i>
                          </div>
                          <div>
                            <small className="text-muted d-block">Situación Laboral</small>
                            <span className="fw-bold">{clientData?.employment_status?.name || 'N/A'}</span>
                            {/* {clientData?.employment_status?.description && (
                              <small className="text-muted d-block">
                                {clientData.employment_status.description}
                              </small>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Tipo de Cliente */}
                <div className="col-6 mt-2">
                  {isRequesting ? (
                    <Skeleton className="card mb-0 m-0 p-0 border-0" height={60} />
                  ) : (
                    <div className="card bg-light-secondary mb-0">
                      <div className="card-body p-2 ps-3">
                        <div className="d-flex align-items-center">
                          <div className="avatar avatar-sm bg-secondary me-2">
                            <i className="bi bi-person"></i>
                          </div>
                          <div>
                            <small className="text-muted d-block">Cliente</small>
                            <span className="fw-bold">{clientData?.client_type?.label || 'N/A'}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Nivel de Riesgo */}
                <div className="col-6 mt-2">
                  {isRequesting ? (
                    <Skeleton className="card mb-0 m-0 p-0 border-0" height={60} />
                  ) : (
                    <div className="card bg-light-warning mb-0">
                      <div className="card-body p-2 ps-3">
                        <div className="d-flex align-items-center text-center">
                          <div className="avatar avatar-sm bg-warning me-2">
                            <i className="bi bi-shield-check"></i>
                          </div>
                          <div>
                            <small className="text-muted d-block">Riesgo</small>
                            <span className="fw-bold">{clientData?.risk_level || 'N/A'}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Navegación */}
              <div className="divider">
                <div className="divider-text small">Navegación</div>
              </div>

              <div className="d-grid gap-1">
                <button
                  className={`btn ${
                    showPurchaseFlow 
                      ? 'btn-success' 
                      : 'btn-outline-success'
                  } w-100 text-center py-4 position-relative main-action-button ${
                    clientData?.status === 'PENDING_EVALUATION' || 
                    clientData?.status === 'REJECTED' || 
                    !permissions.canPurchase 
                      ? 'disabled' 
                      : ''
                  }`}
                  id="purchase"
                  disabled={
                    clientData?.status === 'PENDING_EVALUATION' || 
                    clientData?.status === 'REJECTED' || 
                    !permissions.canPurchase
                  }
                  style={{ 
                    cursor: clientData.status === 'PENDING_EVALUATION' || 
                            clientData.status === 'REJECTED' 
                              ? 'not-allowed' 
                              : 'pointer',
                    transition: 'all 0.3s ease',
                    boxShadow: showPurchaseFlow 
                      ? '0 0 0 0.25rem rgba(25, 135, 84, 0.25)' 
                      : 'none'
                  }} 
                  title={
                    clientData.status === 'PENDING_EVALUATION' 
                      ? "El límite de crédito está siendo calculado" 
                      : clientData.status === 'REJECTED'
                      ? "Cliente sin línea crediticia disponible"
                      : !permissions.canPurchase
                      ? "No tienes permisos para realizar ventas"
                      : "Inicia una nueva venta para el cliente"
                  }  
                  onClick={
                    clientData?.status !== 'PENDING_EVALUATION' && 
                    clientData?.status !== 'REJECTED' && 
                    permissions.canPurchase 
                      ? startPurchaseFlow 
                      : undefined
                  }
                >
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <h3>
                      <i className={`bi bi-cart ${
                        showPurchaseFlow ? 'text-white' : 'text-success'
                      } mb-2`}></i>
                    </h3>
                    <span className="d-block">Nueva Venta</span>
                  </div>
                </button>

                <AuthorizationModal
                  show={showAuthorizationModal}
                  onClose={() => setShowAuthorizationModal(false)}
                  onRequest={handleRequestAuthorization}
                />

                {process.env.REACT_APP_ENABLE_AUTHORIZATIONS === 'true' && (
                  <button
                    className={`btn btn-light-primary w-100 text-start py-2 ${clientAuthorizations && clientAuthorizations.status === 'pending' ? 'border-0' : ''}`}
                    onClick={() => setShowAuthorizationModal(true)}
                    disabled={clientAuthorizations && clientAuthorizations.status === 'pending'}
                  >
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                      <small className="d-block">Solicitar Autorización</small>
                    </div>
                  </button>
                )}

                {permissions.canViewSales && (
                  <button
                    className={`btn btn-light-primary w-100 text-start py-2 ${
                      activeContent === 'Sales' ? 'btn-outline-primary' : ''
                    }`}
                    onClick={() => {
                      setActiveContent('Sales');
                      setShowPurchaseFlow(false);
                      setShowPaymentFlow(false);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faTags} className="me-2" />
                        <small className="d-block">Consultar Ventas</small>
                      </div>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </button>
                )}
                
                {permissions.canViewProofDocuments && (
                  <button
                    className={`btn btn-light-primary w-100 text-start py-2 ${
                      activeContent === 'ProofDocuments' ? 'btn-outline-primary' : ''
                    }`}
                    onClick={() => {
                      setActiveContent('ProofDocuments');
                      setShowPurchaseFlow(false);
                      setShowPaymentFlow(false);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faFolderOpen} className="me-2" />
                        <small className="d-block">Legajo Digital</small>
                      </div>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </button>
                )}
                
                {permissions.canRefinance && (
                  <button
                    className={`btn btn-light-primary w-100 text-start py-2 ${
                      activeContent === 'LoanRefinancing' ? 'btn-outline-primary' : ''
                    }`}
                    onClick={() => {
                      setActiveContent('LoanRefinancing');
                      setShowPurchaseFlow(false);
                      setShowPaymentFlow(false);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faArrowsRotate} className="me-2" />
                        <small className="d-block">Refinanciaciones</small>
                      </div>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </button>
                )}
                
                {permissions.canEditProfile && (
                  <button
                    className={`btn btn-light-primary w-100 text-start py-2 ${
                      activeContent === 'ClientProfile' ? 'btn-outline-primary' : ''
                    }`}
                    onClick={() => {
                      setActiveContent('ClientProfile');
                      setShowPurchaseFlow(false);
                      setShowPaymentFlow(false);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faFileLines} className="me-2" />
                        <small className="d-block">Constancias</small>
                      </div>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </button>
                )}
              </div>

            </div>
          </div>
        </div>
        <div className="col-12 col-lg-9">
          {/* <div className="row">
            <div className="col-md-6">
              <div 
                className={`card btn-card shadow mb-1 main-action ${
                  showPurchaseFlow ? 'selected' : ''
                } ${
                  clientData?.status === 'PENDING_EVALUATION' || clientData?.status === 'REJECTED' || !permissions.canPurchase ? 'disabled' : ''
                }`}
                id="purchase"
                style={{ 
                  cursor: clientData.status === 'PENDING_EVALUATION' || clientData.status === 'REJECTED' ? 'not-allowed' : 'pointer',
                  opacity: clientData.status === 'PENDING_EVALUATION' || clientData.status === 'REJECTED' ? 0.6 : 1 
                }} 
                title={
                  clientData.status === 'PENDING_EVALUATION' 
                    ? "El límite de crédito está siendo calculado" 
                    : "Inicia una nueva venta para el cliente"
                }
                onClick={clientData?.status !== 'PENDING_EVALUATION' && clientData?.status !== 'REJECTED' && permissions.canPurchase ? startPurchaseFlow : undefined}
              >
                <div className="card-body text-center">
                  <i className="bi bi-cart text-success display-5"></i>
                  <h5 className="card-title mt-2 text-success">Nueva Venta</h5>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className={`card btn-card shadow mb-1 main-action ${
                  showPaymentFlow ? 'selected' : ''
                } ${
                  !permissions.canMakePayments ? 'disabled' : ''
                }`}
                id="payment"
                style={{ cursor: !permissions.canMakePayments ? 'not-allowed' : 'pointer', opacity: !permissions.canMakePayments ? 0.6 : 1 }}
                title="Realiza pagos en nombre del cliente."
                onClick={permissions.canMakePayments ? startPaymentFlow : undefined}
              >
                <div className="card-body text-center">
                  <i className="bi bi-credit-card text-secondary display-5"></i>
                  <h5 className="card-title mt-2 text-secondary">Cobrar Cuotas</h5>
                </div>
              </div>
            </div>
          </div> */}
          <div className="card">
            <div className="card-body" id="content-area">
              {renderActiveContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;

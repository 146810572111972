// src/components/PaymentFlow/PaymentFlow.js

import React, { useState, useEffect } from 'react';
import LoanList from './LoanList';
import PaymentSummary from './PaymentSummary';
import PaymentMethods from './PaymentMethods';
import './PaymentFlow.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '../../services/api';
import { formatNumber, parseLocaleNumber, formatCurrency } from '../../utils/numberFormatter';
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MySwal = withReactContent(Swal);

const PaymentFlow = ({ clientData, onClose }) => {
  const [loans, setLoans] = useState([]);
  const [loadingLoans, setLoadingLoans] = useState(true);
  const [loadingInstallments, setLoadingInstallments] = useState({});
  const [selectedInstallments, setSelectedInstallments] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [amountToDistribute, setAmountToDistribute] = useState('');
  const [cashReceived, setCashReceived] = useState('');
  const [changeDue, setChangeDue] = useState(0);
  const [showQRModal, setShowQRModal] = useState(false);
  const [showCashModal, setShowCashModal] = useState(false);
  const [summary, setSummary] = useState({
    total_debt: null,
    overdue_amount: 0
  });
  const [amountDisplay, setAmountDisplay] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentReceipt, setPaymentReceipt] = useState(null);
  const [receiptDownloaded, setReceiptDownloaded] = useState(false);

  // Cargar préstamos del cliente
  useEffect(() => {
    const fetchLoans = async () => {
      try {
        setLoadingLoans(true);
        const response = await api.get(`/customers/${clientData.id}/loans`);
        
        // Guardamos el resumen
        setSummary({
          total_debt: response.data.summary.total_debt,
          overdue_amount: response.data.summary.overdue_amount
        });

        const formattedLoans = response.data.loans.map(loan => ({
          id: loan.sale_id,
          uid: loan.loan.uid,
          issueDate: loan.loan.issued_date,
          totalDebt: loan.loan.financial_details.total_debt,
          overdueAmount: loan.loan.financial_details.overdue_amount,
          outstandingBalance: loan.loan.financial_details.outstanding_balance,
          product: loan.product,
          nextPayment: loan.loan.next_payment,
          status: loan.loan.status,
          installments: []
        }));
        setLoans(formattedLoans);
      } catch (error) {
        console.error('Error fetching loans:', error);
        MySwal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se pudieron cargar los préstamos del cliente',
        });
      } finally {
        setLoadingLoans(false);
      }
    };

    if (clientData?.id) {
      fetchLoans();
    }
  }, [clientData]);

  // Función para cargar las cuotas de un préstamo
  const fetchInstallments = async (loanId, loanUid) => {
    try {
      setLoadingInstallments(prev => ({ ...prev, [loanId]: true }));
      const response = await api.get(`/customers/${clientData.id}/loans/${loanUid}/installments`);
      
      const formattedInstallments = response.data.installments.map(inst => ({
        installmentNumber: inst.number,
        dueDate: inst.dates.due_date,
        amount: inst.amounts.total,
        paymentsMade: inst.balances.paid.total,
        amountDue: inst.balances.remaining.total,
        status: inst.status,
        dates: inst.dates,
        balances: inst.balances
      }));

      setLoans(prevLoans => 
        prevLoans.map(loan => 
          loan.id === loanId 
            ? { ...loan, installments: formattedInstallments }
            : loan
        )
      );
    } catch (error) {
      console.error('Error fetching installments:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se pudieron cargar las cuotas del préstamo',
      });
    } finally {
      setLoadingInstallments(prev => ({ ...prev, [loanId]: false }));
    }
  };

  // Update total amount whenever selectedInstallments changes
  useEffect(() => {
    const total = selectedInstallments.reduce((sum, item) => sum + parseFloat(item.amount), 0);
    setTotalAmount(total);
  }, [selectedInstallments]);

  // Handle installment selection
  const handleInstallmentSelection = (loanId, installmentIndex, amount) => {
    setSelectedInstallments((prev) => {
      const newInstallments = [...prev];
      const existingIndex = newInstallments.findIndex(
        (item) => item.loanId === loanId && item.installmentIndex === installmentIndex
      );

      if (amount > 0) {
        if (existingIndex >= 0) {
          newInstallments[existingIndex].amount = amount;
        } else {
          const loan = loans.find((l) => l.id === loanId);
          const installment = loan.installments[installmentIndex];
          newInstallments.push({
            loanId,
            installmentIndex,
            installment,
            amount,
          });
        }
      } else {
        if (existingIndex >= 0) {
          newInstallments.splice(existingIndex, 1);
        }
      }
      return newInstallments;
    });
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9,]/g, '');

    if (value === '') {
      setAmountDisplay('');
      setAmountToDistribute('');
      setTotalAmount(0);
      return;
    }

    setAmountDisplay(formatNumber(value));
    const numericValue = parseLocaleNumber(value);
    
    if (!isNaN(numericValue)) {
      setAmountToDistribute(numericValue.toString());
      setTotalAmount(numericValue);
    }
  };

  // Calcular el monto total a pagar (para el resumen)
  const totalAmountToPay = parseFloat(amountToDistribute) || 0;

  // Manejar la confirmación del pago
  const handleConfirmPayment = () => {
    if (!paymentMethod) {
      MySwal.fire({
        icon: 'warning',
        title: 'Medio de pago requerido',
        text: 'Por favor, seleccione un medio de pago.',
      });
      return;
    }

    if (totalAmountToPay <= 0) {
      MySwal.fire({
        icon: 'warning',
        title: 'Monto inválido',
        text: 'Por favor, ingrese un monto válido para pagar.',
      });
      return;
    }

    // Inicializar el modal de pago en efectivo
    setCashReceived(totalAmountToPay.toString());
    setChangeDue(0);
    setShowCashModal(true);
  };

  // Manejar cambios en el efectivo recibido
  const handleCashReceivedChange = (e) => {
    const value = e.target.value;
    setCashReceived(value);
    
    const receivedAmount = parseFloat(value) || 0;
    setChangeDue(receivedAmount - totalAmountToPay);
  };

  // Confirmar pago en efectivo
  const handleCashPaymentConfirm = async () => {
    setIsProcessing(true);
    try {
      const response = await api.post(`/customers/${clientData.id}/payments`, {
        amount: totalAmount,
        payment_type: "general",
        payment_method: "cash"
      });

      setPaymentReceipt(response.data.payment);
      
      MySwal.fire({
        icon: 'success',
        title: '¡Pago Exitoso!',
        text: 'El pago ha sido registrado correctamente.',
        showConfirmButton: true,
      });
    } catch (error) {
      console.error('Error processing payment:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al procesar el pago.',
      });
    } finally {
      setIsProcessing(false);
    }
  };

  // Función para redondear al siguiente múltiplo de 1000
  const roundToNextThousand = (amount) => {
    return Math.ceil(amount / 1000) * 1000;
  };

  // Efecto para inicializar valores cuando se muestra el modal de efectivo
  useEffect(() => {
    if (showCashModal) {
      const roundedAmount = roundToNextThousand(totalAmount);
      setCashReceived(roundedAmount.toString());
      setChangeDue(roundedAmount - totalAmount);
    }
  }, [showCashModal]);

  // Distribute amount entered by the user
  const distributeAmount = () => {
    resetFields();

    const amount = parseFloat(amountToDistribute);
    if (isNaN(amount) || amount <= 0) {
      MySwal.fire({
        icon: 'warning',
        title: 'Datos inválidos',
        text: 'Por favor, ingresa un monto válido para distribuir.',
      });
      return;
    }

    let allInstallments = [];
    loans.forEach((loan) => {
      loan.installments.forEach((installment, index) => {
        allInstallments.push({
          loanId: loan.id,
          installmentIndex: index,
          installment,
        });
      });
    });

    allInstallments.sort((a, b) => {
      const dateA = new Date(a.installment.dueDate.split('/').reverse().join('-'));
      const dateB = new Date(b.installment.dueDate.split('/').reverse().join('-'));
      return dateA - dateB;
    });

    const totalPending = allInstallments.reduce(
      (sum, item) => sum + parseFloat(item.installment.amountDue),
      0
    );
    if (amount > totalPending) {
      MySwal.fire({
        icon: 'warning',
        title: 'Datos inválidos',
        text: 'El monto a distribuir es mayor al total pendiente.',
      });
      return;
    }

    let remainingAmount = amount;
    const newSelectedInstallments = [];

    for (let item of allInstallments) {
      if (remainingAmount <= 0) break;
      const { loanId, installmentIndex, installment } = item;
      const amountDue = parseFloat(installment.amountDue);
      let amountToPay = 0;

      if (remainingAmount >= amountDue) {
        amountToPay = amountDue;
        remainingAmount -= amountDue;
      } else {
        amountToPay = remainingAmount;
        remainingAmount = 0;
      }

      newSelectedInstallments.push({
        loanId,
        installmentIndex,
        installment,
        amount: amountToPay,
      });
    }

    setSelectedInstallments(newSelectedInstallments);

    const updatedLoans = loans.map((loan) => {
      const updatedInstallments = loan.installments.map((installment, index) => {
        const selected = newSelectedInstallments.find(
          (item) => item.loanId === loan.id && item.installmentIndex === index
        );
        return {
          ...installment,
          amountToPay: selected ? selected.amount : '',
          isChecked: !!selected,
          inputDisabled: selected ? false : index !== 0,
          checkboxDisabled: selected ? false : index !== 0,
        };
      });
      return {
        ...loan,
        installments: updatedInstallments,
      };
    });

    setLoans(updatedLoans);
  };

  // Reset all fields before distributing amount
  const resetFields = () => {
    setSelectedInstallments([]);
    const updatedLoans = loans.map((loan) => {
      const updatedInstallments = loan.installments.map((installment, index) => {
        return {
          ...installment,
          amountToPay: '',
          isChecked: false,
          inputDisabled: index !== 0,
          checkboxDisabled: index !== 0,
        };
      });
      return {
        ...loan,
        installments: updatedInstallments,
      };
    });
    setLoans(updatedLoans);
  };

  const handleLoadTotalDebt = () => {
    setAmountDisplay(formatNumber(summary.total_debt.toString(),2));
    setAmountToDistribute(summary.total_debt.toString());
    setTotalAmount(parseFloat(summary.total_debt) || 0);
  };

  const handleDownloadReceipt = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `comprobante-${paymentReceipt.uid}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      setReceiptDownloaded(true);
    } catch (error) {
      console.error('Error downloading receipt:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error al descargar el comprobante.',
      });
    }
  };

  const handlePrintReceipt = (url) => {
    const printWindow = window.open(url, '_blank');
    printWindow.onload = () => {
      printWindow.print();
    };
    setReceiptDownloaded(true);
  };

  return (
    <div className="payment-flow">
      <section className="section">
        <div className="row m-0">
          <div className="col-9 ps-0">
            <div className="container mt-0">
              <div className="card">
                <div className="card-header p-3">
                  <div className="row align-items-center">
                    <div className="col-4">

                      {summary.total_debt !== null && (
                        <>
                          <h5 className="mb-0">
                            Saldo: <span className="text-primary">{formatCurrency(summary.total_debt, 2)}</span>
                          </h5>
                        {summary.overdue_amount > 0 && (
                          <p className="mb-0 text-danger">
                            <small>
                              Saldo vencido: {formatCurrency(summary.overdue_amount, 2)}
                            </small>
                          </p>
                        )}
                        </>
                      )}
                    </div>
                    <div className="col-8 pe-1">
                      <div className="float-end">
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0">$</span>
                          <input 
                            type="text"
                            className="form-control border-start-0" 
                            placeholder="Ingrese monto" 
                            value={amountDisplay} 
                            onChange={handleAmountChange} 
                          />
                          {/* <button 
                            className="btn btn-primary" 
                            onClick={distributeAmount}
                          > 
                            Distribuir 
                          </button> */}
                          <button 
                            className="btn btn-outline-primary" 
                            onClick={handleLoadTotalDebt}
                            title="Cargar saldo total"
                          >
                            <i className="bi bi-cash-coin"></i>
                          </button>
                        </div>
                        {summary.total_debt > 0 && (
                          <small className="text-muted mt-1 d-block text-end">
                            Click en <i className="bi bi-cash-coin"></i> para cargar el saldo total
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body position-relative p-2">
                  <div className="overflow-auto" style={{ maxHeight: '400px', paddingBottom: '10px' }}>
                    <LoanList
                      loans={loans}
                      clientId={clientData.id}
                      onInstallmentSelection={handleInstallmentSelection}
                      loading={loadingLoans}
                      loadingInstallments={loadingInstallments}
                      onLoadInstallments={fetchInstallments}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 pe-0 ps-0">
            <div id="payment-summary" className="card">
              <div className="card-header text-center">
                <h4 className="mb-0">Resumen de Pago</h4>
              </div>
              <div className="card-body p-0">
                <PaymentSummary selectedInstallments={selectedInstallments} />
                <PaymentMethods
                  selectedMethod={paymentMethod}
                  onMethodSelect={setPaymentMethod}
                />
                <div className="mt-3 text-center">
                  <h6>
                    Total a Pagar:{' '}
                    <span className="text-primary">
                      {formatCurrency(totalAmount,2)}
                    </span>
                  </h6>
                  { totalAmount > summary.total_debt && summary.total_debt > 0 && (
                    <small className="mt-1 d-block small text-success">
                      <i className="bi bi-info-circle me-1"></i>
                      Está adelantando {formatCurrency(totalAmount - summary.total_debt, 2)}
                    </small>
                  )}
                  { totalAmount == summary.total_debt && summary.total_debt > 0 && (
                    <small className="mt-1 d-block small text-success">
                      <i className="bi bi-info-circle me-1"></i>
                      Está quedando al día
                    </small>
                  )}
                  <button
                    id="confirmPaymentBtn"
                    className="btn btn-success w-100 mt-3"
                    disabled={totalAmount <= 0 || !paymentMethod}
                    onClick={handleConfirmPayment}
                  >
                    Confirmar Pago
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showQRModal && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal fade show"
            tabIndex="-1"
            style={{ display: 'block' }}
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Pago con QR</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowQRModal(false)}
                  ></button>
                </div>
                <div className="modal-body text-center">
                  <p>Monto a pagar: {formatCurrency(totalAmount)}</p>
                  <img
                    src="/assets/compiled/png/qr-demo.png"
                    width={300}
                    alt="QR Code"
                  />
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    onClick={() => setShowQRModal(false)}
                  >
                    Cancelar
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {}}
                  >
                    Confirmar Pago
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showCashModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div className="modal fade show" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                {!paymentReceipt ? (
                  <>
                    <div className="modal-header">
                      <h5 className="modal-title">Confirmar Pago en Efectivo</h5>
                      <button type="button" className="btn-close" onClick={() => setShowCashModal(false)}></button>
                    </div>
                    <div className="modal-body">
                      <div className="mb-3">
                        <label className="form-label">Monto a cobrar:</label>
                        <h4 className="text-primary">{formatCurrency(totalAmount,2)}</h4>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="cashReceived" className="form-label">Efectivo recibido:</label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0">$</span>
                          <input
                            type="number"
                            className={`form-control form-control-lg ${changeDue < 0 ? 'is-invalid' : ''}`}
                            id="cashReceived"
                            value={cashReceived}
                              onChange={handleCashReceivedChange}
                              min={totalAmount}
                            />
                            {changeDue < 0 && (
                              <div className="invalid-feedback">
                                El monto recibido es menor al total a pagar
                              </div>
                            )}  
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Vuelto a entregar:</label>
                        <h4 className={changeDue >= 0 ? 'text-success' : 'text-danger'}>
                          {formatCurrency(Math.max(changeDue, 0),2)}
                        </h4>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button 
                        className="btn btn-secondary" 
                        onClick={() => setShowCashModal(false)}
                        disabled={isProcessing}
                      >
                        Cancelar
                      </button>
                      <button
                        className="btn btn-success"
                        onClick={handleCashPaymentConfirm}
                        disabled={changeDue < 0 || isProcessing}
                      >
                        {isProcessing ? (
                          <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Procesando...
                          </>
                        ) : (
                          'Confirmar Pago'
                        )}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="modal-header">
                      <h5 className="modal-title">Comprobante de Pago</h5>
                      <button type="button" className="btn-close" onClick={() => setShowCashModal(false)}></button>
                    </div>
                    <div className="modal-body">
                      <div className="text-center mb-4">
                        <i className="fas fa-check-circle text-success" style={{ fontSize: '48px' }}></i>
                        <h4 className="mt-2">¡Pago Registrado!</h4>
                        <p className="text-muted">Comprobante #{paymentReceipt.uid}</p>
                      </div>
                      <div className="d-flex justify-content-center mb-3">
                        <div className="btn-group">
                          <button
                            className="btn btn-outline-primary"
                            // onClick={() => handleDownloadReceipt(paymentReceipt.receipt_url)}
                            onClick={() => handlePrintReceipt(paymentReceipt.receipt_url)} // TODO: Implementar descarga del comprobante
                          >
                            <FontAwesomeIcon icon={faDownload} className="me-2" />
                            Descargar
                          </button>
                          <button
                            className="btn btn-outline-secondary"
                            onClick={() => handlePrintReceipt(paymentReceipt.receipt_url)}
                          >
                            <FontAwesomeIcon icon={faPrint} className="me-2" />
                            Imprimir
                          </button>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button 
                          className="btn btn-primary" 
                          onClick={onClose}
                        >
                          Cerrar
                        </button>
                      </div>

                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentFlow;

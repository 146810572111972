// src/components/PurchaseFlow/Steps/DataEntry/Sections/AddressContact.js
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { faCheckCircle, faExclamationTriangle, faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../../../../services/api';
import Swal from 'sweetalert2';

const provinces = [{"id":2,"name":"Buenos Aires"},{"id":3,"name":"Capital Federal"},{"id":10,"name":"Catamarca"},{"id":8,"name":"Chaco"},{"id":19,"name":"Chubut"},{"id":22,"name":"Cordoba"},{"id":21,"name":"Corrientes"},{"id":5,"name":"Entre Rios"},{"id":14,"name":"Formosa"},{"id":23,"name":"Jujuy"},{"id":11,"name":"La Pampa"},{"id":6,"name":"La Rioja"},{"id":12,"name":"Mendoza"},{"id":13,"name":"Misiones"},{"id":15,"name":"Neuquen"},{"id":16,"name":"Rio Negro"},{"id":1,"name":"Salta"},{"id":9,"name":"San Juan"},{"id":4,"name":"San Luis"},{"id":24,"name":"Santa Cruz"},{"id":17,"name":"Santa Fe"},{"id":7,"name":"Santiago del Estero"},{"id":20,"name":"Tierra del Fuego"},{"id":18,"name":"Tucuman"}];

const AddressContact = forwardRef((props, ref) => {
  const {
    formData,
    handleChange,
    activeSection,
    sectionName,
    setSectionValidity,
    sectionValidity,
    handleSectionClick,
  } = props;

  const [errors, setErrors] = useState({});
  const [touchedFields, setTouchedFields] = useState({});
  const [localities, setLocalities] = useState([]);
  const [isLoadingLocalities, setIsLoadingLocalities] = useState(false);

  const requiredFields = [
    'address',
    'province',
    'locality',
    'postalCode',
    'phone',
    'email'
  ];

  useEffect(() => {
    const allFieldsTouched = requiredFields.every(field => touchedFields[field]);
    if (allFieldsTouched) {
      validateSection();
    }
  }, [touchedFields]);

  useEffect(() => {
    if (!formData.province) {
      setLocalities([]);
    }
  }, [formData.province]);
  
  const validateField = (name, value) => {
      let error = '';
      switch (name) {
        case 'address':
          if (!value) {
            error = 'El domicilio es requerido';
          }
          break;
        case 'province':
          if (!value) {
            error = 'La provincia es requerida';
          }
          break;
        case 'locality':
          if (!value) {
            error = 'La localidad es requerida';
          }
          break;
        case 'postalCode':
          if (!value) {
            error = 'El código postal es requerido';
          }
          break;
        case 'phone':
          if (!value) {
            error = 'El teléfono celular es requerido';
          } else if (value.length < 10 || value.length > 13) {
            error = 'El teléfono debe tener entre 10 y 13 caracteres';
          }
          break;
        case 'email':
          if (!value) {
            error = 'El email es requerido';
          } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            error = 'Por favor, ingresa un email válido';
          }
          break;            
        default:
          break;
      }
  
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));
  
      return !error;    
  };

  const validateSection = () => {
      const fieldsToValidate = ['address', 'province', 'locality', 'postalCode', 'phone', 'email'];
      let newErrors = {};
      fieldsToValidate.forEach((field) => {
        const isValidField = validateField(field, formData[field]);
        if (!isValidField) {
          newErrors[field] = errors[field];
        }
      });
  
      setErrors(newErrors);
  
      const isValid = Object.keys(newErrors).length === 0;
      setSectionValidity((prev) => ({ ...prev, [sectionName]: isValid }));
      return isValid;
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setTouchedFields(prev => ({
      ...prev,
      [name]: true
    }));
    handleChange(e);
  };

  const handleFieldBlur = (e) => {
    const { name, value } = e.target;
    setTouchedFields(prev => ({
      ...prev,
      [name]: true
    }));
    validateField(name, value);
  };

  const handleProvinceChange = async (e) => {
    const { name, value } = e.target;
    handleFieldChange(e);
    
    // Resetear la localidad y su error cuando cambia la provincia
    handleFieldChange({
      target: { 
        name: 'locality',
        value: ''
      }
    });
    setErrors(prev => ({
      ...prev,
      locality: ''
    }));
    
    if (value) {
      const cachedLocalities = getLocalitiesFromCache(value);
      if (cachedLocalities) {
        setLocalities(cachedLocalities);
      } else {
        setIsLoadingLocalities(true);
        try {
          const response = await api.get(`/provinces/${value}/localities`);
          setLocalities(response.data);
          saveLocalitiesToCache(value, response.data);
        } catch (error) {
          console.error('Error fetching localities:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No se pudieron cargar las localidades. Por favor, intente nuevamente.',
          });
        } finally {
          setIsLoadingLocalities(false);
        }
      }
    } else {
      setLocalities([]);
    }
  };
  
  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, '');
    if (numericValue.length <= 13) {
      handleFieldChange({
        target: { name, value: numericValue },
      });
    }
  };

  const handleLocalityChange = (e) => {
    const { name, value } = e.target;
    handleFieldChange(e);
    const selectedLocality = localities.find((loc) => loc.id === Number(value));
    if (selectedLocality) {
      handleFieldChange({
        target: { name: 'postalCode', value: selectedLocality.postal_code },
      });
      validateField('postalCode', selectedLocality.postal_code);
    }
  };    

  const getLocalitiesFromCache = (provinceId) => {
    const cachedData = localStorage.getItem(`localities_${provinceId}`);
    return cachedData ? JSON.parse(cachedData) : null;
  };
  
  const saveLocalitiesToCache = (provinceId, localities) => {
    localStorage.setItem(`localities_${provinceId}`, JSON.stringify(localities));
  };
  
  useImperativeHandle(ref, () => ({
    validateSection,
  }));

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading${sectionName}`}>
      <button
          className={`accordion-button ${activeSection !== sectionName ? 'collapsed' : ''}`}
          type="button"
          onClick={() => handleSectionClick(sectionName)}
        >
          <FontAwesomeIcon icon={faHouse} className="me-2" />
          Domicilio y Contacto{' '}
            {sectionValidity[sectionName] === true && (
              <FontAwesomeIcon icon={faCheckCircle} className="text-success ms-2" />
            )}
            {sectionValidity[sectionName] === false && (
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger ms-2" />
            )}
        </button>
      </h2>
      <div
        className={`accordion-collapse collapse ${activeSection === sectionName ? 'show' : ''}`}
        aria-labelledby={`heading${sectionName}`}
      >
        <div className="accordion-body">
          <div className="row">
            {/* Campos con validación */}
            <div className="col-md-6 mb-3">
              <label className="form-label">Domicilio<sup>*</sup></label>
              <input
                type="text"
                className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                name="address"
                value={formData.address || ''}
                onChange={handleFieldChange}
                onBlur={handleFieldBlur}
                autoComplete="off"
                required
              />
              {errors.address && <div className="invalid-feedback">{errors.address}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Provincia<sup>*</sup></label>
              <select
                className={`form-control ${errors.province ? 'is-invalid' : ''}`}
                name="province"
                value={formData.province || ''}
                onChange={handleProvinceChange}
                onBlur={handleFieldBlur}
                autoComplete="off"
                required
              >
                <option value="" disabled>Seleccione una provincia</option>
                {provinces.map((prov) => (
                  <option key={prov.id} value={prov.id}>{prov.name}</option>
                ))}
              </select>
              {errors.province && <div className="invalid-feedback">{errors.province}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Localidad<sup>*</sup></label>
              <div className="position-relative">
                <select
                  className={`form-control ${errors.locality && !isLoadingLocalities ? 'is-invalid' : ''}`}
                  name="locality"
                  value={formData.locality || ''}
                  onChange={handleLocalityChange}
                  onBlur={handleFieldBlur}
                  autoComplete="off"
                  required
                  disabled={!formData.province || isLoadingLocalities}
                >
                  <option value="" disabled>
                    {!formData.province 
                      ? 'Primero seleccione una provincia'
                      : isLoadingLocalities 
                        ? 'Cargando localidades...'
                        : 'Seleccione una localidad'
                    }
                  </option>
                  {localities.map((loc) => (
                    <option key={loc.id} value={loc.id}>{loc.name}</option>
                  ))}
                </select>
                {isLoadingLocalities && (
                  <div className="position-absolute top-50 end-0 translate-middle-y me-2">
                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                      <span className="visually-hidden">Cargando...</span>
                    </div>
                  </div>
                )}
                {errors.locality && !isLoadingLocalities && (
                  <div className="invalid-feedback">{errors.locality}</div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Código Postal<sup>*</sup></label>
              <input
                type="text"
                className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                name="postalCode"
                value={formData.postalCode || ''}
                onChange={handleFieldChange}
                onBlur={handleFieldBlur}
                autoComplete="off"
                required
              />
              {errors.postalCode && <div className="invalid-feedback">{errors.postalCode}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Teléfono Celular<sup>*</sup></label>
              <input
                type="tel"
                className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                name="phone"
                value={formData.phone || ''}
                onChange={handlePhoneChange}
                onBlur={handleFieldBlur}
                autoComplete="off"
                required
              />
              {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Email<sup>*</sup></label>
              <input
                type="email"
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                name="email"
                value={formData.email || ''}
                onChange={handleFieldChange}
                onBlur={handleFieldBlur}
                autoComplete="off"
                required
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AddressContact;
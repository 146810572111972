// src/components/Navbar.js
import React, { useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Logo from '../components/Logo';
import { useAuth } from '../context/AuthContext';
import api from '../services/api';

const MySwal = withReactContent(Swal);

function Navbar() {
  const { user, commerceName, branchName, commerceLogo, logout } = useAuth();
  const [dni, setDni] = useState('');
  const navigate = useNavigate();

  const defaultUserAvatar = process.env.REACT_APP_DEFAULT_USER_AVATAR;

  const handleDniChange = (e) => {
    const value = e.target.value;
    // Solo permitir números y limitar a 8 caracteres
    if (value === '' || (/^\d+$/.test(value) && value.length <= 8)) {
      setDni(value);
    }
  };

  const redirectBasedOnDni = async () => {
    if (dni.length < 7 || dni.length > 8) {
      MySwal.fire({
        icon: 'warning',
        title: 'DNI inválido',
        text: 'El DNI debe tener entre 7 y 8 caracteres.',
      });
      return;
    }

    navigate(`/clientes/${dni}`);
    setDni(''); // Limpiamos el input después de navegar
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      redirectBasedOnDni();
    }
  };

  const handleLogout = async () => {
    try {
      await api.post('/logout', {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
      });
      logout(); // Limpiar el estado de la sesión
      navigate('/login'); // Redirigir al login
    } catch (error) {
      console.error('Error during logout:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error al cerrar sesión',
        text: 'Inténtalo de nuevo más tarde.',
      });
    }
  };

  return (
    <header className="mb-4">
      <div className="header-top">
        <div className="container d-flex align-items-center">
          <div className="logo me-3">
            <Logo />
          </div>

          <div className="col-md-4 header-center">
            <div className="search-input input-group">
              <span className="input-group-text bg-light">
                <i className="bi bi-person-vcard"></i>
              </span>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Ingrese DNI del cliente..."
                value={dni}
                onChange={handleDniChange}
                onKeyDown={handleKeyDown}
                inputMode="numeric"
                pattern="[0-9]*"
              />
              <button className="btn icon btn-light" onClick={redirectBasedOnDni}>
                <FontAwesomeIcon icon={faMagnifyingGlass} size="2x" />
              </button>
            </div>
          </div>

          <div className="header-top-right">
            <div className="dropdown">
              <a href="#" id="topbarUserDropdown" className="user-dropdown d-flex align-items-center dropend dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" >
                <div className="ms-2 avatar avatar-md2 border">
                  <img src={defaultUserAvatar} alt="Avatar" />
                </div>
                <div className="text">
                  <h6 className="user-dropdown-name">{user.firstname} {user.lastname}</h6>
                  <p className="user-dropdown-status text-sm text-muted">
                    {commerceName} ({branchName})
                  </p>
                </div>
              </a>
              <ul className="dropdown-menu dropdown-menu-end shadow-lg">
                <li>
                  <NavLink to="/" className="dropdown-item" end>
                    <i className="bi bi-house me-2"></i> Inicio
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/ventas" className="dropdown-item">
                    <i className="bi bi-cart me-2"></i> Ventas
                  </NavLink>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {/* <li>
                  <a className="dropdown-item" href="#">
                    <i className="bi bi-gear me-2"></i> Configuración
                  </a>
                </li> */}
                <li>
                  <NavLink to="/documentos" className="dropdown-item">
                    <i className="bi bi-upload me-2"></i> Digitalizar documentos
                  </NavLink>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <button
                    className="dropdown-item text-end"
                    onClick={handleLogout}
                  >
                    Cerrar sesión <i className="bi bi-box-arrow-right me-2"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;

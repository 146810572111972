import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { faCheckCircle, faExclamationTriangle, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../../../../services/api';
import { formatNumber, parseLocaleNumber } from '../../../../../utils/numberFormatter';
import Swal from 'sweetalert2';

const provinces = [{"id":2,"name":"Buenos Aires"},{"id":3,"name":"Capital Federal"},{"id":10,"name":"Catamarca"},{"id":8,"name":"Chaco"},{"id":19,"name":"Chubut"},{"id":22,"name":"Cordoba"},{"id":21,"name":"Corrientes"},{"id":5,"name":"Entre Rios"},{"id":14,"name":"Formosa"},{"id":23,"name":"Jujuy"},{"id":11,"name":"La Pampa"},{"id":6,"name":"La Rioja"},{"id":12,"name":"Mendoza"},{"id":13,"name":"Misiones"},{"id":15,"name":"Neuquen"},{"id":16,"name":"Rio Negro"},{"id":1,"name":"Salta"},{"id":9,"name":"San Juan"},{"id":4,"name":"San Luis"},{"id":24,"name":"Santa Cruz"},{"id":17,"name":"Santa Fe"},{"id":7,"name":"Santiago del Estero"},{"id":20,"name":"Tierra del Fuego"},{"id":18,"name":"Tucuman"}];

const EmploymentInfo = forwardRef((props, ref) => {
  const {
    formData,
    handleChange,
    activeSection,
    sectionName,
    setSectionValidity,
    sectionValidity,
    handleSectionClick,
  } = props;

  const [errors, setErrors] = useState({});
  const [touchedFields, setTouchedFields] = useState({});
  const [workLocalities, setWorkLocalities] = useState([]);
  const [salaryDisplay, setSalaryDisplay] = useState('');
  const [isLoadingLocalities, setIsLoadingLocalities] = useState(false);

  const requiredFields = [
    'cuit',
    'companyName',
    'salary',
    'entryDate',
    'workAddress',
    'workProvince',
    'workLocality',
    'workPostalCode'
  ];

  useEffect(() => {
    const allFieldsTouched = requiredFields.every(field => touchedFields[field]);
    console.log('requiredFields', requiredFields);
    console.log('allFieldsTouched', allFieldsTouched);
    console.log('touchedFields', touchedFields);
    if (allFieldsTouched) {
      validateSection();
    }
  }, [touchedFields]);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setTouchedFields(prev => ({
      ...prev,
      [name]: true
    }));
    handleChange(e);
  };

  const handleFieldBlur = (e) => {
    const { name, value } = e.target;
    setTouchedFields(prev => ({
      ...prev,
      [name]: true
    }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'cuit':
        if (!value) {
          error = 'El CUIT es requerido';
        } else if (!/^\d{2}-\d{7,8}-\d{1}$/.test(value)) {
          error = 'Ingrese un CUIT válido';
        }
        break;
      case 'companyName':
        if (!value) {
          error = 'El nombre de la empresa es requerido';
        }
        break;
      case 'salary':
        if (!value) {
          error = 'El salario es requerido';
        } else if (parseFloat(value) <= 0) {
          error = 'El salario debe ser mayor a 0';
        }
        break;
      case 'entryDate':
        if (!value) {
          error = 'La fecha de ingreso es requerida';
        } else if (new Date(value) > new Date()) {
          error = 'Ingrese una fecha de ingreso válida';
        }
        break;
      case 'workAddress':
        if (!value) {
          error = 'El domicilio laboral es requerido';
        }
        break;
      case 'workProvince':
        if (!value) {
          error = 'La provincia es requerida';
        }
        break;
      case 'workLocality':
        if (!value) {
          error = 'La localidad es requerida';
        }
        break;        
      case 'workPostalCode':
        if (!value) {
          error = 'El código postal laboral es requerido';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    return !error;
  };

  const validateSection = () => {
    const fieldsToValidate = ['cuit', 'companyName', 'salary', 'entryDate', 'workAddress', 'workPostalCode', 'workProvince', 'workLocality'];
    const newErrors = {};
  
    fieldsToValidate.forEach((field) => {
      const value = formData[field];
      const isValidField = validateField(field, value);
      if (!isValidField) {
        newErrors[field] = errors[field];
      }
    });

    const isValid = Object.keys(newErrors).length === 0;
    setSectionValidity((prev) => ({ ...prev, [sectionName]: isValid }));
    return isValid;
  };

  const handleCuitChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length <= 11) {
      const formattedValue = numericValue
        .replace(/^(\d{2})(\d{7,8})(\d{1})$/, '$1-$2-$3')
        .replace(/-$/, '');

      handleFieldChange({
        target: { name, value: formattedValue },
      });
    }
  };

  const handleProvinceChange = async (e) => {
    const { name, value } = e.target;
    handleFieldChange(e);
    
    // Resetear la localidad y su error cuando cambia la provincia
    handleFieldChange({
      target: { 
        name: 'workLocality',
        value: ''
      }
    });
    setErrors(prev => ({
      ...prev,
      workLocality: ''
    }));
    
    if (value) {
      const cachedLocalities = getLocalitiesFromCache(value);
      if (cachedLocalities) {
        setWorkLocalities(cachedLocalities);
      } else {
        setIsLoadingLocalities(true);
        try {
          const response = await api.get(`/provinces/${value}/localities`);
          setWorkLocalities(response.data);
          saveLocalitiesToCache(value, response.data);
        } catch (error) {
          console.error('Error fetching localities:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No se pudieron cargar las localidades. Por favor, intente nuevamente.',
          });
        } finally {
          setIsLoadingLocalities(false);
        }
      }
    } else {
      setWorkLocalities([]);
    }
  };
  
  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, '');
    if (numericValue.length <= 13) {
      handleFieldChange({
        target: { name, value: numericValue },
      });
    }
  };

  const handleLocalityChange = (e) => {
    const { name, value } = e.target;
    handleFieldChange(e);

    const selectedLocality = workLocalities.find((loc) => loc.id === Number(value));
    if (selectedLocality) {
      handleFieldChange({
        target: { name: 'workPostalCode', value: selectedLocality.postal_code },
      });
      validateField('workPostalCode', selectedLocality.postal_code);
    }
  };

  const getLocalitiesFromCache = (provinceId) => {
    const cachedData = localStorage.getItem(`localities_${provinceId}`);
    return cachedData ? JSON.parse(cachedData) : null;
  };
  
  const saveLocalitiesToCache = (provinceId, localities) => {
    localStorage.setItem(`localities_${provinceId}`, JSON.stringify(localities));
  };

  const handleSalaryChange = (e) => {
    const value = e.target.value.replace(/[^0-9,]/g, '');
    
    if (value === '') {
      handleFieldChange({ target: { name: 'salary', value: '' } });
      return;
    }

    setSalaryDisplay(formatNumber(value));
    const numericValue = parseLocaleNumber(value);
    if (!isNaN(numericValue)) {
      handleFieldChange({ 
        target: { 
          name: 'salary', 
          value: numericValue
        } 
      });
    }
  };

  useImperativeHandle(ref, () => ({
    validateSection
  }));

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading${sectionName}`}>
        <button
          className={`accordion-button ${activeSection !== sectionName ? 'collapsed' : ''}`}
          type="button"
          onClick={() => handleSectionClick(sectionName)}
        >
          <FontAwesomeIcon icon={faBriefcase} className="me-2" />
          Información Laboral{' '}
          {sectionValidity[sectionName] === true && (
            <FontAwesomeIcon icon={faCheckCircle} className="text-success ms-2" />
          )}
          {sectionValidity[sectionName] === false && (
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger ms-2" />
          )}
        </button>
      </h2>
      <div
        className={`accordion-collapse collapse ${activeSection === sectionName ? 'show' : ''}`}
        aria-labelledby={`heading${sectionName}`}
      >
        <div className="accordion-body">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">CUIT<sup>*</sup></label>
              <input
                type="text"
                className={`form-control ${errors.cuit ? 'is-invalid' : ''}`}
                name="cuit"
                value={formData.cuit || ''}
                onChange={handleCuitChange}
                onBlur={handleFieldBlur}
                required
                autoComplete="off"
              />
              {errors.cuit && <div className="invalid-feedback">{errors.cuit}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Empresa<sup>*</sup></label>
              <input
                type="text"
                className={`form-control ${errors.companyName ? 'is-invalid' : ''}`}
                name="companyName"
                value={formData.companyName || ''}
                onChange={handleFieldChange}
                onBlur={handleFieldBlur}
                required
                autoComplete="off"
              />
              {errors.companyName && <div className="invalid-feedback">{errors.companyName}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Salario Mensual<sup>*</sup></label>
              <div className="input-group">
                <span className="input-group-text bg-transparent border-end-0">$</span>
                <input
                  type="text"
                  className={`form-control border-start-0 ${errors.salary ? 'is-invalid' : ''}`}
                  name="salary"
                  value={salaryDisplay}
                  onChange={handleSalaryChange}
                  onBlur={handleFieldBlur}
                  autoComplete="off"
                  required
                />
                {errors.salary && <div className="invalid-feedback">{errors.salary}</div>}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Fecha de Ingreso<sup>*</sup></label>
              <input
                type="date"
                className={`form-control ${errors.entryDate ? 'is-invalid' : ''}`}
                name="entryDate"
                value={formData.entryDate || ''}
                onChange={handleFieldChange}
                onBlur={handleFieldBlur}
                autoComplete="off"
                required
              />
              {errors.entryDate && <div className="invalid-feedback">{errors.entryDate}</div>}
            </div>
            {/* Campos opcionales */}
            <div className="col-md-6 mb-3">
              <label className="form-label">Cargo</label>
              <input
                type="text"
                className="form-control"
                name="position"
                value={formData.position || ''}
                onChange={handleFieldChange}
                onBlur={handleFieldBlur}
                autoComplete="off"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Teléfono Laboral</label>
              <input
                type="tel"
                className="form-control"
                name="workPhone"
                value={formData.workPhone || ''}
                onChange={handlePhoneChange}
                onBlur={handleFieldBlur}
                autoComplete="off"
              />
            </div>
            {/* Campos requeridos */}
            <div className="col-md-6 mb-3">
              <label className="form-label">Domicilio Laboral<sup>*</sup></label>
              <input
                type="text"
                className={`form-control ${errors.workAddress ? 'is-invalid' : ''}`}
                name="workAddress"
                value={formData.workAddress || ''}
                onChange={handleFieldChange}
                onBlur={handleFieldBlur}
                autoComplete="off"
                required
              />
              {errors.workAddress && <div className="invalid-feedback">{errors.workAddress}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Provincia<sup>*</sup></label>
              <select
                className={`form-control ${errors.workProvince ? 'is-invalid' : ''}`}
                name="workProvince"
                value={formData.workProvince || ''}
                onChange={handleProvinceChange}
                onBlur={handleFieldBlur}
                autoComplete="off"
                required
              >
                <option value="" disabled>Seleccione una provincia</option>
                {provinces.map((prov, index) => (
                  <option key={index} value={prov.id}>{prov.name}</option>
                ))}
              </select>
              {errors.workProvince && <div className="invalid-feedback">{errors.workProvince}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Localidad<sup>*</sup></label>
              <div className="position-relative">
                <select
                  className={`form-control ${errors.workLocality && !isLoadingLocalities ? 'is-invalid' : ''}`}
                  name="workLocality"
                  value={formData.workLocality || ''}
                  onChange={handleLocalityChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  autoComplete="off"
                  required
                  disabled={!formData.workProvince || isLoadingLocalities}
                >
                  <option value="" disabled>
                    {!formData.workProvince 
                      ? 'Primero seleccione una provincia'
                      : isLoadingLocalities 
                        ? 'Cargando localidades...'
                        : 'Seleccione una localidad'
                    }
                  </option>
                  {workLocalities.map((loc) => (
                    <option key={loc.id} value={loc.id}>{loc.name}</option>
                  ))}
                </select>
                {isLoadingLocalities && (
                  <div className="position-absolute top-50 end-0 translate-middle-y me-2">
                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                      <span className="visually-hidden">Cargando...</span>
                    </div>
                  </div>
                )}
                {errors.workLocality && !isLoadingLocalities && (
                  <div className="invalid-feedback">{errors.workLocality}</div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Código Postal Laboral<sup>*</sup></label>
              <input
                type="text"
                className={`form-control ${errors.workPostalCode ? 'is-invalid' : ''}`}
                name="workPostalCode"
                value={formData.workPostalCode || ''}
                onChange={handleFieldChange}
                onBlur={handleFieldBlur}
                autoComplete="off"
                required
              />
              {errors.workPostalCode && <div className="invalid-feedback">{errors.workPostalCode}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EmploymentInfo;




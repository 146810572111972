// src/components/Profile/ClientProfile.js
import React, { useState, useContext } from 'react';
import { ClientContext } from '../../context/ClientContext';
import Skeleton from 'react-loading-skeleton';
import { Modal, Button } from 'react-bootstrap';
import ImageDropzone from './ImageDropzone';

const ClientProfile = () => {
  const { clientData } = useContext(ClientContext);
  const [loading, setLoading] = useState(false);
  
  // Estados para manejar los modales y los datos que se editan
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [editField, setEditField] = useState({});

  const [editMode, setEditMode] = useState({
    dniFrente: false,
    dniDorso: false,
    selfie: false,
  });

  const handleEditClick = (field) => {
    setEditMode({ ...editMode, [field]: !editMode[field] });
  };

  const handleDrop = (acceptedFiles, field) => {
    console.log(`Archivo subido para ${field}:`, acceptedFiles[0]);
    setEditMode({ ...editMode, [field]: false });
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (title, field) => {
    setModalTitle(title);
    setEditField(field);
    setShowModal(true);
  };

  const handleSaveChanges = () => {
    console.log('Guardando cambios: ', editField);
    setShowModal(false);
  };

  if (loading) {
    return (
      <div className="row">
        {[1, 2, 3, 4].map((i) => (
          <div key={i} className="col-md-6 mb-4">
            <Skeleton height={150} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="row">
      {/* Información Personal */}
      <div className="col-md-6 mb-4">
        <div className="card border">
          <div className="card-header">
            <h5>Información Personal
              <i className="bi bi-pencil-square float-end" style={{ cursor: 'pointer' }} onClick={() => handleShowModal('Editar Información Personal', clientData)}></i>
            </h5>
          </div>
          <div className="card-body">
            <p><strong>Nombre:</strong> {clientData.nombre} {clientData.apellido}</p>
            <p><strong>DNI:</strong> {clientData.dni}</p>
            <p><strong>CUIL:</strong> {clientData.cuit}</p>
            <p><strong>Fecha de Nacimiento:</strong> {clientData.birthDate}</p>
            <p><strong>Sexo:</strong> {clientData.gender}</p>
          </div>
        </div>
      </div>

      {/* Información Laboral */}
      <div className="col-md-6 mb-4">
        <div className="card border">
          <div className="card-header">
            <h5>Información Laboral
              <i className="bi bi-pencil-square float-end" style={{ cursor: 'pointer' }} onClick={() => handleShowModal('Editar Información Laboral', { empresa: 'Cirenio Inc', cuit: '30-734412341-0', telefono: '11 2408-4838' })}></i>
            </h5>
          </div>
          <div className="card-body">
            <p><strong>Empresa:</strong> Cirenio Inc</p>
            <p><strong>CUIL:</strong> 30-734412341-0</p>
            <p><strong>Teléfono Laboral:</strong> 11 2408-4838</p>
            <p><strong>Domicilio Laboral:</strong> Mansilla 3696, CABA</p>
          </div>
        </div>
      </div>

      {/* Domicilio y Contacto */}
      <div className="col-md-6 mb-4">
        <div className="card border">
          <div className="card-header">
            <h5>Domicilio y Contacto
              <i className="bi bi-pencil-square float-end" style={{ cursor: 'pointer' }} onClick={() => handleShowModal('Editar Domicilio y Contacto', { domicilio: 'Maipú 1212', telefono: '11 6958-0707', email: 'test@example.com' })}></i>
            </h5>
          </div>
          <div className="card-body">
            <p><strong>Domicilio:</strong> Maipú 1212</p>
            <p><strong>Provincia:</strong> Buenos Aires</p>
            <p><strong>Localidad:</strong> Remedios de Escalada</p>
            <p><strong>Código Postal:</strong> B1824NKA</p>
            <p><strong>Teléfono Celular:</strong> 11 6958-0707</p>
            <p><strong>Email:</strong> {clientData.nombre}.{clientData.apellido}@cirenio.com</p>
          </div>
        </div>
      </div>

      {/* Referidos */}
      <div className="col-md-6 mb-4">
        <div className="card border">
          <div className="card-header">
            <h5>Referidos
              <i className="bi bi-pencil-square float-end" style={{ cursor: 'pointer' }} onClick={() => handleShowModal('Editar Referidos', { referido1: 'Francisco Siri', referido2: 'Fernanda Gattoni' })}></i>
            </h5>
          </div>
          <div className="card-body">
            <p><strong>Referido 1:</strong> Francisco Siri</p>
            <p><strong>Teléfono:</strong> 11 5538-2710</p>
            <p><strong>Vínculo:</strong> Amigo</p>
            <ln></ln>
            <p><strong>Referido 2:</strong> Fernanda Gattoni</p>
            <p><strong>Teléfono:</strong> 11 5538-2710</p>
            <p><strong>Vínculo:</strong> Familiar</p>
          </div>
        </div>
      </div>

      {/* Documentos / Archivos */}
      <div className="col-md-12 mb-4">
        <div className="card border">
          <div className="card-header">
            <h5>Archivos y Documentos</h5>
          </div>
          <div className="card-body">
            <div className="row">
              {/* Frente DNI */}
              <div className="col-md-4 text-center">
                {editMode.dniFrente ? (
                  <ImageDropzone field="dniFrente" handleDrop={handleDrop} />
                ) : (
                  <div style={{ position: 'relative', border: '1px solid #ced4da', padding: '10px', borderRadius: '8px' }}>
                    <img
                      src="/assets/images/dni-frente.jpg"
                      alt="DNI Frente"
                      className="img-fluid border rounded"
                    />
                    <button
                      className="btn btn-link text-primary"
                      style={{ position: 'absolute', top: '10px', right: '10px' }}
                      onClick={() => handleEditClick('dniFrente')}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>
                    <p>Frente DNI</p>
                  </div>
                )}
              </div>

              {/* Dorso DNI */}
              <div className="col-md-4 text-center">
                {editMode.dniDorso ? (
                  <ImageDropzone field="dniDorso" handleDrop={handleDrop} />
                ) : (
                  <div style={{ position: 'relative', border: '1px solid #ced4da', padding: '10px', borderRadius: '8px' }}>
                    <img
                      src="/assets/images/dni-dorso.jpg"
                      alt="DNI Dorso"
                      className="img-fluid border rounded"
                    />
                    <button
                      className="btn btn-link text-primary"
                      style={{ position: 'absolute', top: '10px', right: '10px' }}
                      onClick={() => handleEditClick('dniDorso')}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>
                    <p>Dorso DNI</p>
                  </div>
                )}
              </div>

              {/* Selfie */}
              <div className="col-md-4 text-center">
                {editMode.selfie ? (
                  <ImageDropzone field="selfie" handleDrop={handleDrop} />
                ) : (
                  <div style={{ position: 'relative', border: '1px solid #ced4da', padding: '10px', borderRadius: '8px' }}>
                    <img
                      src="/assets/images/selfie.png"
                      alt="Selfie"
                      className="img-fluid border rounded"
                    />
                    <button
                      className="btn btn-link text-primary"
                      style={{ position: 'absolute', top: '10px', right: '10px' }}
                      onClick={() => handleEditClick('selfie')}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>
                    <p>Selfie</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal para editar la información */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Aquí iría el formulario de edición de los campos */}
          <p>Editando los datos de: {modalTitle}</p>
          {/* Mock de inputs para la edición */}
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Campo editado"
            value={editField?.nombre || ''}
            onChange={(e) => setEditField({ ...editField, nombre: e.target.value })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ClientProfile;

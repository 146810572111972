// src/components/PurchaseFlow/Steps/DataEntry/Sections/PersonalInfo.js
import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { faCheckCircle, faExclamationTriangle, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PersonalInfo = forwardRef((props, ref) => {
  const {
    formData,
    handleChange,
    activeSection,
    sectionName,
    sectionValidity,
    setSectionValidity,
    handleSectionClick,
  } = props;

  const [errors, setErrors] = useState({});
  const [allowEditPersonalInfo] = useState(process.env.REACT_APP_ALLOW_EDIT_PERSONAL_INFO === 'true');

  useEffect(() => {
    validateSection();
  }, []);

  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'firstName':
        if (!value) {
          error = 'El nombre es requerido';
        }
        break;
      case 'lastName':
        if (!value) {
          error = 'El apellido es requerido';
        }
        break;
      case 'gender':
        if (!value) {
          error = 'El sexo es requerido';
        }
        break;
      case 'birthDate':
        if (!value) {
          error = 'La fecha de nacimiento es requerida';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    const newErrors = {
      ...errors,
      [name]: error,
    };

    const isValid = Object.values(newErrors).every((err) => !err);
    setSectionValidity((prev) => ({ ...prev, [sectionName]: isValid }));
  };

  const validateSection = () => {
    const fieldsToValidate = ['firstName', 'lastName', 'gender', 'birthDate'];
    let newErrors = {};
    fieldsToValidate.forEach((field) => {
      const value = formData[field];
      if (!value) {
        newErrors[field] = 'Este campo es requerido';
      }
    });

    setErrors(newErrors);

    const isValid = Object.keys(newErrors).length === 0;
    setSectionValidity((prev) => ({ ...prev, [sectionName]: isValid }));
    return isValid;
  };

  const isFieldEditable = (fieldValue) => {
    return allowEditPersonalInfo || !fieldValue;
  };

  useImperativeHandle(ref, () => ({
    validateSection,
  }));

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading${sectionName}`}>
        <button
          className={`accordion-button ${activeSection !== sectionName ? 'collapsed' : ''}`}
          type="button"
          onClick={() => handleSectionClick(sectionName)}
        >
          <FontAwesomeIcon icon={faUser} className="me-2" />
          Datos Personales{' '}
          {sectionValidity[sectionName] === true && (
            <FontAwesomeIcon icon={faCheckCircle} className="text-success ms-2" />
          )}
          {sectionValidity[sectionName] === false && (
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger ms-2" />
          )}
        </button>
      </h2>
      <div
        className={`accordion-collapse collapse ${activeSection === sectionName ? 'show' : ''}`}
        aria-labelledby={`heading${sectionName}`}
      >
        <div className="accordion-body">
          <div className="row">
            {/* Campos del formulario con validación */}
            <div className="col-md-6 mb-3">
              <label className="form-label">Nombre<sup>*</sup></label>
              <input
                type="text"
                className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                disabled={!isFieldEditable(formData.firstName)}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
                placeholder="Ingrese su nombre"
                autoComplete="off"
                required
              />
              {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Apellido<sup>*</sup></label>
              <input
                type="text"
                className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                disabled={!isFieldEditable(formData.lastName)}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
                placeholder="Ingrese su apellido"
                autoComplete="off"
                required
              />
              {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Sexo<sup>*</sup></label>
              <select
                className={`form-control ${errors.gender ? 'is-invalid' : ''}`}
                name="gender"
                value={formData.gender || ''}
                onChange={handleChange}
                disabled={!isFieldEditable(formData.gender)}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
                required
                autoComplete="off"
              >
                <option value="" disabled>Seleccione</option>
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
                <option value="X">Otro</option>
              </select>
              {errors.gender && <div className="invalid-feedback">{errors.gender}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Fecha de Nacimiento<sup>*</sup></label>
              <input
                type="date"
                className={`form-control ${errors.birthDate ? 'is-invalid' : ''}`}
                name="birthDate"
                value={formData.birthDate || ''}
                onChange={handleChange}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
                required
                autoComplete="off"
              />
              {errors.birthDate && <div className="invalid-feedback">{errors.birthDate}</div>}
            </div>
         </div>
        </div>
      </div>
    </div>
  );
});

export default PersonalInfo;

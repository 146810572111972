// src/components/PurchaseFlow/Step/PaymentStep.js
import React, { useEffect, useRef, useState } from 'react';
import './PaymentStep.css';
import Skeleton from 'react-loading-skeleton';
import NavigationButtons from '../../NavigationButtons';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../../../services/api';
import Swal from 'sweetalert2';

const PaymentStep = ({ onNext, onBack, clientData, purchaseRequestId, onCardTokenized }) => {
  const paymentWidgetRef = useRef(null);

  // Loading states
  const [isWidgetLoading, setIsWidgetLoading] = useState(true);
  const [isSavingCard, setIsSavingCard] = useState(false);

  // Error and final state control
  const [errorMessage, setErrorMessage] = useState('');
  const [isCardSaved, setIsCardSaved] = useState(false);

  // Trigger to reload the widget if something fails
  const [reloadTrigger, setReloadTrigger] = useState(0);

  const scriptSrc = process.env.REACT_APP_CIRENIO_PSP_SDK_SRC;
  const publicKey = process.env.REACT_APP_CIRENIO_PSP_PUBLIC_KEY;

  const canSkipPaymentStep = process.env.REACT_APP_ENABLE_SKIP_PAYMENT_STEP === 'true';

  /**
   * Saves the card token in the backend.
   */
  const saveCardToken = async (cardData) => {
    if (!cardData?.source) {
      setErrorMessage('Datos de tarjeta incompletos. Por favor, inténtalo de nuevo.');
      return;
    }

    const { source } = cardData;
    setIsSavingCard(true);

    const payload = {
      purchase_request_id: purchaseRequestId,
      token: cardData.token,
      holder: {
        id: source.holder?.identification,
        name: source.holder?.name,
      },
      expiration: source.expiration,
      last_four: source.last_4,
      type: source.type,
      variant: source.variant,
      brand: {
        name: source.brand?.name,
        logo: source.brand?.logo_url
      },
      bank: {
        color: source.bank?.color || '',
        logo: source.bank?.logo_url || '',
        name: source.bank?.name || ''
      }
    };

    try {
      const response = await api.post(`/customers/${clientData.id}/cards`, payload);
      setIsSavingCard(false);

      if (response.status === 201 || response.status === 200) {
        console.log('Card token stored successfully:', response.data);
        if (onCardTokenized) {
          onCardTokenized(response.data);
        }
        setIsCardSaved(true);
        setErrorMessage('');
      } else {
        console.error('Unexpected response status:', response.status);
        setErrorMessage('Ocurrió un problema al guardar la tarjeta. Intente nuevamente.');
      }
    } catch (error) {
      setIsSavingCard(false);
      console.error('Error storing card token:', error);
      setErrorMessage('No se pudo guardar la tarjeta. Intente nuevamente.');
    }
  };

  /**
   * Reloads the widget in case of error.
   */
  const reloadWidget = () => {
    setErrorMessage('');
    setIsCardSaved(false);
    setIsWidgetLoading(true);
    setIsSavingCard(false);

    // Increase the reloadTrigger to force reloading the script/widget
    setReloadTrigger((prev) => prev + 1);
  };

  /**
   * Loads the Cirenio script and shows the widget.
   * It restarts if reloadTrigger changes (due to a "Retry" action).
   */
  useEffect(() => {
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.async = true;

    script.onload = () => {
      if (window.CirenioPayments) {
        const payments = new window.CirenioPayments(
          publicKey,
          clientData.cuit,
          {
            containerId: 'cirenioPaymentContainer',
            externalId: `pr-${clientData.cuit}-${purchaseRequestId}`
          }
        );

        payments.on('widget_start', () => setIsWidgetLoading(false));

        payments.on('tokenization_success', async (cardData) => {
          await saveCardToken(cardData);
        });

        payments.on('tokenization_error', () => {
          setErrorMessage('Hubo un problema al procesar tu tarjeta. Por favor, intenta nuevamente.');
        });

        payments.openWidget();
      }
    };

    script.onerror = () => {
      // Here we show the error message, including the "vuelve a intentarlo" link inline
      setErrorMessage('No se pudo cargar el widget de pagos. Revisa tu conexión o vuelve a intentarlo.');
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [scriptSrc, publicKey, clientData, purchaseRequestId, reloadTrigger]);

  const handleContinue = () => {
    onNext();
  };

  const handleSkipStep = () => {
    // Podríamos mostrar una confirmación si lo consideras necesario
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'No se asociará ninguna tarjeta de débito a esta venta',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, continuar sin tarjeta',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#6c757d', // Color gris para indicar que no es la acción recomendada
    }).then((result) => {
      if (result.isConfirmed) {
        onNext();
      }
    });
  };

  /**
   * Renders an alert with the error message.
   * If the error is about loading the script, we show the "vuelve a intentarlo" link inline.
   */
  const renderErrorAlert = () => {
    if (!errorMessage) return null;

    if (errorMessage.includes('No se pudo cargar el widget de pagos')) {
      return (
        <div className="alert alert-warning text-center mt-3" role="alert">
          <i className="bi bi-exclamation-triangle" style={{ marginRight: 8 }}></i>
          {/* We split the message to insert the clickable link */}
          No se pudo cargar el widget de pagos. Revisa tu conexión o{' '}
          <button
            type="button"
            className="btn btn-link p-0"
            style={{ textDecoration: 'underline' }}
            onClick={reloadWidget}
          >
            vuelve a intentarlo
          </button>.
        </div>
      );
    }

    // Default error message
    return (
      <div className="alert alert-warning text-center mt-3" role="alert">
        <i className="bi bi-exclamation-triangle" style={{ marginRight: 8 }}></i>
        {errorMessage}
      </div>
    );
  };

  return (
    <div className="container my-5">
      <div className="position-relative mb-4">
        <h4 className="text-center mb-0">Asociar Tarjeta de Débito</h4>
        {canSkipPaymentStep && (
          <button 
            className="btn btn-link text-muted position-absolute end-0 top-50 translate-middle-y"
            onClick={handleSkipStep}
            type="button"
          >
            <i className="bi bi-arrow-right me-1"></i>
            Continuar sin tarjeta
          </button>
        )}
      </div>

      <div className="row justify-content-between align-items-start gx-4">
        {/* Columna de información */}
        <div className="col-12 col-md-5">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-4">Información Importante</h5>
              
              <div className="mb-4">
                <h6 className="mb-3">
                  <i className="bi bi-credit-card me-2"></i>
                  Requisitos de la Tarjeta
                </h6>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <i className="bi bi-check-circle-fill text-success me-2"></i>
                    Debe ser una tarjeta de débito
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check-circle-fill text-success me-2"></i>
                    Debe estar a tu nombre
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check-circle-fill text-success me-2"></i>
                    Debe estar vinculada a la cuenta donde recibís tus ingresos
                  </li>
                </ul>
              </div>

              <div className="mb-4">
                <h6 className="mb-3">
                  <i className="bi bi-info-circle me-2"></i>
                  ¿Para qué se utilizará?
                </h6>
                <p className="text-muted mb-2">
                  Se utilizará como medio alternativo de cobranza y quedará vinculada para futuras compras
                </p>
              </div>

              <div>
                <h6 className="mb-3">
                  <i className="bi bi-shield-lock me-2"></i>
                  Seguridad
                </h6>
                <p className="text-muted mb-0">
                  Tus datos son tratados con los más altos estándares de seguridad bancaria y están protegidos por la ley de protección de datos personales
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Columna del widget de pago */}
        <div className="col-12 col-md-7">
          <div className="payment-widget-wrapper">
            {isWidgetLoading && <Skeleton height={680} />}
            <div
              id="cirenioPaymentContainer"
              ref={paymentWidgetRef}
              className="payment-widget"
              style={{ display: isWidgetLoading ? 'none' : 'block' }}
            />
          </div>
        </div>
      </div>

      {/* Render error message if needed */}
      {renderErrorAlert()}

      <NavigationButtons
        onBack={onBack}
        onNext={handleContinue}
        disableNext={!isCardSaved}
        isLoading={isSavingCard}
        additionalButtons={
          canSkipPaymentStep ? (
            <button 
              className="btn btn-outline-secondary" 
              onClick={handleSkipStep}
              type="button"
            >
              Omitir este paso
            </button>
          ) : null
        }
      />
    </div>
  );
};

export default PaymentStep;

// src/components/PurchaseFlow/Steps/DataEntry/DataEntryStep.js
import React, { useState, useRef } from 'react';
import api from '../../../../services/api';
import NavigationButtons from '../../NavigationButtons';
import PersonalInfo from './Sections/PersonalInfo';
import AddressContact from './Sections/AddressContact';
import Referrals from './Sections/Referrals';
import EmploymentInfo from './Sections/EmploymentInfo';
import Swal from 'sweetalert2';

const DataEntryStep = ({ onNext, onBack, formData: initialData = {}, clientData, purchaseRequestId }) => {

  const [allowEditPersonalInfo] = useState(process.env.REACT_APP_ALLOW_EDIT_PERSONAL_INFO === 'true');

  // Determinar si se debe mostrar la sección laboral
  const showEmploymentSection = clientData?.employment_status?.code === 'ACTIVE';

  const [formData, setFormData] = useState({
    firstName: clientData?.firstName || '',
    lastName: clientData?.lastName || '',
    birthDate: clientData?.birthDate || '',
    gender: clientData?.gender || '',
    referrals: [
      { firstName: '', lastName: '', phone: '', relationship: '' },
      { firstName: '', lastName: '', phone: '', relationship: '' }
    ],
    ...initialData,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [activeSection, setActiveSection] = useState('PersonalInfo');
  const [sectionValidity, setSectionValidity] = useState({
    PersonalInfo: null,
    AddressContact: null,
    Referrals: null,
    ...(showEmploymentSection ? { EmploymentInfo: null } : {})
  });

  const [serverErrors, setServerErrors] = useState([]);

  const personalInfoRef = useRef();
  const addressContactRef = useRef();
  const referralsRef = useRef();
  const employmentInfoRef = useRef();

  const handleChange = (e, options = {}) => {
    const { name, value } = e.target;
  
    let processedValue = value;
    
    // Procesamiento especial para ciertos campos
    if (name === 'phone' || name === 'workPhone') {
      processedValue = value.replace(/^0+/, ''); // Eliminar ceros a la izquierda para teléfono
    }
  
    setFormData((prev) => {
      if (options.isNested && options.arrayIndex !== undefined) {
        const updatedArray = [...prev[options.nestedKey]];
        updatedArray[options.arrayIndex][name] = processedValue;
        return {
          ...prev,
          [options.nestedKey]: updatedArray,
        };
      }
  
      return {
        ...prev,
        [name]: processedValue,
      };
    });
  };  

  const handleSectionClick = (targetSection) => {
    let currentSectionRef;

    switch (activeSection) {
      case 'PersonalInfo':
        currentSectionRef = personalInfoRef;
        break;
      case 'AddressContact':
        currentSectionRef = addressContactRef;
        break;
      case 'Referrals':
        currentSectionRef = referralsRef;
        break;
      case 'EmploymentInfo':
        currentSectionRef = employmentInfoRef;
        break;
      default:
        currentSectionRef = null;
    }

    if (currentSectionRef && currentSectionRef.current) {
      currentSectionRef.current.validateSection();
    }

    if (activeSection === targetSection) {
      setActiveSection(null);
    } else {
      setActiveSection(targetSection);
    } 
  };

  const handleNext = async () => {
    let currentSectionRef;
  
    switch (activeSection) {
      case 'PersonalInfo':
        currentSectionRef = personalInfoRef;
        break;
      case 'AddressContact':
        currentSectionRef = addressContactRef;
        break;
      case 'Referrals':
        currentSectionRef = referralsRef;
        break;
      case 'EmploymentInfo':
        currentSectionRef = showEmploymentSection ? employmentInfoRef : null;
        break;
      default:
        currentSectionRef = null;
    }
  
    if (currentSectionRef && currentSectionRef.current && !currentSectionRef.current.validateSection()) {
      console.warn('No puede avanzar sin completar todos los campos requeridos.');
      return;
    }
  
    if (Object.values(sectionValidity).every((isValid) => isValid === true)) {
      setIsLoading(true);
      const success = await handleSubmit();
      setIsLoading(false);
      console.log(success);
      if (success) {
        onNext({ personalInfo: formData });
      } else {
        console.error('No se pudo guardar la información. Por favor, revise los errores.');
      }
    } else {
      console.warn('Por favor, complete todas las secciones antes de continuar.');
    }
  };  

  const handleSubmit = async () => {
    setServerErrors([]); // Limpiar errores previos
    
    const payload = {
      purchase_request_id: purchaseRequestId,
      personalInfo: {
        firstname: formData.firstName,
        lastname: formData.lastName,
        gender: formData.gender,
        birthdate: formData.birthDate,
      },
      addressContact: {
        address: formData.address,
        provinceId: formData.province,
        localityId: formData.locality,
        postalCode: `${formData.postalCode}`,
        mobile: formData.phone,
        email: formData.email,
      },
      referrals: formData.referrals.map((referral) => ({
        firstname: referral.firstName,
        lastname: referral.lastName,
        phone: referral.phone,
        relationship: referral.relationship,
      })),
      ...(showEmploymentSection && {
        employmentInfo: {
          cuit: formData.cuit,
          companyName: formData.companyName,
          salary: formData.salary,
          entryDate: formData.entryDate,
          position: formData.position,
          workPhone: formData.workPhone,
          workAddress: formData.workAddress,
          workProvinceId: formData.workProvince,
          workLocalityId: formData.workLocality,
          workPostalCode: `${formData.workPostalCode}`,
        }
      }),
    };

    try {
      const response = await api.post(`/customers/${clientData.id}/data`, payload);
      if (response.status === 200) {
        console.log('Customer data saved successfully');
        return true;
      }
    } catch (error) {
      console.error('Error updating customer data:', error);
      
      if (error.response?.data?.errors) {
        // Convertir el objeto de errores en un array de mensajes
        const errorMessages = Object.values(error.response.data.errors)
          .flat()
          .map(msg => msg.replace(/employment info\./g, '')); // Limpiamos un poco el mensaje
        
        setServerErrors(errorMessages);
      } else {
        // Error genérico
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un problema al guardar los datos. Por favor, intente nuevamente.',
        });
      }
      return false;
    }    
  };  

  return (
    <div className="container my-5">
      <h4 className="text-center mb-2">Complete sus datos personales</h4>
      <p className="text-center mb-4 text-muted">
        Estos datos serán utilizados para el legajo digital y la solicitud de préstamo. 
      </p>

      {/* Accordion con formularios */}
      <div className="accordion" id="datosAccordion">
        <PersonalInfo
          ref={personalInfoRef}
          formData={formData}
          handleChange={handleChange}
          activeSection={activeSection}
          sectionName="PersonalInfo"
          sectionValidity={sectionValidity}
          setSectionValidity={setSectionValidity}
          handleSectionClick={handleSectionClick}
        />
        <AddressContact
          ref={addressContactRef}
          formData={formData}
          handleChange={handleChange}
          activeSection={activeSection}
          sectionName="AddressContact"
          sectionValidity={sectionValidity}
          setSectionValidity={setSectionValidity}
          handleSectionClick={handleSectionClick}
        />
        {showEmploymentSection && (
          <EmploymentInfo
            ref={employmentInfoRef}
            formData={formData}
            handleChange={handleChange}
            activeSection={activeSection}
            sectionName="EmploymentInfo"
            sectionValidity={sectionValidity}
            setSectionValidity={setSectionValidity}
            handleSectionClick={handleSectionClick}
          />
        )}
        <Referrals
          ref={referralsRef}
          formData={formData}
          handleChange={handleChange}
          activeSection={activeSection}
          sectionName="Referrals"
          sectionValidity={sectionValidity}
          setSectionValidity={setSectionValidity}
          handleSectionClick={handleSectionClick}
        />
      </div>

      {serverErrors.length > 0 && (
        <div className="alert alert-warning mt-3" role="alert">
          <strong className="alert-heading"><i class="bi bi-exclamation-triangle me-2"></i>Por favor, corrija los siguientes errores:</strong>
          <ul className="mb-0 ms-3 list-unstyled">
            {serverErrors.map((error, index) => (
              <li key={index}>• {error}</li>
            ))}
          </ul>
        </div>
      )}

      <NavigationButtons
        onBack={onBack}
        onNext={handleNext}
        disableNext={!Object.values(sectionValidity).every((isValid) => isValid === true)}
        isLoading={isLoading}
      />
    </div>
  );
};

export default DataEntryStep;
